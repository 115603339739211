import { FaAngleRight, FaCopy } from "react-icons/fa";
import style from "./singleInvite.module.scss";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { IInvite } from "interface/invite";
import styleSuccess from "styles/sucess.module.scss";
import { useState } from "react";

export default function SingleInvite(props: IInvite) {

  const [link, setLink] = useState("");
  const [copy, setCopy] = useState(false);

  const navigate = useNavigate();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setCopy(true);
  };

  return (
    <section className={style.section}>
      <div className={style.container}>

        <div className={style.line}>
          <TextField
            label="E-mail"
            value={props.email}
            InputProps={{
              readOnly: true,
            }}
            sx={{
              width: '100%', '& label': {
                minWidth: 200,
              },
            }}
            fullWidth
          />
        </div>


        <div className={style.line}>
          <div className={styleSuccess.pointer} onClick={copyToClipboard}>

            <TextField
              label="Link"
              value={props.invite}
              InputProps={{ readOnly: true }}
              fullWidth
              sx={{
                width: '100%',
                '& label': {
                  minWidth: 200,
                },
              }}
            />
            <FaCopy className={styleSuccess.copy} />
            {copy ? "Link Copiado!" : ""}
          </div>
        </div>

      </div>

      <FaAngleRight className={style.section__icon} />

    </section >
  )
}