import { useEffect, useState } from "react";
import { FaBuilding, FaCheckCircle, FaEdit, FaStar, FaTimesCircle, FaUser, FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IBusiness } from "interface/business";
import style from "./business.module.scss"
import ButtonSystem from "components/forms/button";

export default function Business(props: IBusiness) {

  const navigate = useNavigate();

  const [qtdActive, setQtdActive] = useState(0);
  const [qtdInactive, setQtdInactive] = useState(0);

  useEffect(() => {
    if (props.users) {
      const active = props.users.filter((e:any) => e.userActive === true);
      const inactive = props.users.filter((e:any) => e.userActive === false); 
      setQtdActive(active.length);
      setQtdInactive(inactive.length);
    }
  }, [props.users])

  const editBusiness = () => {
    navigate(`/business/${props.id}`)
  }

  const editUsers = () => {
    navigate(`/business/${props.id}/users`)
  }

  const addUser = (e: any) => {
    navigate(`/invite/business/${props.id}`);
  }


  return (
    <div className={style.container}>
      <div className={style.business}>
        <div className={style.name} onClick={editBusiness} >
          <FaBuilding className={style.icon} />
          {props.name}
        </div>
        <div className={style.plan} onClick={editBusiness} >
          <FaStar className={style.icon} /> {props.plan}
        </div>
        <div className={style.users} onClick={editUsers}>
          <FaUser className={style.icon} />
          {qtdActive} Ativos - {qtdInactive} Inativos
        </div>
        <div className={style.active} onClick={editBusiness} >
          {props.active === true
            ? <>
              <FaCheckCircle className={style["active--true"]} /> ATIVO
            </>
            : <>
              <FaTimesCircle className={style["active--false"]} /> INATIVO
            </>
          }
        </div>

        <FaEdit className={style.icon__edit} onClick={editBusiness} />

        <FaUsers className={style.icon__edit} onClick={editUsers} />

        <div className={style.controls}>
        <ButtonSystem action={(e: any) => addUser(e)} status={true} text="Usuário" to="next" icon="add" />
      </div>



      </div>
      
    </div>
  );
}