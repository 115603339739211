import { ITask } from "interface/tasks";
import style from "./singleTask.module.scss";
import ShowUsers from "components/users/show";
import { FaAlignLeft, FaCheck, FaRegCalendar, FaRegComment } from "react-icons/fa";
import classNames from "classnames";

export default function SingleTask(props: ITask) {

  const description = props.description !== undefined && props.description !== "" ? props.description : 0;

  const finalDate = new Date(props.finalDate + 'T00:00:00');
  const today = new Date();

  // Zera as horas, minutos, segundos e milissegundos das datas
  finalDate.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);


  // Calcula a diferença em milissegundos entre as duas datas
  const differenceInMilliseconds = finalDate.getTime() - today.getTime();

  // Calcula a diferença em dias
  const diasNoPrazo = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

  const prazo = props.status === "done" ? "Concluído" : (
    diasNoPrazo > 1 ? `${diasNoPrazo} dias` : (
      diasNoPrazo === 1 ? `amanhã` : (
        diasNoPrazo === 0 ? `hoje` : (
          diasNoPrazo === -1 ? `ontem` : `${diasNoPrazo} dias`
        )
      )
    )
  );

  return (

    <div className={style.container}>
      <div className={props.status === "done" ? style.title__done : style.title}>
        {props.task}
      </div>

      <div className={props.status === "done" ? style.description__done : style.description}>
        {props.description}
      </div>


      <div className={style.rodape}>
        <div className={style.users}>
          <ShowUsers users={props.responsible} />
        </div>
        <div className={props.status === "done" ? style.deadline__done : (diasNoPrazo < 0 ? style.deadline__delay : style.deadline)}>
          <FaRegCalendar className={style.comments__icon} /> {prazo}
        </div>

        <div className={style.comments}>
          {description !== 0 ?
            <FaAlignLeft className={style.comments__icon} />
            : ""}
        </div>

      </div >

    </div >
  )
}