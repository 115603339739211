import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useEffect, useState } from 'react';
import style from "./doughnut.module.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

type IDoughnutPercentage = {
  finalValue: number,
  doneValue: number,
  resultType: string,
  initialValue: number,
  height: number // 200 ou 300 for the size.
}

export default function DoughnutPercentage(props: IDoughnutPercentage) {

  const [doneValue, setDoneValue] = useState(0);
  const [missingValue, setMissingValue] = useState(0);
  const [percent, setPercent] = useState(0);

  useEffect(() => {

    if (props.resultType === "Conquista") {

      const preDone = props.doneValue - props.initialValue;
      const preTotal = props.finalValue - props.initialValue;

      setDoneValue(preDone);

      if (preDone < preTotal) {
        setMissingValue(preTotal - preDone);
      } else {
        setMissingValue(0);
      }

      const prePercent = (preDone / preTotal) * 100;
      const percentFixed = prePercent.toFixed(0);
      const percentFinal = parseInt(`${percentFixed}`);
      setPercent(percentFinal);

    } else if (props.resultType === "Redução") {

      const preDone = props.initialValue - props.doneValue; // 30 - 29 = 1
      const preTotal = props.initialValue - props.finalValue; // 30 - 2 = 28

      setDoneValue(preDone);

      if (preDone < preTotal) {
        setMissingValue(preTotal - preDone);
      } else {
        setMissingValue(0);
      }

      const prePercent = (preDone / preTotal) * 100;
      const percentFixed = prePercent.toFixed(0);
      const percentFinal = parseInt(`${percentFixed}`);
      setPercent(percentFinal);

    } else if (props.resultType === "Manter") {

      if (props.doneValue < props.finalValue) {

        setDoneValue(props.doneValue); // 98
        setMissingValue(props.finalValue - props.doneValue); // 2

        const prePercent = (props.doneValue / props.finalValue) * 100;
        const percentFixed = prePercent.toFixed(0);
        const percentFinal = parseInt(`${percentFixed}`);
        setPercent(percentFinal);


      } else if (props.doneValue > props.finalValue) {

        const preDone = props.doneValue - props.finalValue; // 130 - 110 = 20
        const done = props.finalValue - preDone; // 110 - 20 = 90

        setDoneValue(done);
        setMissingValue(props.finalValue - done); // 110 - 90 = 20

        const prePercent = (done / props.finalValue) * 100;
        const percentFixed = prePercent.toFixed(0);
        const percentFinal = parseInt(`${percentFixed}`);
        setPercent(percentFinal);

      } else if (props.doneValue === props.finalValue) {
        setDoneValue(props.doneValue);
        setMissingValue(0);
        setPercent(100);
      }
    }

  }, [props])

 
  // const gaugeChartText = {
  //   id: "gaugeChartText",
  //   afterDatasetDraw(chart: any, args: any, pluginOptions: any) {
  //     const { ctx, chartArea: { top, bottom, left, right, width, height } } = chart;

  //     const xCenter = chart.getDatasetMeta(0).data[0].x;
  //     const yCenter = chart.getDatasetMeta(0).data[0].y;

  //     ctx.save();
  //     ctx.fillStyle = "#7551d9";
  //     ctx.font = "600 3em Blinker, sans-serif";
  //     ctx.fillText(`${percent}%`, xCenter, yCenter - 1);
  //     ctx.textAlign = "center";
  //     ctx.textBaseline = "middle";
  //   }

  // }

  const data = {
    labels: ["Concluído", "Em aberto"],
    datasets: [{
      label: 'Progress',
      data: [doneValue, missingValue],
      backgroundColor: (context: any) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          return null
        }
        if (context.dataIndex === 0) {
          return getGradient(chart);
        } else {
          return "rgba(241,241,241,1)"
        }
      },
      hoverOffset: 4
    }]
  }

  function getGradient(chart:any) {
    const { ctx, chartArea: { top, bottom, left, right } } = chart;
    const gradientSegment = ctx.createLinearGradient(left, 0, right, 0);
    gradientSegment.addColorStop(0.1, "rgba(117,81,217,1)");
    gradientSegment.addColorStop(0.5, "rgba(98,176,211,1)");
    gradientSegment.addColorStop(0.9, "rgba(36,255,143,1)");
    return gradientSegment;
  }

  const options = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
    },
    rotation: -90,
    circumference: 360,
    cutout: "80%",
    maintainAspectRatio: false,
    responsive: true
  }


  return (
    <div className={style.container}>
    <Doughnut options={options} data={data} height={props.height} />
    <div className={style.container__title}>{`${percent}%`}</div>
    </div>
  );
}