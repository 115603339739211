import ButtonSystem from "components/forms/button";
import style from "./okrNotFound.module.scss";
import { useNavigate } from "react-router-dom";

export default function OkrNotFound() {

  return (
    <div className={style.container}>

      <div className={style.objective}>

        <div className={style.container__header}>
          <div className={style.title}>Não encontramos nenhum objetivo aqui.</div>
        </div>

      </div>


      <div className={style.objective}>
        <div className={style.okrX}>
        </div>
        <div className={style.okrX}>
        </div>
        <div className={style.okrX}>
        </div>
      </div>

      <div className={style.objective}>
        <div className={style.krX}>
        </div>
      </div>

      

    </div>
  );
}
