import { useState } from "react";
import style from "styles/addSometing.module.scss";
import { TbTarget } from "react-icons/tb";
import NewKeyResult from "components/kr/newKeyResult";
import Steps from "components/forms/steps";

export default function NewKResult() {

  const [data, setData] = useState() as any;
  const [currentStep, setCurrentStep] = useState(1);

  const keyResult = data ? data : "Defina um Resultado-chave";

  return (
    <section className={style.section}>

      {/* LEFT */}

      <div className={style.view}>
        <TbTarget className={style.item} />
        <div className={style.printObjective}>
          {keyResult}
        </div>
        <Steps totalSteps={2} currentStep={currentStep} />
      </div>

      {/* RIGHT */}

      <div className={style.content}>
        <div className={style.container}>
          <NewKeyResult data={(e: any) => setData(e)} currentStep={(e: any) => setCurrentStep(e)} />
        </div>
      </div>

    </section>
  );
}