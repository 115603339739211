import style from "./removeCheckpoint.module.scss";
import { collection, deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";

import { FaTrashAlt } from "react-icons/fa";
import { db } from "config/firebase";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { updateState } from "state/atom";

type RemoveCheckpointProps = {
  businessId: any;
  okr: any;
  kr: any;
  dateId: any;
}

export default function RemoveCheckpoint(props: RemoveCheckpointProps) {

  const setUpdateState = useSetRecoilState(updateState);
  const update = useRecoilValue(updateState);


  // Função para remover um checkpoint
  const deleteCheckpoint = () => {

    // Define a referência para o documento de OKR
    const okrRef = doc(db, "companies", props.businessId, "okrs", props.okr);

    // Verifica se o documento OKR existe
    getDoc(okrRef)
      .then((okrDocSnapshot) => {
        if (okrDocSnapshot.exists()) {
          // O documento OKR existe

          // Obtém os dados do documento OKR
          const okrData = okrDocSnapshot.data();

          // Verifica se a lista de KRs existe no documento OKR
          if (okrData.krs) {
            // Procura o KR pelo ID
            const existingKrIndex = okrData.krs.findIndex((kr: any) => kr.id === props.kr);

            if (existingKrIndex !== -1) {
              // Se o KR existir, verifica se a lista de checkpoints existe no KR
              if (okrData.krs[existingKrIndex].checkpoints) {
                // Procura o checkpoint pelo ID ou data para removê-lo
                const checkpointToRemoveIndex = okrData.krs[existingKrIndex].checkpoints.findIndex(
                  (checkpoint: any) => checkpoint.id === props.dateId
                );

                if (checkpointToRemoveIndex !== -1) {
                  // Remove o checkpoint da lista
                  okrData.krs[existingKrIndex].checkpoints.splice(checkpointToRemoveIndex, 1);
                }
              }
            }

            // Atualiza o documento OKR com os KRs atualizados
            return updateDoc(okrRef, { krs: okrData.krs });
          }
        }

        return null;
      })
      .then(() => {
        setUpdateState(!update);
      })
      .catch((error) => {
        console.error("Erro ao atualizar o documento OKR:", error);
      });
  }


  return (
    <FaTrashAlt className={style.icon__remove} onClick={deleteCheckpoint} />
  )
}