import { useEffect, useReducer, useState } from "react";
import { FaBuilding, FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import styleAdd from "styles/addSometing.module.scss";
import { v4 as uuidv4 } from "uuid";
import { FaCodeBranch } from "react-icons/fa";
import style from "styles/components.module.scss";
import ButtonSystem from "components/forms/button";
import { useNavigate, useParams } from "react-router-dom";
import { MenuItem, TextField } from "@mui/material";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "config/firebase";

  // INITIAL INFO OF BUSINESS
  const initialBusiness = {
    id: uuidv4() + Date.now(),
    name: "Novo Negócio",
    cnpj: "",
    plan: "basic",
    active: true,
    photoURL: "",
  };

// STATE OF BUSINESS
const businessStates = (state: any, action: any) => {
  switch (action.type) {

    case "id":
      return { ...state, id: action.payload };

    case "name":
      return { ...state, name: action.payload };

    case "cnpj":
      return { ...state, cnpj: action.payload };

    case "plan":
      return { ...state, plan: action.payload };

    case "active":
      return { ...state, active: action.payload };

      case "photoURL":
      return { ...state, photoURL: action.payload };

    case "reset":
      return action.payload;

    default:
      throw new Error();

  }
}

export default function NewBusiness() {

  const [state, dispatch] = useReducer(businessStates, initialBusiness);

  // GET ID FROM URL PARAM
  const { businessId } = useParams();
  const navigate = useNavigate();

  // FINDING DATA FROM THE BUSINESS ON EDIT
  useEffect(() => {
    if (businessId !== 'new' && businessId) {
      const fetchBusinessData = async () => {
        try {
          // BUSCAR DADOS DO BANCO DE DADOS
          const docRef = doc(db, 'companies', businessId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();

            // EXIBIR DADOS NA TELA PARA EDIÇÃO
            dispatch({ type: 'id', payload: businessId });
            dispatch({ type: 'name', payload: data.name });
            dispatch({ type: 'plan', payload: data.plan });
            dispatch({ type: 'active', payload: data.active });
            dispatch({ type: 'cnpj', payload: data.cnpj });
            dispatch({ type: 'photoURL', payload: data.photoURL });
          } else {
            navigate('/notfound/business');
          }
        } catch (error) {
          console.error('Erro ao buscar dados do documento: ', error);
          navigate('/notfound/business'); // Redirecionar para página de erro, se necessário
        }
      };

      fetchBusinessData();
    }
  }, []);

  const [submitBusiness, setSubmitBusiness] = useState(false);

  const [messageName, setMessageName] = useState(false);
  const [messageCNPJ, setMessageCNPJ] = useState(true);

  // ENVIAR MUDANÇA DE NOME E VERIFICAR
  const changeName = (e: any) => {
    dispatch({ type: "name", payload: e })
  };

  // ENVIAR MUDANÇA DE CNPJ E VERIFICAR
  const changeCNPJ = (e: any) => {
    dispatch({ type: "cnpj", payload: e });
  };

  useEffect(() => {

    // VERIFY NAME
    if (state.name !== "" && state.name !== undefined && messageName === true) {
      setMessageName(false);
    } else if ((state.name === "" || state.name === undefined) && messageName === false) {
      setMessageName(true);
    };

    // VERIFY CNPJ
    if (state.cnpj !== "" && state.cnpj !== undefined && messageCNPJ === true) {
      setMessageCNPJ(false);
    } else if ((state.cnpj === "" || state.cnpj === undefined) && messageCNPJ === false) {
      setMessageCNPJ(true);
    };


  },[state])

  // ENVIAR MUDANÇA DE PACOTE
  const changePlan = (e: any) => {
    dispatch({ type: "plan", payload: e });
  };


  useEffect(() => {

    if (messageName === false && state.name !== "" && state.name !== undefined
      && messageCNPJ === false
    ) {
      setSubmitBusiness(true);
    } else if (submitBusiness === true) {
      setSubmitBusiness(false);
    }
  }, [messageName, state, messageCNPJ]);

  // SUBMIT
  const submit = async (e: any) => {
    try {
      if (submitBusiness === true) {
        await setDoc(doc(db, "companies", state.id), {
          name: state.name,
          cnpj: state.cnpj,
          plan: state.plan,
          active: state.active,
          photoURL: state.photoURL,
        });
        navigate(`/admin`);
      }
    } catch (error) {
      console.error("Error writing document: ", error);
      return "erro";
    }
  };

  const cancel = (e: any) => {
    navigate("/admin");
  }



  return (

    <section className={styleAdd.section}>

      <div className={styleAdd.view}>
        <FaBuilding className={styleAdd.item} />
        <div className={styleAdd.printObjective}>
          {state.name}
        </div>
      </div>

      <div className={styleAdd.content}>
        <section className={style.section}>

          <div className={style.title}><FaCodeBranch className={style.item} />Empresa</div>

          <div className={style.div__input}>

            <TextField
              label="Nome da Empresa"
              error={Boolean(messageName)}
              helperText={messageName ? "Digite um nome para a Empresa" : ""}
              value={state.name}
              onChange={(e: any) => changeName(e.target.value)}
              sx={{
                width: '100%', '& label': {
                  minWidth: 200,
                },
              }}
              fullWidth
            />
          </div>
          <div className={style.div__input}>

            <TextField
              label="CNPJ"
              value={state.cnpj}
              onChange={(e: any) => changeCNPJ(e.target.value)}
              sx={{
                width: '100%', '& label': {
                  minWidth: 200,
                },
              }}
              fullWidth
            />

          </div>
          <div className={style.div__input}>

            <TextField
              id="plano"
              select
              label="Plano"
              value={state.plan}
              onChange={(e: any) => changePlan(e.target.value)}
              sx={{
                width: '100%', '& label': {
                  minWidth: 200,
                },
              }}
            >
              <MenuItem value="basic">basic</MenuItem>
              <MenuItem value="outro">outro</MenuItem>
            </TextField>

            <div className={style.admin}>
              <div className={style.label}>Negócio Ativo:</div>
              <div className={style.adminSelect} onClick={() => dispatch({ type: "active", payload: !state.active })}>{state.active === true
                ? <><FaCheckCircle className={style["active--true"]} /> ATIVO</>
                : <><FaTimesCircle className={style["active--false"]} /> INATIVO</>
              }</div>
            </div>
          </div>
          <div className={style.controls}>

            <ButtonSystem action={(e: any) => cancel(e)} status={true} text="Cancelar" to="previous" icon="cancel" />

            <ButtonSystem action={(e: any) => submit(e)} status={submitBusiness} text="Salvar" to="finish" icon="finish" />
          </div>
        </section>

      </div>
    </section>
  );
}