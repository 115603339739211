import style from "./dashboard.module.scss";
import stylePages from "styles/pages.module.scss";
import { FaBatteryThreeQuarters, FaChartPie, FaCheckCircle, FaFlag, FaList, FaSpinner } from "react-icons/fa";
import { okrsState, userDataState } from "state/atom";
import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import UpdateFirestore from "components/general/update";
import { TbTarget } from "react-icons/tb";
import GaugeChart from "components/charts/gaugeChart";
import OkrDash from "components/okr/okrDash";
import Filters from "components/filters";
import { IOKR } from "interface/allOkrs";
import DoughnutPercentage from "components/charts/doughnutPercentage";

export default function Dashboard() {

  // Info about OKRs

  const okrs = useRecoilValue(okrsState);
  const userData = useRecoilValue(userDataState);

  const [filteredOkrs, setFilteredOkrs] = useState<IOKR[]>([]);

  const allKrs = filteredOkrs.flatMap((okr) => okr.krs);

  // Informações para o gráfico

  const [doneValue, setDoneValue] = useState(0);
  const [missingValue, setMissingValue] = useState(0);
  const [percent, setPercent] = useState(0);
  const [chartKey, setChartKey] = useState(0);

  // Quantity of OKRs

  const qtdOkrs = filteredOkrs.length;

  // Quantity of KRs

  const qtdKrs = filteredOkrs.reduce((acc: number, okr: any) => {
    return acc + okr.krs.length;
  }, 0);

  // Quantity of Checkpoints

  const qtdCheckpoints = filteredOkrs.reduce((acc: number, okr: any) => {
    return acc + okr.krs.reduce((acc: number, kr: any) => {
      return acc + kr.checkpoints.length;
    }, 0);
  }, 0);

  // Quantity of Tasks with Status "Open"
  const qtdOpenTasks = filteredOkrs.reduce((acc: number, okr: any) => {
    return acc + okr.krs.reduce((accKr: number, kr: any) => {
      return (
        accKr +
        kr.tasks.reduce((accTask: number, task: any) => {
          return task.status === "open" ? accTask + 1 : accTask;
        }, 0)
      );
    }, 0);
  }, 0);

  // Quantity of Tasks with Status "Progress"
  const qtdProgressTasks = filteredOkrs.reduce((acc: number, okr: any) => {
    return acc + okr.krs.reduce((accKr: number, kr: any) => {
      return (
        accKr +
        kr.tasks.reduce((accTask: number, task: any) => {
          return task.status === "progress" ? accTask + 1 : accTask;
        }, 0)
      );
    }, 0);
  }, 0);

  // Quantity of Tasks with Status "Done"
  const qtdDoneTasks = filteredOkrs.reduce((acc: number, okr: any) => {
    return acc + okr.krs.reduce((accKr: number, kr: any) => {
      return (
        accKr +
        kr.tasks.reduce((accTask: number, task: any) => {
          return task.status === "done" ? accTask + 1 : accTask;
        }, 0)
      );
    }, 0);
  }, 0);


  // Percentage of Achieved OKRs

  useEffect(() => {
    let sumTotal = 0;

    filteredOkrs.forEach((okr) => {
      let okrDoneValue = 0;

      okr.krs.forEach((kr) => {
        const checkpoints = kr.checkpoints;
        const totalValue = kr.finalValue;
        const initialValue = kr.initialValue;
        const resultType = kr.resultType;

        if (checkpoints.length > 0) {
          let latestCheckpoint = checkpoints.reduce((previousCheckpoint, currentCheckpoint) => {
            return previousCheckpoint.date > currentCheckpoint.date ? previousCheckpoint : currentCheckpoint;
          });

          const checkpointValue = parseFloat(`${latestCheckpoint.value}`);

          if (resultType === "Conquista") {
            const preDone = checkpointValue - initialValue;
            const preTotal = totalValue - initialValue;

            const percentageValue = ((100 / preTotal) * preDone);
            const percent = parseInt(`${percentageValue}`);
            okrDoneValue += percent;
          } else if (resultType === "Redução") {
            const preDone = initialValue - checkpointValue;
            const preTotal = initialValue - totalValue;

            const percentageValue = ((100 / preTotal) * preDone);
            const percent = parseInt(`${percentageValue}`);
            okrDoneValue += percent;
          } else if (resultType === "Manter") {
            if (checkpointValue < totalValue) {
              const preDone = checkpointValue;
              const preTotal = totalValue;

              const percentageValue = ((100 / preTotal) * preDone);
              const percent = parseInt(`${percentageValue}`);
              okrDoneValue += percent;
            } else if (checkpointValue > totalValue) {
              const preDone = checkpointValue - totalValue;
              const preTotal = totalValue - preDone;

              const percentageValue = ((100 / totalValue) * preTotal);
              const percent = parseInt(`${percentageValue}`);
              okrDoneValue += percent;
            } else if (checkpointValue === totalValue) {
              const percentageValue = 100;
              const percent = parseInt(`${percentageValue}`);
              okrDoneValue += percent;
            }
          }
        }
      });

      // Calculate the percentage of completed KR for this OKR
      const krDonePercentage = okr.krs.length > 0 ? okrDoneValue / okr.krs.length : 0;

      sumTotal += krDonePercentage;
    });

    // Calculate the percentage of completed OKRs
    const total = sumTotal / filteredOkrs.length;
    const totalPercent = total.toFixed(0);
    const totalFinal = parseInt(`${totalPercent}`);
    setDoneValue(totalFinal);

    if (totalFinal < 100) {
      const missingValue = 100 - totalFinal;
      setMissingValue(missingValue);
    } else {
      setMissingValue(0);
    }

    const percent = parseInt(`${totalFinal}`);

    if (percent < 100) {
      setPercent(percent);
    } else {
      setPercent(100);
    }

    setChartKey(chartKey + 1);
  }, [filteredOkrs]);

  const filters = (e: any) => {
    setFilteredOkrs(e);
    setChartKey(chartKey + 1);
  }

  return (
    <section className={style.section}>

      <div className={stylePages.header}>

        {/* okr infos */}

        <div className={stylePages.header__container}>
          <div className={stylePages.header__title}>
            Olá, {userData.name}!
          </div>
        </div>

        {/* action buttons */}

        <div className={stylePages.header__actions}>
          <Filters listOfOkrs={okrs} filteredOkrs={(e: any) => filters(e)} />
          <UpdateFirestore />
        </div>
      </div>

      {/* kr infos - 1 line */}

      <div className={style.widget__line}>

        {/* objective */}

        <div className={style.widget__container40}>
          <div className={style.widget__title}>
            <FaChartPie className={style.title__icon} />
            Resultado Geral
          </div>
          <div className={style.widget__principal}>
            {filteredOkrs.length !== 0 ? <DoughnutPercentage key={chartKey} doneValue={doneValue} initialValue={0} finalValue={100} resultType="Conquista" height={300} /> : null}

            {/* {filteredOkrs.length !== 0 ? <GaugeChart key={chartKey} doneValue={doneValue} missingValue={missingValue} percentValue={percent} /> : null} */}
          </div>
        </div>


        {/* percentage */}
        <div className={style.vertical}>

          <div className={style.widget__container60}>



            <div className={style.widget__container20Roxo}>
              <div className={style.widget__titleRoxo}>
                <TbTarget className={style.title__icon} />
                OKRs
              </div>
              <div className={style.widget__value}>
                <div className={style.title}>
                  {qtdOkrs}
                </div>
                <div className={style.subtitleRoxo}>
                  {qtdOkrs === 1 ? "objetivo" : "objetivos"}
                </div>
              </div>
            </div>

            <div className={style.widget__container20Roxo}>
              <div className={style.widget__titleRoxo}>
                <FaBatteryThreeQuarters className={style.title__icon} />
                KRs
              </div>
              <div className={style.widget__value}>
                <div className={style.title}>
                  {qtdKrs}
                </div>
                <div className={style.subtitleRoxo}>
                  {qtdKrs === 1 ? "resultado" : "resultados"}
                </div>
              </div>
            </div>

            <div className={style.widget__container20Roxo}>
              <div className={style.widget__titleRoxo}>
                <FaFlag className={style.title__icon} />
                Checkpoints
              </div>
              <div className={style.widget__value}>
                <div className={style.title}>
                  {qtdCheckpoints}
                </div>
                <div className={style.subtitleRoxo}>
                  {qtdCheckpoints === 1 ? "checkpoint" : "checkpoints"}
                </div>
              </div>
            </div>

          </div>

          <div className={style.widget__container60}>

            <div className={style.widget__container20}>
              <div className={style.widget__title}>
                <FaList className={style.title__icon} />
                A Fazer
              </div>
              <div className={style.widget__value}>
                <div className={style.title}>
                  {qtdOpenTasks}
                </div>
                <div className={style.subtitle}>
                  {qtdOkrs === 1 ? "tarefa" : "tarefas"}
                </div>
              </div>
            </div>

            <div className={style.widget__container20}>
              <div className={style.widget__title}>
                <FaSpinner className={style.title__icon} />
                Em Progresso
              </div>
              <div className={style.widget__value}>
                <div className={style.title}>
                  {qtdProgressTasks}
                </div>
                <div className={style.subtitle}>
                  {qtdKrs === 1 ? "tarefa" : "tarefas"}
                </div>
              </div>
            </div>

            <div className={style.widget__container20}>
              <div className={style.widget__title}>
                <FaCheckCircle className={style.title__icon} />
                Concluídas
              </div>
              <div className={style.widget__value}>
                <div className={style.title}>
                  {qtdDoneTasks}
                </div>
                <div className={style.subtitle}>
                  {qtdCheckpoints === 1 ? "tarefa" : "tarefas"}
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      {/* kr infos - 2 line */}

      <div className={style.widget__line}>

        <div className={style.column}>

          {/* okrs */}

          {filteredOkrs.length === 0 ? (
            <div className={style.noData}>Nenhum objetivo encontrado.</div>
          ) : filteredOkrs.map((okr, index) =>
            <OkrDash data={okr} key={index} />
          )}

        </div>

      </div>

    </section >
  );
}