import BusinessConfig from "components/business/configBusiness";
import ScrollToTop from "components/general/scrolltotop";
import TeamsConfig from "components/teams/configTeams";
import ListUsers from "components/users/listUsers";
import Configuracoes from "pages/configuracoes";
import Dashboard from "pages/dashboard";
import NewKResult from "pages/kr/newkr";
import PageKr from "pages/kr/pageKr";
import Okrs from "pages/okrs/allOkrs";
import NewOkr from "pages/okrs/newokr";
import SingleOkrView from "pages/okrs/singleOkr";
import ResponsePage from "pages/response";
import PageAddMeet from "pages/meetings/addMeeting";
import Meetings from "pages/meetings/allMeetings";
import SingleMeet from "pages/meetings/singleMeeting";
import ForgotPassword from "pages/system/forgotPassword";
import Login from "pages/system/login";
import AddTarefas from "pages/tarefas/addTask";
import Tasks from "pages/tarefas/kanban";
import EditTeams from "pages/teams/edit";
import TeamObjectives from "pages/teams/teamObjectives";
import AddUser from "pages/user/addUser";
import MyObjectives from "pages/user/myObjectives";
import NewUser from "pages/user/newuser";
import UserConfig from "pages/user/userConfig";
import Admin from "pages/yscale/admin";
import NewBusiness from "pages/yscale/newBusiness";
import { Routes, Route, Navigate } from "react-router-dom";
import Archive from "pages/archive";

const RoutesComponent: React.FC<{ userId: any; userData: any }> = ({ userId, userData }) => {

  return (
    <Routes>
      {userId !== null && userId !== undefined ? (
        <>
          {/* LOGIN / INÍCIO */}

          <Route path="/login" element={<Navigate to="/" />} />
          <Route path="/" element={<><ScrollToTop /><Dashboard /></>} />

          {/* OKRS */}

          <Route path="/objetivos" element={<><ScrollToTop /><Okrs /></>} />
          <Route path="/arquivo" element={<><ScrollToTop /><Archive /></>} />
          <Route path="/kr/:okr/:kr" element={<><ScrollToTop /><PageKr /></>} />
          <Route path="/okr/:okr" element={<><ScrollToTop /><SingleOkrView /></>} />
          <Route path="/edit/:okr" element={<><ScrollToTop /><NewOkr /></>} />
          <Route path="/edit/:okr/:kr" element={<><ScrollToTop /><NewKResult /></>} />
          <Route path="/meusresultados" element={<><ScrollToTop /><MyObjectives /></>} />
          <Route path="/time" element={<><ScrollToTop /><TeamObjectives /></>} />

          {/* TIMES */}

          <Route path="/team/:teamid" element={<><ScrollToTop /><EditTeams /></>} />

          {/* TAREFAS */}

          <Route path="/tarefas" element={<><ScrollToTop /><Tasks /></>} />
          <Route path="/tarefas/:okr/:kr/:taskid" element={<><ScrollToTop /><AddTarefas /></>} />

          {/* USUÁRIOS */}
          <Route path="/usuario" element={<><ScrollToTop /><UserConfig /></>} />
          <Route path="/invite/business/:businessId" element={userData.admin === true ? <><ScrollToTop /><AddUser /></> : ""} />

          {/* CONFIGURAÇÕES */}
          <Route path="/configuracoes" element={userData.admin === true ? <><ScrollToTop /><Configuracoes /></> : ""} />
          <Route path="/configuracoes/business" element={userData.admin === true ? <><ScrollToTop /><BusinessConfig /></> : ""} />
          <Route path="/configuracoes/teams" element={userData.admin === true ? <><ScrollToTop /><TeamsConfig /></> : ""} />
          <Route path="/configuracoes/users" element={userData.admin === true ? <><ScrollToTop /><ListUsers /></> : ""} />

          {/* REUNIÔES */}

          <Route path="/reunioes" element={<><ScrollToTop /><Meetings /></>} />
          <Route path="/reunioes/adicionar" element={<><ScrollToTop /><PageAddMeet /></>} />
          <Route path="/reunioes/detalhes/:meetId" element={<><ScrollToTop /><SingleMeet /></>} />

          {/* 404 */}

          <Route path="*" element={<><ScrollToTop /><ResponsePage linkToGo="/" text="Não encontramos esta página!" success={false} textForLink="Voltar ao Início" /></>} />


          {/* ADMIN YSCALE */}

          <Route path="/admin" element={userId === "pCmYv6tCmlP1T274cI0l4XKpog93" || userId === "vanGKNSp5haPMVRhJqvVxXzkr4k1" || userId === "bp7wdYCOr2g6TSpZxCQtd862XN72" ? <><ScrollToTop /><Admin /></> : ""} />
          <Route path="/business/:businessId" element={<><ScrollToTop /><NewBusiness /></>} />
          <Route path="/business/:businessId/users/" element={<><ScrollToTop /><ListUsers /></>} />
          <Route path="/notfound/user" element={<><ScrollToTop /><ResponsePage linkToGo="/okrs" text="Usuário não encontrado!" success={false} textForLink="Ir para Minhas Okrs" /></>} />
          <Route path="/notfound/business" element={<><ScrollToTop /><ResponsePage linkToGo="/okrs" text="Negócio não encontrado!" success={false} textForLink="Ir para Minhas Okrs" /></>} />

        </>
      ) : (
        <>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<><ScrollToTop /><Login /></>} />
          <Route path="/forgotpassword" element={<><ScrollToTop /><ForgotPassword /></>} />
          <Route path="/newinvite/:businessId/:idCad" element={<><ScrollToTop /><NewUser /></>} />
          <Route path="*" element={<Navigate to="/login" />} />

        </>
      )}


    </Routes>
  );
};

export default RoutesComponent;