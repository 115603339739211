import BarProgress from "components/charts/barProgress";
import ShowUsers from "components/users/show";
import { Teams } from "components/teams/showTeams";
import { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { TbTarget } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import style from "./KrSingle.module.scss";


export default function KrSingle(props: any) {

  const navigate = useNavigate();

  const goTo = () => {
    navigate(`/kr/${props.okr}/${props.data.id}`)
  };


  const [doneValue, setDoneValue] = useState(0);
  const [chartKey, setChartKey] = useState(0);

  const checkpoints = props.data.checkpoints;
  const totalValue = props.data.finalValue;
  const initialValue = props.data.initialValue;
  const resultType = props.data.resultType;
  const dataType = props.data.dataType;

  useEffect(() => {

    if (checkpoints.length > 0) {


      let latestCheckpoint = checkpoints.reduce((previousCheckpoint: any, currentCheckpoint: any) => {
        return previousCheckpoint.date > currentCheckpoint.date ? previousCheckpoint : currentCheckpoint;
      });

      const checkpointValue = parseFloat(`${latestCheckpoint.value}`);

      setDoneValue(checkpointValue);
      setChartKey(chartKey + 1);


    } else {
      setDoneValue(initialValue);
      setChartKey(chartKey + 1);
    }
  }, [props]);

  
  const teamsSort = [...props.data.teams].sort((a:any, b:any) => a.localeCompare(b));



  return (
    <section className={style.section} onClick={goTo}>

      <div className={style.container}>

        <div className={style.container__data}>

          <div className={style.container__title}>
            <div className={style.title}>
          {props.data.kr}
            </div>
            <div className={style.teams}>
              {teamsSort.map((e: any, index: any) =>
                <Teams team={e} key={index} />
              )}
            </div>
          </div>

          <div className={style.users}>
            <ShowUsers users={props.data.responsible} />
          </div>
        </div>
        <div className={style.container__chart}>

          <div className={style.chart}>
            <BarProgress key={chartKey} finalValue={totalValue} doneValue={doneValue} dataType={dataType} resultType={resultType} initialValue={initialValue} />
          </div>

          <FaChevronRight className={style.section__icon} />

        </div>

      </div>
    </section>
  );
}