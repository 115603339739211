import { FaEdit, FaTrash } from "react-icons/fa";
import style from "./singleTeam.module.scss";
import { baseURL } from "baseURL";
import { ITeam } from "interface/teams";
import { businessIdState, okrsState, updateState } from "state/atom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { IOKRData } from "interface/allOkrs";
import { useNavigate } from "react-router-dom";
import { collection, deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";


export default function SingleTeam(props: ITeam) {
  const okrs = useRecoilValue(okrsState);
  const navigate = useNavigate();
  const businessId = useRecoilValue(businessIdState);

  const update = useRecoilValue(updateState);
  const setUpdateState = useSetRecoilState(updateState);

  function countKRInTeam(teamId: string, okrData: IOKRData): number {
    let count = 0;

    okrData.forEach((okr) => {
      okr.krs.forEach((kr) => {
        if (kr.teams.includes(teamId)) {
          count++;
        }
      });
    });

    return count;
  }

  const teamId = props.id;
  const krCount = countKRInTeam(teamId, okrs);

  const goToTeams = () => {
    navigate("/time")
  }

  const goToEdit = () => {
    navigate(`/team/${props.id}`)
  }


  // DELETE TEAM

  const goDelete = () => {

    if (businessId) {
      // Define a referência para o documento de configuração da empresa
      const companyConfigRef = doc(db, "companies", businessId);

      // Recupera os dados atuais do documento
      getDoc(companyConfigRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const currentData = docSnapshot.data();

            // Verifica se já existe um campo "teams" no documento
            if (currentData.teams) {
              // Encontra o índice do time com base no ID, se existir
              const existingTeamIndex = currentData.teams.findIndex(
                (existingTeam: any) => existingTeam.id === props.id
              );

              if (existingTeamIndex !== -1) {
                // Se o time já existir, remove-o
                currentData.teams.splice(existingTeamIndex, 1);
              }

              // Atualiza o documento com os novos dados
              updateDoc(companyConfigRef, currentData)
                .then(() => {
                  setUpdateState(!update);
                })
                .catch((error) => {
                  console.error("Erro ao atualizar os times:", error);
                });
            }
          }
        })
        .catch((error) => {
          console.error("Erro ao recuperar os dados do documento:", error);
        });
    }

  };

  return (
    <div className={style.container}>
      <div className={style.title}>
        <img src={baseURL + props.icon} className={style.icon} alt={props.team} />
        {props.team}
      </div>
      <div className={style.krs} onClick={goToTeams}>

        {krCount} KRs
      </div>
      <div className={style.actions}>

        {krCount === 0 ? <FaTrash className={style.remove} onClick={goDelete} /> : ""}
        <FaEdit className={style.edit} onClick={goToEdit} />
      </div>
    </div>
  )
}