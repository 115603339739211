import AddMeet from "components/reunioes/addmeet";
import style from "styles/addSometing.module.scss";
import { FaCalendarAlt } from "react-icons/fa";

export default function PageAddMeeting() {

  const keyResult = "Qual a data da reunião estratégica?";



  return (
    <section className={style.section}>

      {/* LEFT */}

      <div className={style.view}>
        <FaCalendarAlt className={style.item} />
        <div className={style.printObjective}>
          {keyResult}
        </div>
      </div>

      {/* RIGHT */}

      <div className={style.content}>
        <div className={style.container}>
          <AddMeet />
        </div>
      </div>

    </section>
  )
}