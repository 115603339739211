import { auth } from "config/firebase";

export async function gettingUserIdAuth(setUserId: any) {
  return new Promise<void>((resolve) => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // User is signed in.
        const updateUserId = user.uid;
        setUserId(updateUserId);
      } else {
        // User is signed out.
        setUserId(undefined);
      }
      // Resolve a promessa após a conclusão da lógica
      resolve();
    });

    // Retornar uma função para descadastrar o observador quando necessário
    return () => {
      unsubscribe();
    };
  });
}