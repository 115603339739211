import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { userIdState, businessIdState } from "state/atom";
import { auth } from "../../../config/firebase";
import style from "./logout.module.scss";

export default function Logout() {
  const navigate = useNavigate();
  const setUserId = useSetRecoilState(userIdState);
  const setBusinessId = useSetRecoilState(businessIdState);

  async function handleLogout() {
    await auth.signOut();
    setUserId(null);
    setBusinessId(null);
    navigate("/login");
  }

  return (
    <div className={style.container} onClick={handleLogout}>
    <FiLogOut className={style.icon} />
    <div className={style.text}>Sair</div>
    </div>
  );
}