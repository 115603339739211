import { useEffect, useState } from "react";
import { FaChevronRight, FaCodeBranch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import style from "./okrDash.module.scss";
import BarProgressWhite from "components/charts/barProgressWhite";
import { Teams } from "components/teams/showTeams";

export default function OkrDash(props: any) {

  const [doneValue, setDoneValue] = useState(0);
  // const [missingValue, setMissingValue] = useState(0);
  // const [percent, setPercent] = useState(0);
  const [chartKey, setChartKey] = useState(0);

  const navigate = useNavigate();

  const krs = props.data.krs;

  useEffect(() => {

    if (krs.length === 0) {
      // Caso não haja KRs, definir valores padrão
      setDoneValue(0);
      // setMissingValue(1);
      // setPercent(0);
      setChartKey(chartKey + 1);
      return;
    }

    let sumTotal = 0;

    krs.forEach((e: any) => {

      const checkpoints = e.checkpoints;
      const totalValue = e.finalValue;
      const initialValue = e.initialValue;
      const resultType = e.resultType;

      if (checkpoints.length > 0) {

        let latestCheckpoint = checkpoints.reduce((previousCheckpoint: any, currentCheckpoint: any) => {
          return previousCheckpoint.date > currentCheckpoint.date ? previousCheckpoint : currentCheckpoint;
        });

        const checkpointValue = parseFloat(`${latestCheckpoint.value}`);

        if (resultType === "Conquista") {

          const preDone = checkpointValue - initialValue;
          const preTotal = totalValue - initialValue;

          const percentageValue = ((100 / preTotal) * preDone);
          const percent = parseInt(`${percentageValue}`);
          sumTotal += percent;

        } else if (resultType === "Redução") {

          const preDone = initialValue - checkpointValue;
          const preTotal = initialValue - totalValue;

          const percentageValue = ((100 / preTotal) * preDone);
          const percent = parseInt(`${percentageValue}`);
          sumTotal += percent;

        } else if (resultType === "Manter") {

          if (checkpointValue < totalValue) {

            const preDone = checkpointValue;
            const preTotal = totalValue;

            const percentageValue = ((100 / preTotal) * preDone);
            const percent = parseInt(`${percentageValue}`);
            sumTotal += percent;

          } else if (checkpointValue > totalValue) {

            const preDone = checkpointValue - totalValue; // 110 - 100 = 10
            const preTotal = totalValue - preDone; // 100 - 10 = 90

            const percentageValue = ((100 / totalValue) * preTotal); // 100 / 100 * 90 = 90
            const percent = parseInt(`${percentageValue}`);
            sumTotal += percent;

          } else if (checkpointValue === totalValue) {

            const percentageValue = (100);
            const percent = parseInt(`${percentageValue}`);
            sumTotal += percent;

          }
        }

      }
    });

    const total = sumTotal / krs.length;
    const totalPercent = total.toFixed(0);
    const totalFinal = parseInt(`${totalPercent}`);
    setDoneValue(totalFinal);

    // if (totalFinal < 100) {
    //   const missingValue = 100 - totalFinal;
    //   setMissingValue(missingValue);
    // } else {
    //   setMissingValue(0);
    // }

    // const percent = parseInt(`${totalFinal}`);

    // if (percent < 100) {
    //   setPercent(percent);
    // } else {
    //   setPercent(100);
    // }

    setChartKey(chartKey + 1);

  }, [krs]);


  // Getting all teams from okr
  const allTeams = krs.flatMap((krs: any) => krs.teams);
  const uniqueTeamsSet = new Set(allTeams);
  const teamsFromKrs = Array.from(uniqueTeamsSet);

  const goTo = () => {
    navigate(`/okr/${props.data.id}/`)
  };

  return (
    <div className={style.container} onClick={goTo}>
      <div className={style.okr}>
        <FaCodeBranch className={style.item} />
        <div className={style.title}>
          <div>
            {props.data.okrName}
          </div>
          <div className={style.teams}>
            {teamsFromKrs.map((e: any, index: any) =>
              <Teams team={e} key={index} />
            )}
          </div>

        </div>
        <div className={style.chart}>
          <BarProgressWhite key={chartKey} doneValue={doneValue} finalValue={100} initialValue={0} resultType="Conquista" dataType="%" />
        </div>
        <div className={style.go}>
          <FaChevronRight className={style.section__icon} />
        </div>
      </div>
    </div>
  );
}
