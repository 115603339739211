import OkrSingle from "components/okr/okrSingle";
import stylePages from "styles/pages.module.scss";
import { useRecoilValue } from "recoil";
import { businessIdState } from "state/atom";
import { useNavigate } from "react-router-dom";
import ButtonSystem from "components/forms/button";
import Filters from "components/filters";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";
import { IOKR } from "interface/allOkrs";
import OkrNotFound from "components/okr/okrNotFound";

export default function Archive(){

  const [okrsArchived, setOkrsArchived] = useState<IOKR[]>([]);
  const businessId = useRecoilValue(businessIdState);

  useEffect(() => {
    
    async function gettingOkrs(businessId: any, setOkrs: any) {
      try {
        if (businessId) {
          // Define a referência para a coleção de "okrs" da empresa
          const okrsRef = collection(db, "companies", businessId, "archived");
    
          // Busca todos os documentos dentro da coleção "okrs"
          const okrsSnapshot = await getDocs(okrsRef);
    
          // Cria um array com as informações dos documentos encontrados
          const okrsData = await Promise.all(
            okrsSnapshot.docs.map(async (okrDoc) => {
              const krs = okrDoc.data().krs === undefined || !okrDoc.data().krs ? [] : okrDoc.data().krs;
    
              return {
                id: okrDoc.id,
                okrName: okrDoc.data().okrName,
                initialDate: okrDoc.data().initialDate,
                finalDate: okrDoc.data().finalDate,
                krs: krs.map((kr: any) => ({
                  id: kr.id,
                  kr: kr.kr,
                  dataType: kr.dataType,
                  resultType: kr.resultType,
                  responsible: kr.responsible,
                  initialValue: kr.initialValue,
                  finalValue: kr.finalValue,
                  teams: kr.teams || [],
                  checkpoints: kr.checkpoints === undefined || !kr.checkpoints ? [] : kr.checkpoints,
                  tasks: kr.tasks === undefined || !kr.tasks ? [] : kr.tasks,
                  initialDate: kr.initialDate,
                  finalDate: kr.finalDate,
                })),
              };
            })
          );
    
          setOkrsArchived(okrsData);
        }
      } catch (error) {
        console.error("Error fetching okrs: ", error);
      }
    }

    gettingOkrs(businessId, setOkrsArchived);

  },[]);
  

  const [filteredOkrs, setFilteredOkrs] = useState([]);

  const navigate = useNavigate();

  const filters = (e: any) => {
    setFilteredOkrs(e);
  }

  const voltar = () => {
    navigate(-1)
  }

  return (
    <section className={stylePages.container__section}>
      <div className={stylePages.header}>

        <div className={stylePages.header__container}>
          <div className={stylePages.header__title}>
            Objetivos Arquivados
          </div>
        </div>

        <div className={stylePages.header__actions}>
        <ButtonSystem action={(e: any) => voltar()} status={true} text="Voltar" to="previous" icon="back" />
          <Filters listOfOkrs={okrsArchived} filteredOkrs={(e:any) => filters(e)} />

        </div>
      </div>
      {filteredOkrs.length === 0 ? <OkrNotFound /> : ""}

      {filteredOkrs.map((okr:any, index:any) => (
        <OkrSingle key={index} data={okr} select={true} archive={true} />
      ))}
    </section>
  );
}