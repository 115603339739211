import { db } from "config/firebase";
import { collection, getDocs } from "firebase/firestore";

export async function gettingOkrs(businessId: any, setOkrs: any) {
  try {
    if (businessId) {
      // Define a referência para a coleção de "okrs" da empresa
      const okrsRef = collection(db, "companies", businessId, "okrs");

      // Busca todos os documentos dentro da coleção "okrs"
      const okrsSnapshot = await getDocs(okrsRef);

      // Cria um array com as informações dos documentos encontrados
      const okrsData = await Promise.all(
        okrsSnapshot.docs.map(async (okrDoc) => {
          const krs = okrDoc.data().krs === undefined || !okrDoc.data().krs ? [] : okrDoc.data().krs;

          return {
            id: okrDoc.id,
            okrName: okrDoc.data().okrName,
            initialDate: okrDoc.data().initialDate,
            finalDate: okrDoc.data().finalDate,
            krs: krs.map((kr: any) => ({
              id: kr.id,
              kr: kr.kr,
              dataType: kr.dataType,
              resultType: kr.resultType,
              responsible: kr.responsible,
              initialValue: kr.initialValue,
              finalValue: kr.finalValue,
              teams: kr.teams || [],
              checkpoints: kr.checkpoints === undefined || !kr.checkpoints ? [] : kr.checkpoints,
              tasks: kr.tasks === undefined || !kr.tasks ? [] : kr.tasks,
              initialDate: kr.initialDate,
              finalDate: kr.finalDate,
            })),
          };
        })
      );

      setOkrs(okrsData);
    }
  } catch (error) {
    console.error("Error fetching okrs: ", error);
  }
}