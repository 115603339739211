const today = new Date();
const year = today.getFullYear();
const mo = today.getMonth() + 1;
const da = today.getDate();
const month = mo < 10 ? "0" + mo : mo;
const day = da < 10 ? "0" + da : da;
const dateToStart = year + "-" + month + "-" + day;

const today2 = today.setDate(today.getDate() + 90);
const today3 = new Date(today2);

const year3 = today3.getFullYear();
const mo3 = today3.getMonth() + 1;
const da3 = today3.getDate();
const month3 = mo3 < 10 ? "0" + mo3 : mo3;
const day3 = da3 < 10 ? "0" + da3 : da3;

const dateToEnd = year3 + "-" + month3 + "-" + day3;

export {today, dateToStart, dateToEnd};
