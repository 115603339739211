import { db } from "config/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

interface IDeleteKr {
  businessId: any;
  okr: any;
  kr: any;
}

export default async function DeleteKr(props: IDeleteKr) {

  // Define a referência para o documento de OKR
  const okrRef = doc(db, "companies", props.businessId, "okrs", props.okr);

  // Verifica se o documento OKR existe
  getDoc(okrRef)
    .then((okrDocSnapshot) => {
      if (okrDocSnapshot.exists()) {
        // O documento OKR existe

        // Obtém os dados do documento OKR
        const okrData = okrDocSnapshot.data();

        // Verifica se a lista de KRs existe no documento OKR
        if (okrData.krs) {
          // Procura o KR pelo ID
          const existingKrIndex = okrData.krs.findIndex((kr: any) => kr.id === props.kr);

          if (existingKrIndex !== -1) {
            // Remove o KR da lista
            okrData.krs.splice(existingKrIndex, 1);

            // Atualiza o documento OKR com os KRs atualizados
            updateDoc(okrRef, okrData).then(() => {
              // Realize as ações necessárias após a remoção do KR
              // Por exemplo, atualize o estado ou realize outras operações
            }).catch((error) => {
              console.error("Erro ao atualizar o documento OKR:", error);
            });
          } else {
            // Se o KR não existir, não é possível removê-lo
            console.error("O KR não existe. Não é possível remover um KR que não existe.");
          }
        } else {
          // Se a lista de KRs não existir, não é possível remover um KR
          console.error("A lista de KRs não existe. Não é possível remover um KR.");
        }
      } else {
        // O documento OKR não existe, não é possível remover um KR separadamente
        console.error("O documento OKR não existe. Não é possível remover um KR separadamente.");
      }
    })
    .catch((error) => {
      console.error("Erro ao verificar a existência do documento OKR:", error);
    });

  return
}
