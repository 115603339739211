import AddTask from "components/tasks/addTask";
import { TbList, TbTarget } from "react-icons/tb";
import { useState } from "react";
import style from "styles/addSometing.module.scss";
import { useParams } from "react-router-dom";

export default function AddTarefas(){

  const [data, setData] = useState() as any;
  const { okr, kr, taskid } = useParams();

  const keyResult = data ? data : "Nova Tarefa";

return (
  <section className={style.section}>

    {/* LEFT */}

    <div className={style.view}>
      <TbList className={style.item} />
      <div className={style.printObjective}>
        {keyResult}
      </div>
    </div>

    {/* RIGHT */}

    <div className={style.content}>
      <div className={style.container}>
      <AddTask okr={okr} kr={kr} taskId={taskid} data={(e: any) => setData(e)} />
      </div>
    </div>

  </section>
);
}