import { useRecoilValue, useSetRecoilState } from "recoil";
import { meetingsState, updateMeetingState, updateState } from "state/atom";
import ButtonSystemNoText from "components/forms/button/buttonNoText";


export default function UpdateMeetings(){

  const updateMeeting = useRecoilValue(updateMeetingState);
  const setUpdateMeetings = useSetRecoilState(updateMeetingState);

  return(

      <ButtonSystemNoText action={(e:any) => setUpdateMeetings(!updateMeeting)} status={true} text="Atualizar" to="next" icon="update" />
  )
}