import OkrSingle from "components/okr/okrSingle";
import stylePages from "styles/pages.module.scss";
import { useRecoilValue } from "recoil";
import { okrsState } from "state/atom";
import { useNavigate, useParams } from "react-router-dom";
import ButtonSystemNoText from "components/forms/button/buttonNoText";
import UpdateFirestore from "components/general/update";

export default function SingleOkrView() {
  const okrs = useRecoilValue(okrsState);
  const { okr } = useParams();

  const navigate = useNavigate();

  const filteredOkr = okrs.find((o) => o.id === okr);

  const previousPage = () => {
    navigate(-1);
  }

  return (
    <section className={stylePages.container__section}>

      <div className={stylePages.header}>

        <div className={stylePages.header__container}>
          <div className={stylePages.header__title}>
            
          </div>
        </div>

        <div className={stylePages.header__actions}>
          <ButtonSystemNoText action={previousPage} status={true} text="" to="previous" icon="back" />
          <UpdateFirestore />
        </div>
      </div>

      {okrs.length === 0 ? <div className={stylePages.noData}>Sua empresa ainda não tem objetivos cadastrados.</div> : ""}
      <OkrSingle data={filteredOkr} select={true} archive={false} key={0} />
    </section>
  );
}
