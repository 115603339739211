import { useEffect, useReducer, useState } from "react";
import style from "./AddCheckpoint.module.scss";
import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { okrsState, updateState, userIdState } from "state/atom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { formatNumber, removeFormat } from "components/date/formatNumber";
import { dateToStart } from "components/date";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { ValidateDate } from "components/date/validate";
import ButtonSystem from "components/forms/button";
import { FaInfoCircle, FaTimes, FaTimesCircle } from "react-icons/fa";

const initialState = {
  date: dateToStart,
  responsible: "",
  value: 0,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {

    case "date":
      return { ...state, date: action.payload };

    case "responsible":
      return { ...state, responsible: action.payload };

    case "value":
      return { ...state, value: action.payload };

    case "obs":
      return { ...state, obs: action.payload };

    case "reset":
      return action.payload;

    default:
      throw new Error();

  }
}

type AddCheckpointProps = {
  businessId: any;
  okr: any;
  kr: any;
  update: any;
  minDate: any;
  maxDate: any;
}

export default function AddCheckpoint(props: AddCheckpointProps) {

  // get all data
  const [messageDate, setMessageDate] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const userId = useRecoilValue(userIdState);
  const update = useRecoilValue(updateState);
  const setUpdateState = useSetRecoilState(updateState);
  const [isCheckpointExists, setIsCheckpointExists] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [valueError, setValueError] = useState(false);
  const okrs = useRecoilValue(okrsState);


  useEffect(() => {
    const currentDate = new Date();
    const maxDate = new Date(props.maxDate);
    const minDate = new Date(props.minDate);

    if (currentDate > maxDate) {
      setMessageDate(true)
    } else if (currentDate < minDate) {
      setMessageDate(true)
    } else {
      setMessageDate(false)
    }
  }, [props.maxDate, props.minDate]);

  const firstDateX = dayjs(props.minDate, 'YYYY-MM-DD');
  const firstDate = firstDateX.format("DD/MM/YYYY");
  const lastDateX = dayjs(props.maxDate, 'YYYY-MM-DD');
  const lastDate = lastDateX.format("DD/MM/YYYY");


  // user id as reponsible
  useEffect(() => {
    dispatch({ type: "responsible", payload: userId });
  }, [userId])

  useEffect(() => {
    const checkIfCheckpointExists = async () => {
      try {
        const dateId = state.date.replaceAll("/", "-");

        // Filtra o OKR com o mesmo ID
        const selectedOKR = okrs.find((okr) => okr.id === props.okr);

        if (selectedOKR) {
          // Filtra o KR com o mesmo ID dentro do OKR selecionado
          const selectedKR = selectedOKR.krs.find((kr) => kr.id === props.kr);

          if (selectedKR && selectedKR.checkpoints) {
            // Verifica se algum checkpoint possui a propriedade 'id' igual a 'dateId'
            const checkpointExists = selectedKR.checkpoints.some((checkpoint) => checkpoint.date === dateId);

            setIsCheckpointExists(checkpointExists);
          } else {
            setIsCheckpointExists(false);
          }
        } else {
          setIsCheckpointExists(false);
        }
      } catch (error) {
        console.error("Error checking checkpoint existence: ", error);
      }
    };

    checkIfCheckpointExists();
  }, [props.businessId, props.okr, props.kr, state.date, okrs]);

  // Changing Date
  const changeDate = (e: any) => {
    const date = dayjs(e);
    const formattedDate = date.format("YYYY-MM-DD");
    dispatch({ type: "date", payload: formattedDate })
  }

  // Changing Value
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
      setValueError(true);
    } else {
      if (valueError === true) {
        setValueError(false);
      }
      const value = removeFormat(e.target.value);

      if (value) {
        dispatch({ type: "value", payload: value })
      }
    }
  };

  // Value On Blur
  const valueOnBlur = (e: any) => {
    const value = removeFormat(e.target.value);
    const value2 = Number(value);
    if (value2) {
      dispatch({ type: "value", payload: value2 })
    }
  };



  // VALIDATE DATE
  const validateDate = (e: any) => {
    const date = dayjs(e, 'DD/MM/YYYY');
    const formattedDate = date.format("YYYY-MM-DD");
    const validate = ValidateDate(formattedDate);
    if (validate === true) {
      setMessageDate(false);
    } else {
      setMessageDate(true);
    }
  }

  const verifyCheckpoint = () => {
    if (isCheckpointExists) {
      setOpenModal(true);
    } else {
      addCheckpoint();
    }

  }


  // SUBMIT
  const addCheckpoint = () => {

    // Define a referência para o documento de OKR
    const okrRef = doc(db, "companies", props.businessId, "okrs", props.okr);

    // Verifica se o documento OKR existe
    getDoc(okrRef)
      .then((okrDocSnapshot) => {
        if (okrDocSnapshot.exists()) {
          // O documento OKR existe

          // Obtém os dados do documento OKR
          const okrData = okrDocSnapshot.data();

          // Verifica se a lista de KRs existe no documento OKR
          if (okrData.krs) {
            // Procura o KR pelo ID
            const existingKrIndex = okrData.krs.findIndex((kr: any) => kr.id === props.kr);

            if (existingKrIndex !== -1) {
              // Se o KR existir, verifica se a lista de checkpoints existe no KR
              if (okrData.krs[existingKrIndex].checkpoints) {
                // Procura o checkpoint pela data ou cria um novo
                const existingCheckpointIndex = okrData.krs[existingKrIndex].checkpoints.findIndex((checkpoint: any) => checkpoint.date === state.date);

                if (existingCheckpointIndex !== -1) {
                  // Se o checkpoint existir, atualiza apenas as informações relevantes
                  okrData.krs[existingKrIndex].checkpoints[existingCheckpointIndex] = { ...state };
                } else {
                  // Se o checkpoint não existir, cria um novo
                  okrData.krs[existingKrIndex].checkpoints.push({ ...state });
                }
              } else {
                // Se a lista de checkpoints não existir, cria-a com o novo checkpoint
                okrData.krs[existingKrIndex].checkpoints = [{ ...state }];
              }
            } else {
              // Se o KR não existir, não é possível adicionar um checkpoint
              console.error("O KR não existe. Não é possível adicionar um checkpoint separadamente.");
              return null;
            }
          } else {
            // Se a lista de KRs não existir, não é possível adicionar um checkpoint
            console.error("A lista de KRs não existe. Não é possível adicionar um checkpoint separadamente.");
            return null;
          }

          // Atualiza o documento OKR com os KRs atualizados
          return updateDoc(okrRef, { krs: okrData.krs });
        } else {
          // O documento OKR não existe, não é possível adicionar um KR separadamente
          console.error("O documento OKR não existe. Não é possível adicionar um KR separadamente.");
          return null;
        }
      })
      .then(() => {
        dispatch({ type: "date", payload: dateToStart });
        dispatch({ type: "value", payload: 0 });
        dispatch({ type: "obs", payload: "" });
        setMessageDate(false);
        setUpdateState(!update);
      })
      .catch((error) => {
        console.error("Erro ao atualizar o documento OKR:", error);
      });

    setOpenModal(false);
  }


  const handleCancelReplace = () => {
    setOpenModal(false);
  };


  return (
    <div className={style.container}>

      <Dialog open={openModal} onClose={handleCancelReplace}>
        <DialogTitle>Data de checkpoint já cadastrada</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Já existe um checkpoint cadastrado para a data selecionada. Deseja substituí-lo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={addCheckpoint} color="primary">
            Substituir
          </Button>
        </DialogActions>
      </Dialog>

      <div className={style.form}>


        {/* value */}

        <div className={style.label}>

          <TextField
            label="Novo Valor"
            value={valueError === false ? formatNumber(state.value) : ""}
            error={valueError}
            onChange={handleChange}
            onBlur={valueOnBlur}
            sx={{
              width: '100%',
              '& label': {
                minWidth: 200,
                color: '#fff', // cor do texto do label
              },
              '& input': {
                color: '#fff', // cor do texto digitado
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#fff', // cor da borda
                },
                '&:hover fieldset': {
                  borderColor: '#fff', // cor da borda quando o mouse passa por cima
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#fff', // cor da borda quando o input está focado
                },
              },
              '&:hover:not(:focus-within) label': {
                color: 'white',
              },
              '&:focus-within label': {
                color: '#24FF8F',
              }
            }}
            fullWidth
          />
        </div>

        {/* date */}

        <div className={style.label}>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Data"
              format="DD/MM/YYYY"
              value={dayjs(state.date)}
              onChange={(e: any) => {
                changeDate(e);
                validateDate(e)
              }
              }
              minDate={dayjs(props.minDate)}
              maxDate={dayjs(props.maxDate)}
              sx={{
                width: '100%',
                '& label': {
                  minWidth: 200,
                  color: '#fff', // cor do texto do label
                },
                '& input': {
                  color: '#fff', // cor do texto digitado
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#fff', // cor da borda
                  },
                  '&:hover fieldset': {
                    borderColor: '#fff', // cor da borda quando o mouse passa por cima
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#fff', // cor da borda quando o input está focado
                  },
                },
                '&:hover:not(:focus-within) label': {
                  color: 'white',
                },
                '&:focus-within label': {
                  color: '#24FF8F',
                }
              }}
            />
          </LocalizationProvider>


        </div>
      </div>

      {messageDate ?

        <div className={style.error}>
          <FaTimesCircle /> A data do checkpoint deve estar no prazo do Resultado Chave.
        </div> : ""}



      {/* message */}

      <div className={style.labelB}>

        <TextField
          label="Observação:"
          value={state.obs}
          onChange={(e: any) => dispatch({ type: "obs", payload: e.target.value })}
          sx={{
            width: '100%',
            '& label': {
              minWidth: 200,
              color: '#fff', // cor do texto do label
            },
            '& input': {
              color: '#fff', // cor do texto digitado
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#fff', // cor da borda
              },
              '&:hover fieldset': {
                borderColor: '#fff', // cor da borda quando o mouse passa por cima
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff', // cor da borda quando o input está focado
              },
            },
            '&:hover:not(:focus-within) label': {
              color: 'white',
            },
            '&:focus-within label': {
              color: '#24FF8F',
            }
          }}
          fullWidth
        />
      </div>

      <div className={style.info}>
        <FaInfoCircle /> O prazo do Resultado Chave: {firstDate} até {lastDate}.
      </div>

      {/* save */}

      <ButtonSystem action={(e: any) => { verifyCheckpoint() }} status={!messageDate} text="Adicionar Resultado" to="checkpoint" icon="checkpoint" />

    </div>
  );
}