import stylePages from "styles/pages.module.scss";
import { useRecoilValue } from "recoil";
import { meetingsState } from "state/atom";
import { useNavigate } from "react-router-dom";
import style from "./allMeetings.module.scss";
import { FaCalendarAlt, FaPlusCircle } from "react-icons/fa";
import classNames from "classnames";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";


export default function Meetings() {
  const listOfMeetings = useRecoilValue(meetingsState);

  const [open, setOpen] = useState(false);

  const sortMeetings = [...listOfMeetings];

  sortMeetings.sort((a, b) => {
    // Converta as datas para objetos Date para que possam ser comparadas
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    // Compare as datas usando getTime()
    return dateB.getTime() - dateA.getTime();
  });

  const totalMeetings = listOfMeetings.length;

  const emptyMeetings = 3 - totalMeetings;

  const navigate = useNavigate();

  const addMeeting = () => {

    // check se tem alguma reunião aberta

    const openMeetings = listOfMeetings.filter((meeting: any) => meeting.status === "open");

    if (openMeetings.length > 0) {
      setOpen(true);
    } else {
      navigate("/reunioes/adicionar");
    }
  }

  // ACTIONS BUTTONS 
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <section className={stylePages.container__section}>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Você já tem uma reunião em andamento!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Antes de abrir uma nova reunião é necessário salvar ou deletar a reunião em andamento. Apenas administradores podem deletar ou salvar reuniões.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Entendi</Button>
        </DialogActions>
      </Dialog>

      <div className={stylePages.header}>

        <div className={stylePages.header__container}>
          <div className={stylePages.header__title}>
            Reuniões Estratégicas
          </div>
        </div>

        {/* <div className={stylePages.header__actions}>
          <ButtonSystem action={(e: any) => addMeeting(e)} status={true} text="Comparar Reuniões" to="next" icon="add" />
        </div> */}
      </div>

      <div className={style.container}>

        <div className={classNames({
          [style.meeting]: true,
          [style.meeting__add]: true,
        })} onClick={(e: any) => addMeeting()}>
          <FaPlusCircle className={classNames({
            [style.meeting__icon]: true,
            [style.color__branco]: true,
          })} />
          <div className={classNames({
            [style.meeting__title]: true,
            [style.color__branco]: true,
          })}>
            Nova Reunião
          </div>
        </div>

        {listOfMeetings.length > 0 ?
          sortMeetings.map((meet: any) =>
            <div onClick={(e: any) => navigate(`/reunioes/detalhes/${meet.date}`)} className={classNames({
              [style.meeting]: true,
              [style.meeting__open]: meet.status === "open",
              [style.meeting__closed]: meet.status === "closed",
            })} >
              <FaCalendarAlt className={classNames({
                [style.meeting__icon]: true,
                [style.color__azulmarinho]: meet.status === "open",
                [style.color__roxo]: meet.status === "closed",
              })} />
              <div className={classNames({
                [style.meeting__title]: true,
                [style.color__azulmarinho]: true,
              })}>
                {meet.date}
              </div>
              {meet.status === "open" ? "Reunião em andamento" : "Reunião concluída!"}
            </div>

          ) : ""}


        {emptyMeetings > 0 ?
          Array.from({ length: emptyMeetings }).map((_, index) => <div key={index} className={classNames({
            [style.meeting]: true,
            [style.meeting__none]: true,
          })} >
          </div>)
          : ""}




      </div>

    </section>
  )
}