import style from "./teamObjectives.module.scss";
import stylePages from "styles/pages.module.scss";
import { useRecoilValue } from "recoil";
import { okrsState, userDataState } from "state/atom";
import SelectArea from "components/teams/selectArea";
import { useEffect, useState } from "react";
import KrSingle from "components/kr/krSingle";
import React from "react";
import OkrName from "components/okr/okrName";

export default function TeamObjectives() {
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const okrs = useRecoilValue(okrsState);
  const userData = useRecoilValue(userDataState);

  useEffect(() => {
    if (userData) {
      setSelectedTeams(userData.teams);
    }
  }, [userData]);

  const filteredKrs = okrs.reduce((acc: any, okr: any) => {
    const krs = okr.krs.filter((kr: any) => {
      const teamMatch = kr.teams.some((team: any) => selectedTeams.includes(team));
      return teamMatch;
    }).map((kr: any) => ({ ...kr, okrId: okr.id, okrName: okr.okrName }));

    return [...acc, ...krs];
  }, []);

  const objectives = filteredKrs.reduce((acc: any, kr: any) => {
    const existingOkr = acc.find((o: any) => o.id === kr.okrId);
    if (existingOkr) {
      existingOkr.krs.push(kr);
    } else {
      acc.push({
        id: kr.okrId,
        name: kr.okrName,
        krs: [kr],
      });
    }
    return acc;
  }, []);


  return (
    <section className={stylePages.container__section}>
      <div className={stylePages.header}>

        <div className={stylePages.header__container}>
          <div className={stylePages.header__title}>
            Resultados por time
          </div>
        </div>

        <div className={stylePages.header__actions}>

        </div>
      </div>

      <div className={style.filterTeams}>
        <SelectArea label="Selecione os times para ver os resultados esperados" value={selectedTeams} handleArea={setSelectedTeams} />
      </div>

      <hr className={stylePages.separator} />

      {filteredKrs.length === 0 ? (
        <div className={stylePages.noData}>Os times selecionados ainda não tem objetivos cadastrados.</div>
      ) : (
        objectives.map((o: any) => (
          <React.Fragment key={o.id}>
            {o.krs.length > 0 && <OkrName okrName={o.krs[0].okrName} />}
            {o.krs.map((kr: any, index: any) => (
              <KrSingle key={index} data={kr} okr={kr.okrId} />
            ))}
            <hr className={stylePages.separator} />
          </React.Fragment>
        ))
      )}
    </section>
  );
}
