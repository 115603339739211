import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField } from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';
import { FaPlus, FaKey } from 'react-icons/fa';
import styleTask from "./addTask.module.scss";
import { TbTarget } from 'react-icons/tb';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { dateToEnd, dateToStart } from 'components/date';
import style from "../../../styles/components.module.scss";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ValidateDate } from 'components/date/validate';
import SelectUsers from 'components/users/select';
import styleComponent from "../../../styles/components.module.scss";
import ButtonSystem from 'components/forms/button';
import { useNavigate } from 'react-router-dom';
import { businessIdState, okrsState, updateState } from 'state/atom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 as uuidv4 } from "uuid";
import { db } from 'config/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

function validateFields(task:any, okrId:any, krId:any) {
  return task.trim() !== '' && okrId !== '' && krId !== '';
}

interface INewTask {
  okr: any,
  kr: any,
  taskId: any,
  data: any
}

const initialFilters = {
  id: "",
  task: "",
  initialDate: dateToStart,
  finalDate: dateToStart,
  description: "",
  position: 0,
  status: "open",
  responsible: [],
  categories: [],
  comments: [],
  okrId: "",
  krId: ""
};


const filtersState = (state: any, action: any) => {
  switch (action.type) {

    case "id":
      return { ...state, id: action.payload };

    case "task":
      return { ...state, task: action.payload };

    case "initialDate":
      return { ...state, initialDate: action.payload };

    case "finalDate":
      return { ...state, finalDate: action.payload };

    case "description":
      return { ...state, description: action.payload };

    case "position":
      return { ...state, position: action.payload };

    case "responsible":
      return { ...state, responsible: action.payload };

    case "status":
      return { ...state, status: action.payload };

    case "categories":
      return { ...state, categories: action.payload };

    case "comments":
      return { ...state, comments: action.payload };

    case "okrId":
      return { ...state, okrId: action.payload };

    case "krId":
      return { ...state, krId: action.payload };

    case "SET_MULTIPLE":
      return { ...state, ...action.payload }

    case "reset":
      return action.payload;

    default:
      throw new Error();

  }
}


export default function AddTask(props: INewTask) {

  // DATA TO SAVE
  const [state, dispatch] = useReducer(filtersState, initialFilters);
  const businessId = useRecoilValue(businessIdState);
  const [messageDate, setMessageDate] = useState(false);
  const update = useRecoilValue(updateState);
  const [updateUsers, setUpdateUsers] = useState(false);
  const okrsData = useRecoilValue(okrsState);
  const [newTask, setNewTask] = useState(false);
  const setUpdateState = useSetRecoilState(updateState);

  const [okrsList, setOkrsList] = useState<{ id: any; name: any; }[]>([]);
  const [krsList, setKrsList] = useState<{ id: any; name: any; }[]>([]);

  const [okrName, setOkrName] = useState();
  const [krName, setKrName] = useState();

  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);


  const navigate = useNavigate();

  // SEND INFO TO PARENT
  useEffect(() => {
    props.data(state.task);
  }, [state]);


  useEffect(() => {

    

    // NEW TASK
    if (props.taskId === "newtask") {

      if(props.okr !== "all" && props.kr !== "all"){
        dispatch({ type: "okrId", payload: props.okr });
        dispatch({ type: "krId", payload: props.kr });
      }

      


      setNewTask(true);
      const newId = uuidv4();
      dispatch({ type: "id", payload: newId });

      // EDITING A TASK
    } else {
      setNewTask(false);

      const okrFiltered = okrsData.find((okrData) => okrData.id === props.okr);

      setOkrName(okrFiltered?.okrName);

      if (okrFiltered) {
        const krFiltered = okrFiltered.krs.find((krData) => krData.id === props.kr);

        setKrName(krFiltered?.kr);

        if (krFiltered) {
          const taskFiltered = krFiltered.tasks.find((taskData) => taskData.id === props.taskId);

          if (taskFiltered) {
            dispatch({ type: "SET_MULTIPLE", payload: { "id": taskFiltered.id, "task": taskFiltered.task, "description": taskFiltered.description, "position": taskFiltered.position, "status": taskFiltered.status, "responsible": taskFiltered.responsible, "initialDate": taskFiltered.initialDate, "finalDate": taskFiltered.finalDate, "categories": taskFiltered.categories, "comments": taskFiltered.comments, "okrId": taskFiltered.okrId, "krId": taskFiltered.krId, } });
            setUpdateUsers(!updateUsers);
          }
        }
      }
    }
  }, []);


  useEffect(() => {
    const firstListOkrs = okrsData;
    const okrsGetting = firstListOkrs.map((okr) => ({ id: okr.id, name: okr.okrName }));
    setOkrsList(okrsGetting);
  }, []);

  useEffect(() => {

    const newListOkrs = okrsData;
    const okrFilter = newListOkrs.find((okrItem) => okrItem.id === state.okrId);
    if (okrFilter) {
      const krsGetting = okrFilter.krs.map((singlekr) => ({ id: singlekr.id, name: singlekr.kr }));
      setKrsList(krsGetting);
    }
  }, [state.okrId]);

  // CHANGE FINAL DATE

  const changeFinalDate = (e: any) => {
    const date = dayjs(e);
    const formattedDate = date.format("YYYY-MM-DD");
    dispatch({ type: "finalDate", payload: formattedDate })
  }

  // VALIDATE FINAL DATE
  const validateFinalDate = (e: any) => {
    const date = dayjs(e, 'DD/MM/YYYY');
    const formattedDate = date.format("YYYY-MM-DD");
    const validate = ValidateDate(formattedDate);
    if (validate === true) {
      setMessageDate(false);
    } else {
      setMessageDate(true);
    }
  }

  useEffect(() => {
    const { task, okrId, krId, finalDate } = state;
    const isValid = validateFields(task, okrId, krId);
    setIsSaveButtonEnabled(isValid);
  }, [state]);

  // SUBMIT
  const submitTask = () => {

    if (businessId && state.okrId && state.krId && state.id) {

      // Define a referência para o documento de OKR
      const okrRef = doc(db, "companies", businessId, "okrs", state.okrId);

      // Verifica se o documento OKR existe
      getDoc(okrRef).then((okrDocSnapshot) => {
        if (okrDocSnapshot.exists()) {
          // O documento OKR existe

          // Obtém os dados do documento OKR
          const okrData = okrDocSnapshot.data();

          // Verifica se a lista de KRs já existe no documento OKR
          if (okrData.krs) {
            // Procura o KR pelo ID
            const existingKrIndex = okrData.krs.findIndex((itemkr: any) => itemkr.id === state.krId);

            if (existingKrIndex !== -1) {
              // Se o KR existir, obtenha a lista de Tasks
              const krTasks = okrData.krs[existingKrIndex].tasks || [];

              // Verifica se já existe uma tarefa com o mesmo ID
              const existingTaskIndex = krTasks.findIndex((task: any) => task.id === state.id);

              if (existingTaskIndex !== -1) {
                // Se uma tarefa com o mesmo ID existir, substitua-a pela nova tarefa
                krTasks[existingTaskIndex] = state;
              } else {
                // Caso contrário, adicione a nova tarefa à lista
                krTasks.push(state);
              }

              // Atualize a lista de Tasks do KR
              okrData.krs[existingKrIndex].tasks = krTasks;
            }
          }

          // Atualiza o documento OKR com os KRs atualizados ou adicionados
          updateDoc(okrRef, okrData).then(() => {
            dispatch({ type: "reset", payload: initialFilters });
            setNewTask(true);
            const newId2 = uuidv4();
            dispatch({ type: "id", payload: newId2 });
            setUpdateState(!update);
          }).catch((error) => {
            console.error("Erro ao atualizar o documento OKR:", error);
          });
        } else {
          // O documento OKR não existe, não é possível adicionar um KR separadamente
          console.error("O documento OKR não existe. Não é possível adicionar um KR separadamente.");
        }
      }).catch((error) => {
        console.error("Erro ao verificar a existência do documento OKR:", error);
      });
    }
  };


  // Remove uma tarefa (task)

  const removeTask = () => {

    if (businessId && state.okrId && state.krId && state.id) {

      // Define a referência para o documento de OKR
      const okrRef = doc(db, "companies", businessId, "okrs", state.okrId);

      // Verifica se o documento OKR existe
      getDoc(okrRef)
        .then((okrDocSnapshot) => {
          if (okrDocSnapshot.exists()) {
            // O documento OKR existe

            // Obtém os dados do documento OKR
            const okrData = okrDocSnapshot.data();

            // Verifica se a lista de KRs já existe no documento OKR
            if (okrData.krs) {
              // Procura o KR pelo ID
              const existingKrIndex = okrData.krs.findIndex(
                (itemkr: any) => itemkr.id === state.krId
              );

              if (existingKrIndex !== -1) {
                // Se o KR existir, obtenha a lista de Tasks
                const krTasks =
                  okrData.krs[existingKrIndex].tasks || [];

                // Encontre a tarefa a ser removida pelo ID
                const taskIndexToRemove = krTasks.findIndex(
                  (task: any) => task.id === state.id
                );

                if (taskIndexToRemove !== -1) {
                  // Remova a tarefa da lista
                  krTasks.splice(taskIndexToRemove, 1);

                  // Atualize a lista de Tasks do KR
                  okrData.krs[existingKrIndex].tasks = krTasks;
                }
              }
            }

            // Atualiza o documento OKR com os KRs atualizados (com a tarefa removida)
            updateDoc(okrRef, okrData)
              .then(() => {
                dispatch({ type: "reset", payload: initialFilters });
                setUpdateState(!update);
                navigate(-1);
              })
              .catch((error) => {
                console.error("Erro ao atualizar o documento OKR:", error);
              });
          } else {
            // O documento OKR não existe, não é possível remover uma tarefa
            console.error(
              "O documento OKR não existe. Não é possível remover uma tarefa."
            );
          }
        })
        .catch((error) => {
          console.error("Erro ao verificar a existência do documento OKR:", error);
        });
    }
  };

  const changeOkr = (e:any) => {
    dispatch({ type: "okrId", payload: e.target.value })
    dispatch({ type: "krId", payload: "" })
  }




  return (

    <section className={style.section}>


      {newTask === false ? <div className={style.div__input}>
        <div className={styleTask.title__okr}>
          <TbTarget className={styleTask.icon} /> {okrName}
        </div>
        <div className={styleTask.title__kr}>
          <FaKey className={styleTask.icon} /> {krName}
        </div>

      </div> : ""}


      {/* TASK */}

      <div className={style.div__input}>
        <TextField
          label="Tarefa"
          // error={Boolean(true)}
          // helperText={true ? "Digite uma tarefa." : ""}
          value={state.task}
          onChange={(e: any) => dispatch({ type: "task", payload: e.target.value })}
          sx={{
            width: '100%', '& label': {
              minWidth: 200,
            },
          }}
          fullWidth
        />
      </div>

      {newTask === false ? "" : <>

        <div className={style.div__input}>
          <div className={style.horizontal}>

            {/* OKR */}

            <div className={style.col50}>
              <TextField
                id="okr"
                select
                label="Objetivo"
                value={state.okrId}
                onChange={(e:any) => changeOkr(e)}
                sx={{
                  width: '100%', '& label': {
                    minWidth: 200,
                  },
                }}
              >
                {okrsList.map((okr: any) => <MenuItem value={okr.id}>{okr.name}</MenuItem>)}
              </TextField>
            </div>

            <div className={style.col50}>
              <TextField
                id="kr"
                select
                label="Resultado-chave"
                value={state.krId}
                onChange={(e: any) => dispatch({ type: "krId", payload: e.target.value })}
                sx={{
                  width: '100%', '& label': {
                    minWidth: 200,
                  },
                }}
              >
                {krsList.map((kr: any) => <MenuItem value={kr.id}>{kr.name}</MenuItem>)}
              </TextField>

            </div>

          </div>
        </div> </>}

      <div className={style.div__input}>
        <div className={style.horizontal}>

          {/* STATUS */}

          <div className={style.col50}>
            <TextField
              id="status"
              select
              label="Status"
              value={state.status}
              onChange={(e: any) => dispatch({ type: "status", payload: e.target.value })}
              sx={{
                width: '100%', '& label': {
                  minWidth: 200,
                },
              }}
            >
              <MenuItem value="open">A Fazer</MenuItem>
              <MenuItem value="progress">Em Progresso</MenuItem>
              <MenuItem value="done">Concluído</MenuItem>
              <MenuItem value="archive">Arquivar</MenuItem>
            </TextField>
          </div>

          {/* PRAZO */}

          <div className={style.col50}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Prazo"
                format="DD/MM/YYYY"
                value={dayjs(state.finalDate)}
                onChange={(e: any) => {
                  changeFinalDate(e);
                  validateFinalDate(e)
                }
                }
                sx={{
                  width: '100%', '& label': {
                    minWidth: 200,
                  },
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>

      <div className={style.div__input}>

        <TextField
          id="description"
          label="Observações"
          multiline
          rows={Math.max(state.description.split('\n').length, 3)} // Calcula o número de linhas com base no conteúdo
          variant="outlined"
          fullWidth
          value={state.description}
          onChange={(e: any) => dispatch({ type: "description", payload: e.target.value })}
          sx={{
            width: '100%', '& label': {
              minWidth: 200,
            },
          }}
        />

      </div>

      <div className={style.div__input}>

        {updateUsers ? <SelectUsers label="Selecione os usuários responsáveis" users={state.responsible} handleUsers={(e: any) => dispatch({ type: "responsible", payload: e })} /> : <SelectUsers label="Selecione os usuários responsáveis" users={state.responsible} handleUsers={(e: any) => dispatch({ type: "responsible", payload: e })} />}

      </div>



      <div className={styleComponent.addControls}>

        <ButtonSystem action={() => navigate(-1)} status={true} text="Voltar" to="back" icon="back" />

        {newTask === false ? <ButtonSystem action={() => removeTask()} status={true} text="Deletar Tarefa" to="remove" icon="remove" /> : ""}

        <ButtonSystem action={() => submitTask()} status={isSaveButtonEnabled && !messageDate} text="Salvar e Adicionar Outra Tarefa" to="next" icon="add" />

        <ButtonSystem action={() => { submitTask(); navigate(-1) }} status={isSaveButtonEnabled && !messageDate} text="Salvar" to="finish" icon="finish" />

      </div>

    </section >
  )
}