import { useState } from "react";
import { Link } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import style from '../login/Login.module.scss';
import InfoLogin from "pages/system/info";
import ButtonSystem from "components/forms/button";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  return (
    <section className={style.section}>
      <div className={style.info}>
        <InfoLogin />
      </div>
      <div className={style.firebase}>
        <div className={style.loginArea}>
          <div className={style.loginTitle}>Esqueceu sua senha?</div>
          <form className={style.form}>
            <div className={style.label}>E-mail:</div>
            <input
              className={style.field}
              type="email"
              placeholder="Insira seu e-mail"
              value={email}
              onChange={handleEmailChange}
            />
            {error && <span className={style.errorMessage}>E-mail inválido. Verifique com nosso suporte.</span>}
            {success && <span className={style.successMessage}>E-mail enviado com sucesso!</span>}
            <div className={style.divButton}>
              <ButtonSystem action={handleSubmit} status={email !== ''} text="Enviar" to="next" icon="finish" />
            </div>
          </form>
          <div className={style.forgotPassword}>
            <Link to="/" className={style.link}>
              Voltar para a página de login
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}



export default ForgotPassword;