import style from "../../../styles/components.module.scss";
import { useEffect, useReducer, useState } from "react";
import styleComponent from "../../../styles/components.module.scss";
import { HiLightBulb } from "react-icons/hi";
import SelectArea from "../../teams/selectArea";
import ButtonSystem from "components/forms/button";
import krsList from "../../../data/krs.json";
import { dateToEnd, dateToStart } from "components/date";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { businessIdState, okrsState, updateState } from "state/atom";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import SelectUsers from "components/users/select";
import { ValidateDate } from "components/date/validate";
import TextField from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { Alert, MenuItem } from "@mui/material";
import { formatNumber, removeFormat } from "components/date/formatNumber";
import { v4 as uuidv4 } from "uuid";
import { FaInfoCircle } from "react-icons/fa";


const initialFilters = {
  id: "",
  kr: "",
  dataType: "%",
  resultType: "Conquista",
  initialValue: 0,
  finalValue: 100,
  responsible: [],
  initialDate: dateToStart,
  finalDate: dateToEnd,
  teams: []
};

const filtersStatesKr = (state: any, action: any) => {
  switch (action.type) {

    case "id":
      return { ...state, id: action.payload };

    case "kr":
      return { ...state, kr: action.payload };

    case "dataType":
      return { ...state, dataType: action.payload };

    case "resultType":
      return { ...state, resultType: action.payload };

    case "initialValue":
      return { ...state, initialValue: action.payload };

    case "finalValue":
      return { ...state, finalValue: action.payload };

    case "responsible":
      return { ...state, responsible: action.payload };

    case "initialDate":
      return { ...state, initialDate: action.payload };

    case "finalDate":
      return { ...state, finalDate: action.payload };

    case "teams":
      return { ...state, teams: action.payload };

    case "SET_MULTIPLE":
      return { ...state, ...action.payload }

    case "reset":
      return action.payload;

    default:
      throw new Error();

  }
}


export default function NewKeyResult(props: any) {

  // DATA TO SAVE

  const [stateKr, dispatchKr] = useReducer(filtersStatesKr, initialFilters);

  // MESSAGES

  const [messageText, setMessageText] = useState(false);
  const [messageDate, setMessageDate] = useState(false);
  const [messageDate2, setMessageDate2] = useState(false);
  const [messageValue, setMessageValue] = useState(false);
  const [messageValueText, setMessageValueText] = useState("");

  const [valueFinalError, setValueFinalError] = useState(false);
  const [valueInitialError, setValueInitialError] = useState(false);
  const [buttonNext, setButtonNext] = useState(false);
  const [random, setRandom] = useState(1);
  const [newKr, setNewKr] = useState(false);
  const [diferenceDates, setDiferenceDates] = useState(true);
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  // GET INFO

  const krs = krsList;
  const businessId = useRecoilValue(businessIdState);
  const okrsData = useRecoilValue(okrsState);
  const { okr } = useParams();
  const { kr } = useParams();
  const update = useRecoilValue(updateState);
  const setUpdateState = useSetRecoilState(updateState);
  const [updateUsers, setUpdateUsers] = useState(false);

  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);

  console.log("datas", stateKr.initialDate, stateKr.finalDate);
  console.log("datas min max", minDate, maxDate);


  // NEW KR OR EDIT KR

  useEffect(() => {

    if (kr === "newkr") {
      setNewKr(true);
      const newId = uuidv4() + '_' + Date.now();
      dispatchKr({ type: "id", payload: newId });

    } else {
      setNewKr(false);

    }
  }, []);

  // WHEN UPDATE OKRS DATA

  useEffect(() => {

    const okrData = okrsData.find((okrData) => okrData.id === okr);

    if (okrData && kr === "newkr") {
      setMinDate(okrData.initialDate);

      // calcular se hoje é maior que a data inicial e menor que a data final
      const today = dayjs();
      const initialDate = dayjs(okrData.initialDate);
      const finalDate = dayjs(okrData.finalDate);

      if (today >= initialDate && today <= finalDate) {
        dispatchKr({ type: "initialDate", payload: today.format("YYYY-MM-DD") });
      } else {
        dispatchKr({ type: "initialDate", payload: okrData.finalDate });
      }

      setMaxDate(okrData.finalDate);
      dispatchKr({ type: "finalDate", payload: okrData.finalDate });
    } else {

      if (okrData) {
        const krData = okrData.krs.find((krData) => krData.id === kr);
        if (krData) {
          dispatchKr({ type: "SET_MULTIPLE", payload: { "id": krData.id, "kr": krData.kr, "dataType": krData.dataType, "initialValue": krData.initialValue, "finalValue": krData.finalValue, "responsible": krData.responsible, "initialDate": krData.initialDate, "finalDate": krData.finalDate, "teams": krData.teams, "resultType": krData.resultType } });
          setMinDate(okrData.initialDate);
          setMaxDate(okrData.finalDate);
          setUpdateUsers(!updateUsers);
        }
      }
    }

  }, [okrsData]);



  // UPDATE PARENT
  useEffect(() => {
    props.data(stateKr.kr);
  }, [stateKr]);


  // STEPS
  useEffect(() => {
    props.currentStep(currentStep);
  }, [currentStep]);


  // KR MESSAGE
  useEffect(() => {
    if ((stateKr.kr === "" || stateKr.kr === undefined) && !messageText) {
      setMessageText(true);
    } else if (stateKr.kr !== "" && stateKr.kr !== undefined && messageText) {
      setMessageText(false);
    }
  }, [stateKr.kr]);


  // SUBMIT

  const submit = () => {
    if (businessId && okr && stateKr) {
      // Define a referência para o documento de OKR
      const okrRef = doc(db, "companies", businessId, "okrs", okr);

      // Verifica se o documento OKR existe
      getDoc(okrRef).then((okrDocSnapshot) => {
        if (okrDocSnapshot.exists()) {
          // O documento OKR existe

          // Obtém os dados do documento OKR
          const okrData = okrDocSnapshot.data();

          // Verifica se a lista de KRs já existe no documento OKR
          if (okrData.krs) {
            // Procura o KR pelo ID
            const existingKrIndex = okrData.krs.findIndex((kr: any) => kr.id === stateKr.id);

            if (existingKrIndex !== -1) {
              // Se o KR existir, atualiza apenas as informações relevantes
              const updatedKr = { ...okrData.krs[existingKrIndex], ...stateKr };
              okrData.krs[existingKrIndex] = updatedKr;
            } else {
              // Se o KR não existir, adiciona-o à lista de KRs
              okrData.krs.push({ ...stateKr });
            }
          } else {
            // Se a lista de KRs não existir, cria-a com o novo KR
            okrData.krs = [{ ...stateKr }];
          }

          // Atualiza o documento OKR com os KRs atualizados ou adicionados
          updateDoc(okrRef, okrData).then(() => {
            dispatchKr({ type: "reset", payload: initialFilters });
            const newId2 = uuidv4() + '_' + Date.now();
            dispatchKr({ type: "id", payload: newId2 });
            setCurrentStep(1);
            setUpdateState(!update);
          }).catch((error) => {
            console.error("Erro ao atualizar o documento OKR:", error);
          });
        } else {
          // O documento OKR não existe, não é possível adicionar um KR separadamente
          console.error("O documento OKR não existe. Não é possível adicionar um KR separadamente.");
        }
      }).catch((error) => {
        console.error("Erro ao verificar a existência do documento OKR:", error);
      });
    }
  };

  const randomIdea = (e: any) => {
    e.preventDefault();
    const randomMin = Math.ceil(1);
    const randomMax = Math.floor(krs.length);
    const first = Math.floor(Math.random() * (randomMax - randomMin + 1)) + randomMin;

    const randomNumber = first - 1;

    if (randomNumber !== random) {

      const randomFinalValue = Number(krs[randomNumber].finalvalue);

      dispatchKr({ type: "SET_MULTIPLE", payload: { "kr": krs[randomNumber].kr, "dataType": krs[randomNumber].datatype, "resultType": krs[randomNumber].resulttype, "finalValue": randomFinalValue, "initialValue": krs[randomNumber].initialvalue } })
      setRandom(randomNumber);

    } else if (randomNumber === random && randomNumber === 0) {

      const randomFinalValue = Number(krs[randomNumber + 1].finalvalue);

      dispatchKr({ type: "SET_MULTIPLE", payload: { "kr": krs[randomNumber + 1].kr, "dataType": krs[randomNumber + 1].datatype, "resultType": krs[randomNumber + 1].resulttype, "finalValue": randomFinalValue, "initialValue": krs[randomNumber + 1].initialvalue } })
      setRandom(randomNumber + 1);

    } else if (randomNumber === random && randomNumber !== 0) {

      const randomFinalValue = Number(krs[randomNumber - 1].finalvalue);

      dispatchKr({ type: "SET_MULTIPLE", payload: { "kr": krs[randomNumber - 1].kr, "resultType": krs[randomNumber - 1].resulttype, "dataType": krs[randomNumber - 1].datatype, "finalValue": randomFinalValue, "initialValue": krs[randomNumber - 1].initialvalue } })
      setRandom(randomNumber - 1);
    }
  }

  // CHANGE INITIAL DATE

  const changeInitialDate = (e: any) => {
    const date = dayjs(e);
    const formattedDate = date.format("YYYY-MM-DD");
    dispatchKr({ type: "initialDate", payload: formattedDate })
  }

  // CHANGE FINAL DATE

  const changeFinalDate = (e: any) => {
    const date = dayjs(e);
    const formattedDate = date.format("YYYY-MM-DD");
    dispatchKr({ type: "finalDate", payload: formattedDate })
  }

  // VALIDATE INITIAL DATE

  const validateInitialDate = (e: any) => {
    const date = dayjs(e, 'DD/MM/YYYY');
    const formattedDate = date.format("YYYY-MM-DD");
    const validate = ValidateDate(formattedDate);
    if (validate === true) {
      setMessageDate(false);
    } else {
      setMessageDate(true);
    }
    if (formattedDate < minDate) {
      setMessageDate(true);
    }
  }

  // VALIDATE FINAL DATE
  const validateFinalDate = (e: any) => {
    const date = dayjs(e, 'DD/MM/YYYY');
    const formattedDate = date.format("YYYY-MM-DD");
    const validate = ValidateDate(formattedDate);
    if (validate === true) {
      setMessageDate2(false);
    } else {
      setMessageDate2(true);
    }
    if (formattedDate > maxDate) {
      setMessageDate2(true);
    }
  }

  // VALIDATE DATE DIFFERENCE

  useEffect(() => {
    const previousDate = new Date(stateKr.initialDate).getTime();
    const postDate = new Date(stateKr.finalDate).getTime();
    if (previousDate < postDate && diferenceDates !== true) {
      setDiferenceDates(true)
    } else if (previousDate >= postDate && diferenceDates !== false) {
      setDiferenceDates(false)
    }
  }, [stateKr.initialDate, stateKr.finalDate]);

  // VALIDATE INITIAL VALUE

  const validateInitialValue = (e: any) => {

    if (e === "" || e === undefined || e === null) {
      setValueInitialError(true);
    } else {
      if (valueInitialError === true) {
        setValueInitialError(false);
      }
      const value = removeFormat(e);
      if (value) {
        dispatchKr({ type: "initialValue", payload: value })
      }
    }

  }

  const onBlurInitialValue = (e: any) => {
    const value = removeFormat(e);
    const toNumber = Number(value);
    if (value) {
      dispatchKr({ type: "initialValue", payload: toNumber })
    }
  }

  // VALIDATE FINAL VALUE

  const validateFinalValue = (e: any) => {

    if (e === "" || e === undefined || e === null) {
      setValueFinalError(true);
    } else {
      if (valueFinalError === true) {
        setValueFinalError(false);
      }
      const value = removeFormat(e);
      if (value) {
        dispatchKr({ type: "finalValue", payload: value })
      }
      if (stateKr.resultType === "Manter") {
        dispatchKr({ type: "initialValue", payload: value })
      }
    }

  }

  const onBlurFinalValue = (e: any) => {
    const value = removeFormat(e);
    const toNumber = Number(value);
    if (value) {
      dispatchKr({ type: "finalValue", payload: toNumber })
    }
    if (stateKr.resultType === "Manter") {
      dispatchKr({ type: "initialValue", payload: value })
    }
  }

  // CHECK TYPE OF RESULT

  const checkTypeOfResult = (e: any) => {
    dispatchKr({ type: "resultType", payload: e })

    if (e === "Manter") {
      dispatchKr({ type: "initialValue", payload: stateKr.finalValue })
    }
  }

  // VALIDATE INITIAL VALUE AND FINAL VALUE

  useEffect(() => {
    if (stateKr.resultType === "Redução" && stateKr.initialValue <= stateKr.finalValue) {
      setMessageValue(true);
      setMessageValueText("Digite um valor inicial maior do que a meta.");
    } else if (stateKr.resultType === "Conquista" && stateKr.initialValue >= stateKr.finalValue) {
      setMessageValue(true);
      setMessageValueText("Digite um valor inicial menor do que a meta.");
    } else if (messageValue !== false) {
      setMessageValue(false);
      setMessageValueText("");
    }
  }, [stateKr.initialValue, stateKr.finalValue, stateKr.resultType]);


  // BUTTON NEXT

  useEffect(() => {
    if (messageText === false
      && messageDate === false
      && messageDate2 === false
      && messageValue === false
      && diferenceDates === true
      && stateKr.teams.length > 0
      && valueFinalError === false
      && valueInitialError === false
    ) {
      setButtonNext(true);
    } else if (buttonNext === true) {
      setButtonNext(false);
    }
  }, [stateKr]);





  return (
    <section className={style.section}>

      {currentStep === 1 ? (<> <button type="button" className={style.button} onClick={randomIdea}>
        <HiLightBulb className={style.item__button} />ideia
      </button> </>) : (<></>)}

      {/* <div className={style.title}>
        <TbTarget className={style.item} /> Resultado-chave
      </div>
      <div className={style.subtitle}>
        Descreva um resultado-chave relevante ao objetivo. (Quantitativo)
      </div> */}

      {currentStep === 1 ? (<>

        {/* KR - RESULTADO-CHAVE */}

        <div className={style.div__input}>
          <TextField
            label="KR - Resultado-chave"
            error={Boolean(messageText)}
            helperText={messageText ? "Digite um resultado-chave para chegar ao seu objetivo." : ""}
            value={stateKr.kr}
            onChange={(e: any) => dispatchKr({ type: "kr", payload: e.target.value })}
            sx={{
              width: '100%', '& label': {
                minWidth: 200,
              },
            }}
            fullWidth
          />
        </div>



        <div className={style.div__input}>
          <div className={style.horizontal}>

            {/* TYPE OF RESULT */}

            <div className={style.col50}>
              <TextField
                id="typeOfResult"
                select
                label="Tipo de Resultado"
                value={stateKr.resultType}
                onChange={(e: any) => checkTypeOfResult(e.target.value)}
                sx={{
                  width: '100%', '& label': {
                    minWidth: 200,
                  },
                }}
              >
                <MenuItem value="Conquista">Conquista</MenuItem>
                <MenuItem value="Redução">Redução</MenuItem>
                <MenuItem value="Manter">Manter</MenuItem>
              </TextField>
            </div>

            {/* MÉTRICA */}

            <div className={style.col50}>
              <TextField
                id="metrica"
                select
                label="Métrica"
                value={stateKr.dataType}
                onChange={(e: any) => dispatchKr({ type: "dataType", payload: e.target.value })}
                sx={{
                  width: '100%', '& label': {
                    minWidth: 200,
                  },
                }}
              >
                <MenuItem value="%">%</MenuItem>
                <MenuItem value="R$">R$</MenuItem>
                <MenuItem value="number">Número</MenuItem>
              </TextField>
            </div>
          </div>
        </div>


        {/* INITIAL VALUE */}

        <div className={style.div__input}>
          <div className={style.horizontal}>

            <div className={style.col50}>
              <TextField
                id="initialValue"
                label="Valor Inicial"
                disabled={stateKr.resultType === 'Manter' ? true : false}
                value={valueInitialError === false ? formatNumber(stateKr.initialValue) : ""}
                error={valueInitialError || messageValue}
                onChange={(e: any) => validateInitialValue(e.target.value)}
                onBlur={(e: any) => onBlurInitialValue(e.target.value)}
                sx={{
                  width: '100%', '& label': {
                    minWidth: 200,
                  },
                }}
              />
            </div>

            {/* FINAL VALUE */}

            <div className={style.col50}>
              <TextField
                id="meta"
                label="Meta"
                value={valueFinalError === false ? formatNumber(stateKr.finalValue) : ""}
                error={valueFinalError}
                onChange={(e: any) => validateFinalValue(e.target.value)}
                onBlur={(e: any) => onBlurFinalValue(e.target.value)}
                sx={{
                  width: '100%', '& label': {
                    minWidth: 200,
                  },
                }}
              />
            </div>
          </div>
        </div>

        <div className={style.div__input}>
          {messageValue ? <Alert severity="error" style={{ width: '95%' }}>{messageValueText}</Alert> : <></>}
        </div>
      </>) : (<></>)}

      {currentStep === 2 ? (<>

        <div className={style.div__input}>



          {newKr ? <SelectArea label="Selecione as áreas responsáveis" value={stateKr.teams} handleArea={(e: any) => dispatchKr({ type: "teams", payload: e })} /> : <SelectArea label="Selecione as áreas responsáveis" value={stateKr.teams} handleArea={(e: any) => dispatchKr({ type: "teams", payload: e })} />}

        </div>

        <div className={style.div__input}>

          {updateUsers ? <SelectUsers label="Selecione os usuários responsáveis" users={stateKr.responsible} handleUsers={(e: any) => dispatchKr({ type: "responsible", payload: e })} /> : <SelectUsers label="Selecione os usuários responsáveis" users={stateKr.responsible} handleUsers={(e: any) => dispatchKr({ type: "responsible", payload: e })} />}

        </div>

        <div className={style.div__input}>
          <div className={style.horizontal}>

            <div className={style.col50}>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Data Inicial"
                  format="DD/MM/YYYY"
                  value={dayjs(stateKr.initialDate)}
                  onChange={(e: any) => {
                    changeInitialDate(e);
                    validateInitialDate(e)
                  }
                  }
                  minDate={dayjs(minDate)}
                  maxDate={dayjs(maxDate)}
                  sx={{
                    width: '100%', '& label': {
                      minWidth: 200,
                    },
                  }}
                />
              </LocalizationProvider>

            </div>
            <div className={style.col50}>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Data Final"
                  format="DD/MM/YYYY"
                  value={dayjs(stateKr.finalDate)}
                  onChange={(e: any) => {
                    changeFinalDate(e);
                    validateFinalDate(e)
                  }
                  }
                  minDate={dayjs(minDate)}
                  maxDate={dayjs(maxDate)}
                  sx={{
                    width: '100%', '& label': {
                      minWidth: 200,
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
          <span className={style.message}>
            {diferenceDates === false ? <> - A data final precisa ser maior que a data inicial.</> : ""}
          </span>


        </div>
        <div className={style.deadline}>
          <FaInfoCircle /> O prazo do objetivo ao qual este resultado chave está vinculado é: <b>{dayjs(minDate).format("DD/MM/YYYY")}</b>  até  <b> {dayjs(maxDate).format("DD/MM/YYYY")} </b>.
        </div>

      </>) : (<></>)}

      <div className={styleComponent.addControls}>

        {currentStep > 1
          ? <ButtonSystem action={(e: any) => setCurrentStep(currentStep - 1)} status={currentStep > 1} text="Voltar" to="back" icon="back" /> : ""}

        {currentStep < 2
          ? <ButtonSystem action={(e: any) => setCurrentStep(currentStep + 1)} status={currentStep < 3 && messageText === false
            && messageValue === false
            && valueFinalError === false
            && valueInitialError === false} text="Avançar" to="next" icon="next" /> : ""}

        {currentStep === 2
          ? <ButtonSystem action={() => submit()} status={buttonNext && currentStep === 2} text="Salvar e Adicionar Outro KR" to="next" icon="add" /> : ""}

        {currentStep === 2
          ? <ButtonSystem action={() => { submit(); navigate(`/objetivos`) }} status={buttonNext && currentStep === 2} text="Salvar" to="finish" icon="finish" /> : ""}

      </div>

    </section >
  );
}