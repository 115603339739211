import { useRecoilValue, useSetRecoilState } from "recoil";
import { updateState } from "state/atom";
import ButtonSystemNoText from "components/forms/button/buttonNoText";


export default function UpdateFirestore(){

  const update = useRecoilValue(updateState);
  const setUpdateState = useSetRecoilState(updateState);

  return(

      <ButtonSystemNoText action={(e:any) => setUpdateState(!update)} status={true} text="Atualizar" to="next" icon="update" />
  )
}