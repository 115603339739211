import { FaCalendarAlt, FaChartPie, FaUsers } from "react-icons/fa";
import style from "./singleMeeting.module.scss";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { businessIdState, meetingsState, okrsState, updateMeetingState, userDataState } from "state/atom";
import DoughnutPercentage from "components/charts/doughnutPercentage";
import { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IMeeting } from "interface/meeting";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField } from "@mui/material";
import dayjs from "dayjs";
import { MathOkrs } from "services/math/mathOkrs";
import ShowUsers from "components/users/show";
import SelectUsers from "components/users/select";
import MeetSingleOkr from "components/reunioes/singleOkr";
import classNames from "classnames";
import UpDown from "components/reunioes/upDown";
import stylePages from "styles/pages.module.scss";
import UpdateFirestore from "components/general/update";
import ButtonSystem from "components/forms/button";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import UpdateMeetings from "components/general/update/updateMeetings";

const initialFilters = {
  date: "",
  status: "open",
  team: [],
  okrs: [],
  result: 0,
  notes: [],
};

const filtersStateMeeting = (state: any, action: any) => {
  switch (action.type) {

    case "date":
      return { ...state, date: action.payload };

    case "status":
      return { ...state, status: action.payload };

    case "team":
      return { ...state, team: action.payload };

    case "okrs":
      return { ...state, okrs: action.payload };

    case "result":
      return { ...state, result: action.payload };

    case "notes":
      return { ...state, notes: action.payload };

    case "SET_MULTIPLE":
      return { ...state, ...action.payload }

    case "reset":
      return action.payload;

    default:
      throw new Error();
  }
}

export default function SingleMeeting() {

  // LOADING DATA STATE
  const businessId = useRecoilValue(businessIdState);
  const setMeetingUpdate = useSetRecoilState(updateMeetingState);
  const updateMeeting = useRecoilValue(updateMeetingState);
  const { meetId } = useParams();
  const listOfOkrs = useRecoilValue(okrsState);
  const listOfMeetings = useRecoilValue(meetingsState);

  const userData = useRecoilValue(userDataState);

  // TODAY MEETING
  const [stateMeeting, dispatchMeeting] = useReducer(filtersStateMeeting, initialFilters);

  // ALL OTHER MEETINGS
  const [optionsCompare, setOptionsCompare] = useState<IMeeting[]>([]);

  // COMPARE MEETING SELECTED
  const [dateOfCompareMeeting, setDateOfCompareMeeting] = useState("");
  const [compareMeeting, setCompareMeeting] = useState<IMeeting | undefined>(undefined);

  // ENABLE ACTIONS
  const [chartKey, setChartKey] = useState(0);
  const [open, setOpen] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [buttonParticipacoes, setButtonParticipacoes] = useState(false);

  // NAVIGATE
  const navigate = useNavigate();
  const previousPage = () => {
    navigate(-1);
  }


  // CATCH TODAYS MEETING
  useEffect(() => {

    const listOfMeeting2 = [...listOfMeetings];
    const filteredMeeting = listOfMeeting2.find((meeting) => meeting.date === meetId);

    if (filteredMeeting) {

      dispatchMeeting({ type: "SET_MULTIPLE", payload: { "date": filteredMeeting.date, "status": filteredMeeting.status, "team": filteredMeeting.team, "okrs": filteredMeeting.status === "closed" ? filteredMeeting.okrs : [], "result": filteredMeeting.result, "notes": filteredMeeting.notes } });


      if (filteredMeeting.status === "open") {

        const okrsTratados = listOfOkrs.map((okr) => {
          const krs = okr.krs.map((kr) => {
            if (kr.checkpoints.length > 0) {
              const latestCheckpoint = kr.checkpoints.reduce((previousCheckpoint, currentCheckpoint) => {
                return previousCheckpoint.date > currentCheckpoint.date ? previousCheckpoint : currentCheckpoint;
              });

              // Substituir a matriz de checkpoints pelo último checkpoint
              const updatedKr = { ...kr, checkpoints: [latestCheckpoint] };

              // Remover a propriedade "tasks" do KR atualizado
              const { tasks, ...krWithoutTasks } = updatedKr;
              return krWithoutTasks;
            }

            // Remover a propriedade "tasks" de cada KR
            const { tasks, ...krWithoutTasks } = kr;
            return krWithoutTasks;
          });

          return { ...okr, krs };
        });


        dispatchMeeting({ type: "okrs", payload: okrsTratados });

        const resultadoGeral = MathOkrs({ okrs: okrsTratados });
        dispatchMeeting({ type: "result", payload: resultadoGeral.percentage });
      }


    }
    const remainingMeetings = listOfMeeting2.filter((meeting) => meeting.date !== meetId);
    setOptionsCompare(remainingMeetings);

  }, [listOfMeetings, meetId, updateMeeting, listOfOkrs]);


  // CATCH LAST MEETING
  useEffect(() => {
    const listOfMeeting3 = [...listOfMeetings];
    const filteredMeeting2 = listOfMeeting3.find((meeting) => meeting.date === dateOfCompareMeeting);

    if (filteredMeeting2) {
      setCompareMeeting(filteredMeeting2);
    }
  }, [dateOfCompareMeeting]);

  console.log("compareMeeting", compareMeeting);



  // FORMATANDO AS DATAS e RESULTADOS
  const lastMeetingResult = compareMeeting ? compareMeeting.result : "Sem Dados";



  // ACTIONS BUTTONS
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSave = () => {
    setOpenSave(false);
  };

  // DELETAR A REUNIÃO
  const deleteMeeting = () => {

    // Verifique se businessId e meetingDate estão definidos
    if (!businessId || !meetId) {
      console.error("businessId e meetingDate são obrigatórios.");
      return;
    }

    const meetingRef = doc(db, "companies", businessId, "meetings", "docmeetings");

    const meetingDocSnapshot = getDoc(meetingRef);

    meetingDocSnapshot.then((docSnapshot) => {
      if (docSnapshot.exists()) {
        // Se o documento existir, atualize-o
        const meetingData = docSnapshot.data();
        if (meetingData) {
          const targetMeeting = meetingData.meetings.find((meeting: any) => meeting.date === meetId);
          if (targetMeeting) {
            meetingData.meetings = meetingData.meetings.filter((meeting: any) => meeting.date !== meetId);
            updateDoc(meetingRef, meetingData);

            setMeetingUpdate(!updateMeeting);

            navigate("/reunioes");
          } else {
            console.error("O documento da reunião não existe.");
          }
        } else {
          console.error("O documento da reunião não existe.");
        }
      } else {
        // Se o documento não existir, crie-o
        console.error("O documento da reunião não existe.");
      }
    });







    setOpen(false);
  }

  // SALVAR A REUNIÃO
  const submit = () => {

    const today = new Date();

    // Verifique se businessId e meetingDate estão definidos
    if (!businessId || !meetId) {
      console.error("businessId e meetingDate são obrigatórios.");
      return;
    }

    const meetingRef = doc(db, "companies", businessId, "meetings", "docmeetings");

    const meetingDocSnapshot = getDoc(meetingRef);

    meetingDocSnapshot.then((docSnapshot) => {
      if (docSnapshot.exists()) {
        // Se o documento existir, atualize-o
        const meetingData = docSnapshot.data();
        if (meetingData) {
          const targetMeeting = meetingData.meetings.find((meeting: any) => meeting.date === meetId);
          if (targetMeeting) {
            targetMeeting.okrs = stateMeeting.okrs;
            targetMeeting.result = stateMeeting.result;
            targetMeeting.status = "closed";
            targetMeeting.closed = dayjs(today).format("YYYY-MM-DD");
            updateDoc(meetingRef, meetingData);

            setMeetingUpdate(!updateMeeting);

            navigate("/reunioes");
          } else {
            console.error("O documento da reunião não existe.");
          }
        } else {
          console.error("O documento da reunião não existe.");
        }
      } else {
        // Se o documento não existir, crie-o
        console.error("O documento da reunião não existe.");
      }
    });




  }



  // PARTICIPANTES
  const editTeams = (e: any) => {
    dispatchMeeting({ type: "team", payload: e });
    setButtonParticipacoes(true);
  }
  const saveTeams = async () => {
    if (!businessId || !meetId) {
      console.error("businessId e meetingDate são obrigatórios.");
      return;
    }
    const meetingRef = doc(db, "companies", businessId, "meetings", "docmeetings");
    const meetingDocSnapshot = await getDoc(meetingRef);
    const meetingsData = meetingDocSnapshot.data();
    if (meetingsData) {
      const targetMeeting = meetingsData.meetings.find((meeting: any) => meeting.date === meetId);
      if (targetMeeting) {
        targetMeeting.team = stateMeeting.team;
        await updateDoc(meetingRef, meetingsData);
      } else {
        console.error("O documento da reunião não existe.");
      }
    } else {
      console.error("O documento da reunião não existe.");
    }
    setButtonParticipacoes(false);
  }




  return (
    <section className={style.section}>
      <div className={stylePages.header}>
        <div className={stylePages.header__container}>
          <div className={stylePages.header__title}>
          </div>
        </div>

        {/* ACTIONS BUTTONS */}

        <div className={stylePages.header__actions}>
          <ButtonSystem text="Voltar" action={previousPage} status={true} to="previous" icon="back" />
          <UpdateMeetings />
          <ButtonSystem text="Deletar" action={() => handleClickOpen()} status={userData.admin === true ? true : false} to="remove" icon="remove" />
          <ButtonSystem text="Salvar Reunião" action={() => setOpenSave(true)} status={userData.admin === true && stateMeeting.status === "open" ? true : false} to="finish" icon="finish" />
        </div>

        {/* DIALOG DELETE */}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Você tem certeza que deseja excluir este Reunião?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ao excluir esta reunião, a ação não poderá ser desfeita.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={deleteMeeting} autoFocus>
              Excluir
            </Button>
          </DialogActions>
        </Dialog>

        {/* SAVE MEETING DIALOG */}

        <Dialog
          open={openSave}
          onClose={handleCloseSave}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Você já adicionou todos as anotações e participantes?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ao salvar essa reunião ela não poderá ser reaberta, para seus dados ficarem salvos.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSave}>Cancelar</Button>
            <Button onClick={submit} autoFocus>
              Salvar
            </Button>
          </DialogActions>
        </Dialog>




      </div>

      {/* HEADER */}

      <div className={style.container}>

        <div className={classNames({
          [style.container__left]: true,
          [style.background__degrade]: true,
        })}>
          <div className={style.component__container}>
            <div className={classNames({
              [style.title__left]: true,
              [style.color__branco]: true,
            })}>
              Reunião
            </div>
          </div>
        </div>
        <div className={classNames({
          [style.container__center]: true,
          [style.background__branco]: true,
        })}>

          <div className={classNames({
            [style.component__title]: true,
            [style.color__cinza]: true,
          })}>
            <FaCalendarAlt className={style.title__subicon} />
            REUNIÃO
          </div>

          <div className={style.component__container}>
            <div className={style.title__center}>
              {dayjs(stateMeeting.date).format('DD/MM/YYYY')}
            </div>
            <div className={style.notes}>
              {stateMeeting.status === "closed" ? "Salva no dia " + dayjs(stateMeeting.closed).format('DD/MM/YYYY') : ""}
            </div>
          </div>
        </div>


        <div className={classNames({
          [style.container__right]: true,
          [style.background__cinza]: true,
        })}>
          <div className={classNames({
            [style.component__title]: true,
            [style.color__cinza]: true,
          })}>
            <FaCalendarAlt className={style.title__subicon} />
            REUNIÃO - COMPARAÇÃO
          </div>

          <div className={style.component__container}>
            <TextField
              id="lastmeeting"
              select
              label="Comparação"
              value={dateOfCompareMeeting}
              onChange={(e: any) => setDateOfCompareMeeting(e.target.value)}
              sx={{
                width: '100%', '& label': {
                  minWidth: 100,
                },
              }}
            >
              {optionsCompare.map((option: any) => <MenuItem value={option.date}>
                {dayjs(option.date).format('DD/MM/YYYY')}
              </MenuItem>)}
            </TextField>
          </div>
        </div>


      </div>


      {/* GENERAL RESULTS */}

      <div className={style.container}>


        <div className={classNames({
          [style.container__left]: true,
          [style.background__roxo]: true,
        })}>
          <div className={style.component__container}>
            <FaChartPie className={classNames({
              [style.title__icon]: true,
              [style.color__verde]: true,
            })} />
            <div className={classNames({
              [style.title__left]: true,
              [style.color__branco]: true,
            })}>
              Resultado Geral
            </div>
            <div className={style.comparative}>
              <UpDown resultType="Conquista" dataType="%" initialValue={0} previousValue={compareMeeting?.result} nowValue={stateMeeting.result} finalValue={100} />
            </div>

          </div>
        </div>


        <div className={classNames({
          [style.container__center]: true,
          [style.background__branco]: true,
        })}>

          <div className={classNames({
            [style.component__title]: true,
            [style.color__cinza]: true,
          })}>
            <FaChartPie className={style.title__subicon} />
            RESULTADO GERAL
          </div>

          <div className={style.component__container}>
            <DoughnutPercentage key={chartKey} doneValue={stateMeeting.result} initialValue={0} finalValue={100} resultType="Conquista" height={250} />
          </div>
        </div>

        <div className={classNames({
          [style.container__right]: true,
          [style.background__cinza]: true,
        })}>

          <div className={classNames({
            [style.component__title]: true,
            [style.color__cinza]: true,
          })}>
            <FaChartPie className={style.title__subicon} />
            RESULTADO GERAL - COMPARAÇÃO
          </div>

          <div className={style.component__container}>
            {lastMeetingResult !== "Sem Dados" ? <DoughnutPercentage key={chartKey} doneValue={lastMeetingResult} initialValue={0} finalValue={100} resultType="Conquista" height={150} /> : <div className={style.semDados}>SEM DADOS</div>}
          </div>
        </div>
      </div>


      {/* TEAM */}

      <div className={style.container}>


        <div className={classNames({
          [style.container__left]: true,
          [style.background__roxo]: true,
        })}>

          <div className={style.component__container}>

            <FaUsers className={classNames({
              [style.title__icon]: true,
              [style.color__verde]: true,
            })} />
            <div className={classNames({
              [style.title__left]: true,
              [style.color__branco]: true,
            })}>

              Participantes

            </div>
          </div>
        </div>

        <div className={classNames({
          [style.container__center]: true,
          [style.background__branco]: true,
        })}>

          <div className={classNames({
            [style.component__title]: true,
            [style.color__cinza]: true,
          })}>
            <FaUsers className={style.title__subicon} />
            PARTICIPANTES
          </div>

          <div className={style.component__container}>


            {stateMeeting.status === "closed" ?
              <div className={style.notes}>
                <ShowUsers users={stateMeeting.team} />
              </div>
              : <>

                <div className={style.chart}>
                  <SelectUsers label="Selecione os participantes" users={stateMeeting.team} handleUsers={(e: any) => editTeams(e)} />
                </div>
                <span className={style.space} />
                <div className={style.notes}>
                  <ButtonSystem text={buttonParticipacoes ? "Salvar Participações" : "Salvo"} action={() => saveTeams()} status={buttonParticipacoes} to="finish" icon="finish" />
                </div></>}
          </div>
        </div>

        <div className={classNames({
          [style.container__right]: true,
          [style.background__cinza]: true,
        })}>
          <div className={classNames({
            [style.component__title]: true,
            [style.color__cinza]: true,
          })}>
            <FaUsers className={style.title__subicon} />
            PARTICIPANTES - COMPARAÇÃO
          </div>

          <div className={style.component__container}>
            {compareMeeting ? <ShowUsers users={compareMeeting?.team} /> : <div className={style.semDados}>SEM DADOS</div>}
          </div>
        </div>
      </div>


      {stateMeeting.okrs.map((okr: any, index: any,) => <MeetSingleOkr okr={okr} compareMeeting={compareMeeting} businessId={businessId} meetId={meetId} meetingNotes={stateMeeting.notes !== undefined ? stateMeeting.notes : []} key={index} status={stateMeeting.status} />)}

      <div className={style.submit}>

        <ButtonSystem text="Voltar" action={previousPage} status={true} to="previous" icon="back" />
        <ButtonSystem text="Salvar Reunião" action={() => setOpenSave(true)} status={userData.admin === true && stateMeeting.status === "open" ? true : false} to="finish" icon="finish" />

      </div>

    </section >
  )
}