import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { businessUsersState } from "state/atom";
import style from "../../../styles/components.module.scss";
import styleIntern from "./select.module.scss";
import Select from 'react-select';
import { baseURL } from "baseURL";
import makeAnimated from 'react-select/animated';

type IUserValid = {
  label: string,
  users: string[],
  handleUsers: any,
}

export default function SelectUsers(props: IUserValid) {

  // DATA TO USE
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedNameUsers, setSelectedNameUsers] = useState<string[]>([]);
  const businessUsers = useRecoilValue(businessUsersState);

 // GET DATA FROM PARENT
 useEffect(() => {
  if (props.users) {
    // Receiving the IDs
    setSelectedUsers(props.users);

    // Filtering the users with the IDs
    const filteringUsers = businessUsers.filter((user: any) => props.users.includes(user.id));
    const nameUsers = filteringUsers.map((user: any) => user.name);

    // Sending the names
    setSelectedNameUsers(nameUsers);
  }
}, [props.users]);

  // SEND TO PARENT
  const selectUsers = (selectedUsers: string[]) => {
    setSelectedNameUsers(selectedUsers);
  
    // Obter os IDs correspondentes para os usuários selecionados
    const selectedUserIds = selectedUsers.map((selectedUser) => {
      const user = businessUsers.find((user) => user.name === selectedUser);
      return user ? user.id : null; // Se o usuário for encontrado, retorna o ID, caso contrário, retorna null
    });
  
    setSelectedUsers(selectedUserIds);
    props.handleUsers(selectedUserIds);
  };

  const options = businessUsers.map((user: any) => ({
    value: user.name,
    label: (
      <div className={styleIntern.user}>
        <div>
          {!user.photoURL || user.photoURL === "" ? (
            <img src={baseURL + "/assets/avatar.png"} alt={user.name} className={styleIntern.userPhoto} />
          ) : (
            <img src={user.photoURL} alt={user.name} className={styleIntern.userPhoto} />
          )}
        </div>
        <div className={styleIntern.text}>{user.name}</div>
      </div>
    ),
  }));

  const animatedComponents = makeAnimated();

  return (
    <div className={styleIntern.content}>
      <div className={style.label}>{props.label}:</div>
      <div className={styleIntern.contentUsers}>
        <Select
          isMulti
          value={selectedNameUsers.map((user) => options.find((option) => option.value === user))}
          styles={{ container: (base) => ({ ...base, width: "100%" }) }}
          components={animatedComponents}
          menuPlacement="auto"
          options={options}
          onChange={(selectedOptions) =>
            selectUsers(selectedOptions.map((option: any) => option.value))
          }
        />
      </div>
    </div>
  );
}