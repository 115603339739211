import NewObjective from "components/okr/newObjective";
import { useState } from "react";
import style from "../../../styles/addSometing.module.scss";

import { FaCodeBranch } from "react-icons/fa";

export default function NewOkr() {

  const [data, setData] = useState() as any;

  const okrName = data ? data.okrName : "Defina um novo Objetivo";

  return (
    <section className={style.section}>

      <div className={style.view}>
        <FaCodeBranch className={style.item} />
        <div className={style.printObjective}>
          {okrName}
        </div>
      </div>

      <div className={style.content}>
      <div className={style.container}>

          <NewObjective data={(e:any) => setData(e)} />

          </div>

      </div>

    </section>
  );
}