import OkrSingle from "components/okr/okrSingle";
import stylePages from "styles/pages.module.scss";
import { useRecoilValue } from "recoil";
import { okrsState } from "state/atom";
import { useNavigate } from "react-router-dom";
import ButtonSystem from "components/forms/button";
import Filters from "components/filters";
import { useState } from "react";

export default function Okrs() {
  const okrs = useRecoilValue(okrsState);

  const [filteredOkrs, setFilteredOkrs] = useState([]);

  const navigate = useNavigate();

  const addKR = (e: any) => {
    navigate(`/edit/newokr`);
  }

  const filters = (e: any) => {
    setFilteredOkrs(e);
  }

  const goToArchive = () => {
    navigate("/arquivo")
  }

  return (
    <section className={stylePages.container__section}>
      <div className={stylePages.header}>

        <div className={stylePages.header__container}>
          <div className={stylePages.header__title}>
            Nossos Objetivos
          </div>
        </div>

        <div className={stylePages.header__actions}>
          <Filters listOfOkrs={okrs} filteredOkrs={(e:any) => filters(e)} />
          <ButtonSystem action={(e: any) => goToArchive()} status={true} text="Arquivo" to="previous" icon="archive" />
          <ButtonSystem action={(e: any) => addKR(e)} status={true} text="Adicionar Objetivo" to="next" icon="add" />
        </div>
      </div>
      {filteredOkrs.length === 0 ? <div className={stylePages.noData}>Nenhum objetivo encontrado.</div> : ""}

      {filteredOkrs.map((okr:any, index:any) => (
        <OkrSingle key={index} data={okr} select={true} archive={false} />
      ))}
    </section>
  );
}
