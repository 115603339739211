import { db } from "config/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { IUser } from "interface/user";

export async function getUserData(businessId:any, userId:any, setUserData:any) {
  if (businessId && userId) {
    const companyRef = doc(db, "companies", businessId);
    const userRef = doc(companyRef, "users", userId);
    
    return new Promise<void>((resolve) => {
      const unsubscribe = onSnapshot(userRef, (snapshot: any) => {
      const userData = snapshot.data() as IUser;
      const { name, email, role, admin, teams, userActive, photoURL, id } = userData;
      const userToUpdate = {
        id: id,
        name: name,
        email: email,
        role: role,
        admin: admin,
        teams: teams,
        userActive: userActive,
        photoURL: photoURL,
      };
      setUserData(userToUpdate);
        
        // Resolve a promessa após a conclusão da lógica
        resolve();
      });
  
      // Retornar uma função para descadastrar o observador quando necessário
      return () => {
        unsubscribe();
      };
    });
  }
}