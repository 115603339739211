import { db } from "config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import TeamsData from "../../data/teams.json";

export async function verifyTeamsCreated(businessId: any) {
  try {
    if (businessId) {
      const businessRef = doc(db, "companies", businessId);
      
      // Verifique se o documento "companies" com o ID do negócio existe
      const businessDoc = await getDoc(businessRef);
      
      if (businessDoc.exists()) {
        const businessData = businessDoc.data();
        
        // Verifique se o campo "teams" já existe no documento "companies"
        if (!businessData.teams) {
          // Se não existir, crie-o com os dados fornecidos
          await setDoc(businessRef, { teams: TeamsData }, { merge: true });
        }
      }
    }
  } catch (error) {
    console.error("Erro ao verificar/criar o campo 'teams':", error);
  }
}