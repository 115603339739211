import { baseURL } from "baseURL";
import ButtonSystem from "components/forms/button";
import style from "./InfoLogin.module.scss";

export default function InfoLogin() {

  const convert = () => {

  }


  return (
    <section className={style.section}>
      <div className={style.content}>
        <div className={style.logo}>
          <img src={
            baseURL + "/assets/yscale.svg"} alt="Yscale" className={style.logo__img} />
        </div>
        <div className={style.titleA}>Comunique seus objetivos, inspire seu time e escale os resultados.</div>
        <div className={style.titleB}>Gerencie de forma simples e intuitiva, comunique com transparência os objetivos da empresa e alcance o sucesso.</div>
        <div className={style.divButton}>
          <ButtonSystem action={convert} status={true} text="Quero impulsionar meu time!" to="finish" icon="finish" />
        </div>
      </div>
    </section>
  );
}