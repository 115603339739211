import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useEffect, useState } from 'react';
import style from "./gaugeChart.module.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

type IGaugeChart = {
  doneValue: number,
  missingValue: number,
  percentValue: number,
}

export default function GaugeChart(props: IGaugeChart) {

  const [doneValue, setDoneValue] = useState(props.doneValue);
  const [missingValue, setMissingValue] = useState(props.missingValue);
  const [percent, setPercent] = useState(props.percentValue);


  useEffect(() => {
    setDoneValue(props.doneValue);
    setMissingValue(props.missingValue);
    setPercent(props.percentValue);

  }, [props])


  // const gaugeChartText = {
  //   id: "gaugeChartText",
  //   afterDatasetDraw(chart: any, args: any, pluginOptions: any) {
  //     const { ctx, chartArea: { top, bottom, left, right, width, height } } = chart;

  //     const xCenter = chart.getDatasetMeta(0).data[0].x;
  //     const yCenter = chart.getDatasetMeta(0).data[0].y;

  //     ctx.save();
  //     ctx.fillStyle = "#7551d9";
  //     ctx.font = "600 3em Blinker, sans-serif";
  //     ctx.fillText(`${percent}%`, xCenter, yCenter - 1);
  //     ctx.textAlign = "center";
  //     ctx.textBaseline = "baseline";
  //   }

  // }


  const data = {
    labels: ["Concluído", "Em aberto"],
    datasets: [{
      label: 'Progress',
      data: [doneValue, missingValue],
      backgroundColor: (context: any) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          return null
        }
        if (context.dataIndex === 0) {
          return getGradient(chart);
        } else {
          return "rgba(241,241,241,1)"
        }
      },
      hoverOffset: 4
    }]
  }

  function getGradient(chart: any) {
    const { ctx, chartArea: { top, bottom, left, right } } = chart;
    const gradientSegment = ctx.createLinearGradient(left, 0, right, 0);
    gradientSegment.addColorStop(0.1, "rgba(117,81,217,1)");
    gradientSegment.addColorStop(0.5, "rgba(98,176,211,1)");
    gradientSegment.addColorStop(0.9, "rgba(36,255,143,1)");
    return gradientSegment;
  }

  const options = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
    },
    rotation: -90,
    circumference: 180,
    cutout: "80%",
    maintainAspectRatio: false,
    responsive: true
  }


  return (
    <div className={style.container}>
    <Doughnut options={options} data={data} height={120} />
    <div className={style.container__title}>{`${percent}%`}</div>
    </div>
  );
}