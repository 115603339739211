import moment from "moment";
import style from "./Timeline.module.scss";

type ITimeline = {
  initialDate: string,
  finalDate: string
}

export default function Timeline(props: ITimeline) {

  const initialDate = props.initialDate;
  const finalDate = props.finalDate;
  const today = moment().format('YYYY-MM-DD');

  const viewInitialDate = moment(initialDate).format('DD/MM/YY');
  const viewFinalDate = moment(finalDate).format('DD/MM/YY');

  const startDate = moment(initialDate);
  const timeEnd = moment(finalDate);
  const timeToday = moment(today);


  const preTotalDays = moment(timeEnd).diff(startDate, 'days');
  const totalDays = Math.abs(preTotalDays);

  const preConcludedDays = moment(today).diff(startDate, 'days');
  const concluded = Math.abs(preConcludedDays);

  const preRemainsDays = moment(timeEnd).diff(today, 'days');

  const remains = timeToday < timeEnd ? preRemainsDays : 0;


 
  // const remains = Math.abs(removeZero);

  const concludedPercentage = (100 / totalDays) * concluded;




  return (
    <div className={style.timeline}>

      <div className={style.info}>
        <div className={style.infoA}>
          <div className={style.title}>
            {remains}
          </div>
          <div className={style.subtitle}>
            {remains === 1 ? "dia restante" : "dias restantes"}
          </div>
        </div>
        <div className={style.infoB}>

          {viewInitialDate}
          <hr className={style.separator} />
          {viewFinalDate}

        </div>


      </div>

    </div>
  );
}