
import style from "../../../styles/components.module.scss";
import { FaCodeBranch } from "react-icons/fa";
import { HiLightBulb } from "react-icons/hi";
import { useEffect, useState } from "react";
import styleComponent from "../../../styles/components.module.scss";
import ButtonSystem from "components/forms/button";
import objectivesList from "../../../data/objectives.json";
import { dateToEnd, dateToStart } from "components/date";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { businessIdState, okrsState, updateState } from "state/atom";
import { useNavigate, useParams } from "react-router-dom";
import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { ValidateDate } from "components/date/validate";
import TextField from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";


const initialFilters = {
  okrName: "Defina um novo Objetivo",
  initialDate: dateToStart,
  finalDate: dateToEnd
};

export default function NewObjective(props: any) {

  // DATA TO SAVE

  const [okrName, setOkrName] = useState(initialFilters.okrName);
  const [firstDate, setFirstDate] = useState(initialFilters.initialDate);
  const [endDate, setEndDate] = useState(initialFilters.finalDate);

  const [newOkr, setNewOkr] = useState(false);

  // MESSAGES

  const [messageObjective, setMessageObjective] = useState(false);
  const [messageDate, setMessageDate] = useState(false);
  const [messageDate2, setMessageDate2] = useState(false);
  const [diferenceDates, setDiferenceDates] = useState(true);
  const [buttonNext, setButtonNext] = useState(false);
  const [random, setRandom] = useState(1);

  const objectives = objectivesList;
  const businessId = useRecoilValue(businessIdState);
  const okrsData = useRecoilValue(okrsState);
  const { okr } = useParams();

  const update = useRecoilValue(updateState);
  const setUpdateState = useSetRecoilState(updateState);

  useEffect(() => {
    if (okr === "newokr") {
      setNewOkr(true);
    } else {
      setNewOkr(false);

      const okrData = okrsData.find((okrData) => okrData.id === okr);

      if (okrData) {

        setOkrName(okrData.okrName);
        setFirstDate(okrData.initialDate);
        setEndDate(okrData.finalDate);
      }

    }
  }, []);


  const navigate = useNavigate();

  // RETURN DATA TO PARENT

  useEffect(() => {
    props.data({
      okrName: okrName,
      initialDate: firstDate,
      finalDate: endDate,
    });
  }, [okrName, firstDate, endDate]);

  // SUBMIT OKR

  const submit = () => {
    if (businessId) {
      // Define a referência para a coleção de "okrs" da empresa
      const okrsRef = collection(db, "companies", businessId, "okrs");

      // Define o ID do documento a ser criado ou atualizado
      const okrId = newOkr ? doc(okrsRef).id : okr;

      // Define a referência para o documento a ser atualizado ou criado
      const okrDocRef = doc(okrsRef, okrId);

      // Verifica se o documento já existe
      getDoc(okrDocRef).then((docSnapshot) => {
        if (docSnapshot.exists()) {
          // Atualiza as informações do state no documento existente
          updateDoc(okrDocRef, {
            okrName: okrName,
            initialDate: firstDate,
            finalDate: endDate,
          });

          setUpdateState(!update);

          navigate(`/objetivos`);


        } else {
          // Cria um novo documento com as informações do state
          setDoc(okrDocRef, {
            id: okrId,
            okrName: okrName,
            initialDate: firstDate,
            finalDate: endDate,
          });

          navigate(`/edit/${okrId}/newkr`);
        }
      });
    }
  };

  // VALIDATE OBJECTIVE
  const changeObjective = (e: any) => {
    setOkrName(e);
    if (e !== "" && e !== undefined && messageObjective === true) {
      setMessageObjective(false);
    } else if ((e === "" || e === undefined) && messageObjective === false) {
      setMessageObjective(true);
    }
  }

  // VALIDATE BUTTON 

  useEffect(() => {

    if (messageObjective === false && okrName !== "" && okrName !== undefined
      && diferenceDates === true && messageDate === false && messageDate2 === false) {
      setButtonNext(true);
    } else if (buttonNext === true) {
      setButtonNext(false);
    }
  }, [messageObjective, okrName, diferenceDates, messageDate, messageDate2]);


  // RANDOM IDEA
  const randomIdea = (e: any) => {
    e.preventDefault();
    const randomMin = Math.ceil(1);
    const randomMax = Math.floor(objectives.length);
    const first = Math.floor(Math.random() * (randomMax - randomMin + 1)) + randomMin;
    const randomNumber = first - 1;
    if (randomNumber !== random) {
      setOkrName(objectives[randomNumber]);
      setRandom(randomNumber);
    } else if (randomNumber === random && randomNumber === 0) {
      setOkrName(objectives[randomNumber + 1]);
      setRandom(randomNumber + 1);
    } else if (randomNumber === random && randomNumber !== 0) {
      setOkrName(objectives[randomNumber - 1]);
      setRandom(randomNumber - 1);
    }
  }

  // CHANGE FIRST DATE

  const changeFirstDate = (e: any) => {
    const date = dayjs(e);
    const formattedDate = date.format("YYYY-MM-DD");
    setFirstDate(formattedDate);
  }

  // CHANGE FINAL DATE
  const changeEndDate = (e: any) => {
    const date = dayjs(e);
    const formattedDate = date.format("YYYY-MM-DD");
    setEndDate(formattedDate);
  }

   // VALIDATE FIRST DATE

   const validateInitialDate = (e: any) => {
    const date = dayjs(e, 'DD/MM/YYYY');
    const formattedDate = date.format("YYYY-MM-DD");
    const validate = ValidateDate(formattedDate);
    if (validate === true) {
      setMessageDate(false);
    } else {
      setMessageDate(true);
    }
  }

  // VALIDATE FINAL DATE
  const validateEndDate = (e: any) => {
    const date = dayjs(e, 'DD/MM/YYYY');
    const formattedDate = date.format("YYYY-MM-DD");
    const validate = ValidateDate(formattedDate);
    if (validate === true) {
      setMessageDate2(false);
    } else {
      setMessageDate2(true);
    }
  }

  // VALIDATE DATE DIFFERENCE

  useEffect(() => {
    const previousDate = new Date(firstDate).getTime();
    const postDate = new Date(endDate).getTime();
    if (previousDate < postDate && diferenceDates !== true) {
      setDiferenceDates(true)
    } else if (previousDate >= postDate && diferenceDates !== false) {
      setDiferenceDates(false)
    }
  }, [firstDate, endDate]);


  return (
    <section className={style.section}>

      <div className={style.title}><FaCodeBranch className={style.item} />Novo Objetivo</div>
      <div className={style.subtitle}>Descreva o que gostaria de alcançar. (Simples e Qualitativo)</div>

      <button type="button" className={style.button} onClick={randomIdea}><HiLightBulb className={style.item__button} />ideia</button>

      <div className={style.div__input}>

        <TextField
          label="Objetivo"
          error={Boolean(messageObjective)}
          helperText={messageObjective ? "Adicione um novo Objetivo" : ""}
          value={okrName}
          onChange={(e) => changeObjective(e.target.value)}
          placeholder="Digite um objetivo..."
          sx={{
            width: '100%', '& label': {
              minWidth: 200,
            },
          }}
          fullWidth
        />

      </div>
      <div className={style.div__input}>

        <div className={style.horizontal}>
          <div className={style.col50}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Data Inicial"
                format="DD/MM/YYYY"
                value={dayjs(firstDate)}
                onChange={(e: any) => {
                  changeFirstDate(e);
                  validateInitialDate(e)}
                }
                sx={{
                  width: '100%', '& label': {
                    minWidth: 200,
                  },
                }}
              />
            </LocalizationProvider>

          </div>
          <div className={style.col50}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Data Final"
                format="DD/MM/YYYY"
                value={dayjs(endDate)}
                onChange={(e: any) => {
                  changeEndDate(e);
                  validateEndDate(e)}
                }
                sx={{
                  width: '100%', '& label': {
                    minWidth: 200,
                  },
                }}
              />
            </LocalizationProvider>
          </div>

        </div>
        <span className={style.message}>
          {diferenceDates === false ? <> - A data final precisa ser maior que a data inicial.</> : ""}
        </span>
      </div>

      <div className={styleComponent.controls}>

        <ButtonSystem action={(e: any) => submit()} status={buttonNext} text="Salvar" to="finish" icon="finish" />

        {newOkr ? "" : <ButtonSystem action={(e: any) => navigate(`/edit/${okr}/newkr`)} status={buttonNext} text="Adicionar Resultado-chave" to="next" icon="next" />}

      </div>

    </section>
  );
}