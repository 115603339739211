import { FaCodeBranch } from "react-icons/fa";
import style from "./okrName.module.scss";


export default function OkrName(props: any) {

  return (
    <div className={style.container}>
        <div className={style.okr}>
          <FaCodeBranch className={style.item} /> {props.okrName}
        </div>
    </div>
  );
}
