import classNames from "classnames";
import { useEffect, useState } from "react";
import style from "../../../styles/components.module.scss";
import { baseURL } from "baseURL";
import { useRecoilValue } from "recoil";
import { businessInfoState, updateState } from "state/atom";

type IAreaValid = {
  label: string,
  value: string[],
  handleArea: any,
}

export default function SelectArea(props: IAreaValid) {

  const [selectedAreas, setSelectedAreas] = useState<string[]>([]);
  const businessInfo = useRecoilValue(businessInfoState);
  const teams = businessInfo.teams;
  const update = useRecoilValue(updateState);

  useEffect(() => {
    setSelectedAreas(props.value);
  }, [props.value, update]);

  const teamsSort = [...teams].sort((a, b) => {
    const teamA = a.team.toLowerCase();
    const teamB = b.team.toLowerCase();

    if (teamA < teamB) {
      return -1;
    }
    if (teamA > teamB) {
      return 1;
    }
    return 0;
  });


  const onSelect = (e: string) => {

    if (selectedAreas.includes(e) === true) {
      setSelectedAreas(selectedAreas.filter((r: any) => r !== e));
      props.handleArea(selectedAreas.filter((r: any) => r !== e));
    }
    else {
      setSelectedAreas([...selectedAreas, e]);
      props.handleArea([...selectedAreas, e]);
    }
  }

  return (
    <div className={style.label}>
      <>
        {props.label}:
        <div className={style.areas}>

          {teamsSort.map((area: any, index: any) => <button type="button" className={classNames({
            [style.singleArea]: true,
            [selectedAreas.includes(area.id) === true ? style["singleArea--selected"] : style["singleArea--notSelected"]]: true,
          })} onClick={(e: any) => onSelect(e.target.id)} key={index} id={area.id} placeholder="">

            <img src={baseURL + area.icon} alt={area.team} className={classNames({
              [style.singleArea__icon]: true,
              [selectedAreas.includes(area.id) === true ? style["singleArea__icon--active"] : ""]: true
            })} /> {area.team}
          </button>
          )}
        </div>
      </>
    </div>
  );
}