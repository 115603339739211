import style from "styles/components.module.scss";
import { useEffect, useReducer, useState } from "react";
import styleComponent from "styles/components.module.scss";
import ButtonSystem from "components/forms/button";
import { FaCheck, FaCheckCircle, FaCopy, FaTimesCircle, FaUser } from "react-icons/fa";
import styleAdd from "styles/addSometing.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../config/firebase";
import { doc, setDoc } from "firebase/firestore";
import { TextField } from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import styleSuccess from "styles/sucess.module.scss";
import SelectArea from "components/teams/selectArea";

const initialData = {
  idCad: `${Date.now()}-${uuidv4()}`,
  email: "",
  admin: false,
  name: "",
  role: "",
  userActive: true,
  photoURL: "",
  teams: []
};


const filtersStatesUser = (state: any, action: any) => {
  switch (action.type) {

    case "idCad":
      return { ...state, idCad: action.payload };

    case "email":
      return { ...state, email: action.payload };

    case "admin":
      return { ...state, admin: action.payload };

    case "name":
      return { ...state, name: action.payload };

    case "role":
      return { ...state, role: action.payload };

    case "userActive":
      return { ...state, userActive: action.payload };

    case "teams":
      return { ...state, teams: action.payload };

    case "photoURL":
      return { ...state, photoURL: action.payload };

    case "reset":
      return action.payload;

    default:
      throw new Error();

  }
}


export default function AddUser() {

  const { businessId } = useParams();
  const navigate = useNavigate();

  const [stateUser, dispatchUser] = useReducer(filtersStatesUser, initialData);

  const [success, setSuccess] = useState(false);
  const [link, setLink] = useState("");
  const [copy, setCopy] = useState(false);

  const [messageName, setMessageName] = useState(false);
  const [messagePassword, setMessagePassword] = useState(false);
  const [messageRole, setMessageRole] = useState(false);
  const [messageEmail, setMessageEmail] = useState(false);

  const [buttonUpdate, setButtonUpdate] = useState(false);


  const nameVerify = (e: any) => {
    dispatchUser({ type: "name", payload: e });

    if (e === "" && !messageName) {
      setMessageName(true);
    } else if (e !== "" && messageName) {
      setMessageName(false);
    }
  }

  const roleVerify = (e: any) => {
    dispatchUser({ type: "role", payload: e });

    if (e === "" && !messageRole) {
      setMessageRole(true);
    } else if ((e !== "") && messageRole) {
      setMessageRole(false);
    }
  }


  const emailVerify = (e: any) => {
    dispatchUser({ type: "email", payload: e });

    if (e === "" && !messageEmail) {
      setMessageEmail(true);
    } else if ((e !== "") && messageEmail) {
      setMessageEmail(false);
    }
  }

  useEffect(() => {

    if ((stateUser.name !== "" && stateUser.name !== undefined)
      && (stateUser.role !== "" && stateUser.role !== undefined)
      && (stateUser.email !== "" && stateUser.email !== undefined)
      && (stateUser.teams.length > 0)
    ) {
      setButtonUpdate(true);
    } else if (buttonUpdate === true) {
      setButtonUpdate(false);
    }

  }, [stateUser]);

  const sendInvite = async (e: any) => {

    if (businessId) {

      try {
        const userDocRef = doc(db, "companies", businessId, "invites", stateUser.idCad);
        await setDoc(userDocRef, {
          email: stateUser.email,
          invite: `https://painel.yscale.com.br/newinvite/${businessId}/${stateUser.idCad}`
        });

        const addUserDocRef = doc(db, "companies", businessId, "users", stateUser.email);
        await setDoc(addUserDocRef, {
          email: stateUser.email,
          admin: stateUser.admin,
          name: stateUser.name,
          role: stateUser.role,
          userActive: true,
          photoURL: stateUser.photoURL,
          teams: stateUser.teams,

        });

        setLink(`https://painel.yscale.com.br/newinvite/${businessId}/${stateUser.idCad}`);

        setSuccess(true);

        // URL da API ou serviço para envio de e-mails
        const emailApiUrl = "https://hook.us1.make.com/4ylfhefj2cn8bsunnp5ozohzibvureyq";

        // Dados a serem enviados para a API (link e e-mail)
        const requestData = {
          link: `https://painel.yscale.com.br/newinvite/${businessId}/${stateUser.idCad}`,
          email: stateUser.email
        };


        // Envia os dados para a API (usando o método de sua preferência, por exemplo, fetch)
        const response = await fetch(emailApiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(requestData)
        });

        // Verifica se o envio foi bem-sucedido e retorna o resultado apropriado
        if (response.ok) {
          return "sucesso";
        } else {
          console.error("Error sending invite email");
          return "erro";
        }
      } catch (error) {
        console.error("Error updating user document: ", error);
        return "erro";
      }
    }
  };

  const cancel = (e: any) => {
    navigate(-1);
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setCopy(true);
  };

  return (

    <section className={styleAdd.section}>

      <div className={styleAdd.view}>
        <FaUser className={styleAdd.item} />
        <div className={styleAdd.printObjective}>
          Novo Usuário
        </div>
      </div>

      <div className={styleAdd.content}>
        <div className={styleAdd.container}>

          {success
            ? <section className={style.section}>

              <div className={style.title}><FaCheck className={style.item} /> Sucesso</div>

              <div className={style.div__input}>
                Compartilhe o link abaixo para cadastro do novo usuário:

                <div className={styleSuccess.pointer} onClick={copyToClipboard}>

                  <TextField
                    label="Link"
                    value={`https://painel.yscale.com.br/newinvite/business/${businessId}/user/${stateUser.idCad}`}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    sx={{
                      width: '100%',
                      '& label': {
                        minWidth: 200,
                      },
                    }}
                  />
                  <FaCopy className={styleSuccess.copy} />
                  {copy ? "Link Copiado!" : ""}
                </div>

              </div>

              <ButtonSystem action={(e: any) => cancel(e)} status={true} text="Voltar" to="previous" icon="cancel" />


            </section>
            : <section className={style.section}>

              <div className={style.div__input}>

                <TextField
                  label="Nome do Usuário"
                  error={Boolean(messageName)}
                  helperText={messageName ? "Adicione o nome do usuário" : ""}
                  value={stateUser.name}
                  onChange={(e: any) => nameVerify(e.target.value)}
                  sx={{
                    width: '100%', '& label': {
                      minWidth: 200,
                    },
                  }}
                  fullWidth
                />

              </div>


              <div className={style.div__input}>

                <TextField
                  label="E-mail"
                  error={Boolean(messageEmail)}
                  helperText={messageEmail ? "Adicione um e-mail válido" : ""}
                  value={stateUser.email}
                  onChange={(e: any) => emailVerify(e.target.value)}
                  sx={{
                    width: '100%', '& label': {
                      minWidth: 200,
                    },
                  }}
                  fullWidth
                />

              </div>

              <div className={style.div__input}>

                <TextField
                  label="Cargo"
                  error={Boolean(messageRole)}
                  helperText={messageRole ? "Adicione o cargo do usuário" : ""}
                  value={stateUser.role}
                  onChange={(e: any) => roleVerify(e.target.value)}
                  sx={{
                    width: '100%', '& label': {
                      minWidth: 200,
                    },
                  }}
                  fullWidth
                />
              </div>


              <div className={style.div__input}>

                <div className={style.admin}>
                  <div className={style.label}>Administrador:</div>
                  <div className={style.adminSelect} onClick={() => dispatchUser({ type: "admin", payload: !stateUser.admin })}>{stateUser.admin === true
                    ? <><FaCheckCircle className={style["active--true"]} /> ADMINISTRADOR</>
                    : <><FaTimesCircle className={style["active--false"]} /> SOMENTE USUÁRIO</>
                  }</div>
                </div>


                <div className={style.admin}>
                  <div className={style.label}>Ativo:</div>
                  <div className={style.adminSelect} onClick={() => dispatchUser({ type: "userActive", payload: !stateUser.userActive })}>{stateUser.userActive === true
                    ? <><FaCheckCircle className={style["active--true"]} /> ATIVO</>
                    : <><FaTimesCircle className={style["active--false"]} /> INATIVO</>
                  }</div>
                </div>

                <SelectArea label="Selecione a(s) área(s) do usuário" value={stateUser.teams} handleArea={(e: any) => dispatchUser({ type: "teams", payload: e })} />

                  </div>


                <div className={styleComponent.controls}>
                  <ButtonSystem action={(e: any) => cancel(e)} status={true} text="Cancelar" to="previous" icon="cancel" />
                  <ButtonSystem action={(e: any) => { sendInvite(e) }} status={buttonUpdate} text="Salvar" to="finish" icon="finish" />
                </div>




            </section>}
        </div>

      </div >
    </section >
  );
}