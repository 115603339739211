import { BrowserRouter as Router } from "react-router-dom";
import style from "styles/pages.module.scss";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userIdState, businessIdState, okrsState, userDataState, businessUsersState, businessInfoState, meetingsState } from "state/atom";
import Menu from "components/menu";
import UpdateFirebase from "config/update";
import { gettingOkrs } from "services/okrs/gettingOkrs";
import { getUsersByBusinessId } from "services/business/gettingBusinessUsers";
import { getUserData } from "services/user/gettingUserData";
import { getBusinessInfo } from "services/business/gettingBusinessInfo";
import { gettingBusinessIdAuth } from "services/business/gettingBusinessIdAuth";
import { gettingUserIdAuth } from "services/user/gettingUserIdAuth";
import { verifyTeamsCreated } from "services/teams/verifyTeamsCreated";
import RoutesComponent from "routes/routes";
import { ReactComponent as Loading } from "../loading.svg";
import { gettingMeetings } from "services/meetings/gettingMeetings";
import UpdateMeetings from "services/meetings/updateMeetings";

export default function App() {

  const userId = useRecoilValue(userIdState);
  const setUserId = useSetRecoilState(userIdState);
  const setUserData = useSetRecoilState(userDataState);
  const setBusinessId = useSetRecoilState(businessIdState);
  const setOkrs = useSetRecoilState(okrsState);
  const setMeetings = useSetRecoilState(meetingsState);
  const setBusinessUsers = useSetRecoilState(businessUsersState);
  const setBusinessInfo = useSetRecoilState(businessInfoState);
  const businessId = useRecoilValue(businessIdState);
  const userData = useRecoilValue(userDataState);

  const [isLoadingB, setIsLoadingB] = useState(true);
  const [isLoadingA, setIsLoadingA] = useState(true);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      // GETTING USER ID OF AUTH
      await gettingUserIdAuth(setUserId);

      // GETTING BUSINESS ID OF AUTH
      await gettingBusinessIdAuth(setBusinessId);

      setIsLoadingA(false);

    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // GETTING OKRS
      await gettingOkrs(businessId, setOkrs);
      // GETTING MEETINGS
      await gettingMeetings(businessId, setMeetings);
      // GETTING USERS FROM THE BUSINESS
      await getUsersByBusinessId(businessId, setBusinessUsers);
      // GETTING USER DATA
      await getUserData(businessId, userId, setUserData);
      // GETTING BUSINESS INFO
      await getBusinessInfo(businessId, setBusinessInfo);
      // VERIFY CREATED TEAMS
      await verifyTeamsCreated(businessId);

      setIsLoadingB(false); // Definir isLoading como false após obter os IDs
      setKey(key + 1);

    };

    if (businessId && userId) {
      fetchData();
    } else if (userId === undefined) {
      setIsLoadingB(false);
    }
  }, [businessId, userId]);

  if (isLoadingA || isLoadingB) {
    return <section className={style.sectionLoading}>
      <div className={style.divloading}>
        <Loading />
      </div>
    </section>
  }

  return (
    <>
      <Router>
        <>
          <UpdateFirebase /> <UpdateMeetings />
          <section className={style.section}>
            {userId !== null && userId !== undefined ? <header className={style.container__menu}><Menu key={key} /></header> : null}
            <div className={userId ? style.container__pagesWithMenu : style.container__pagesNoMenu}>
              <RoutesComponent key={key} userId={userId} userData={userData} />
            </div>
          </section>
        </>
      </Router>
    </>
  );
}