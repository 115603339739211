import styleComponent from "../../styles/components.module.scss";
import style from "./Response.module.scss";
import {FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import ButtonSystem from "components/forms/button";
import { useNavigate } from "react-router-dom";

type IResponse = {
  text: string,
  linkToGo: string,
  textForLink: string,
  success: boolean,
}


export default function ResponsePage(props: IResponse){

  const navigate = useNavigate();

  const goToLink = (e: any) => {
    navigate(props.linkToGo);
  }

  return (

    <section className={style.section}>

      <div className={style.container}>
        {props.success === true
        ? <FaCheckCircle className={style.item__success} />
        : <FaTimesCircle className={style.item__notSuccess} />}
      </div>

      <div className={style.printKr}>
        {props.text}
      </div>

      <div className={styleComponent.controls}>
        <ButtonSystem action={(e: any) => goToLink(e)} status={true} text={`${props.textForLink}`} to="next" icon="review"/>
      </div>

    </section>
  );
}