import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { businessIdState, userDataState, userIdState } from "state/atom";
import { db, storage } from "config/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import style from "./UserConfig.module.scss";
import SelectArea from "components/teams/selectArea";
import PhotoUser from "components/users/photoUser";
import { FaSave, FaUserEdit } from "react-icons/fa";
import { baseURL } from "baseURL";
import styleAdd from "../../../styles/addSometing.module.scss";
import { TextField } from "@mui/material";

export default function UserConfig() {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [teams, setTeams] = useState([]);
  const [photo, setPhoto] = useState();
  const [photoURL, setPhotoURL] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [update, setUpdate] = useState(false);

  // pega as informações do usuário do estado global
  const businessId = useRecoilValue(businessIdState);
  const userId = useRecoilValue(userIdState);
  const userData = useRecoilValue(userDataState);

  useEffect(() => {
    setUpdate(!update);
  }, [userData, name, role, teams, photo, photoURL, error, success]);


  useEffect(() => {
    if (userData) {
      setName(userData.name);
      setRole(userData.role);
      setTeams(userData.teams);
      setPhotoURL(userData.photoURL);
    }
  }, [userData]);

  const userRef = userId && businessId ? doc(db, "companies", businessId, "users", userId) : "";

  // Atualiza o nome do usuário no estado local
  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  // Atualiza o cargo do usuário no estado local
  const handleRoleChange = (event: any) => {
    setRole(event.target.value);
  };

  // Atualiza o time do usuário no estado local
  const handleTeamChange = (e: any) => {
    setTeams(e);
  };

  // Atualiza a foto do usuário no estado local
  const handlePhotoChange = (event: any) => {
    setPhoto(event);
  };

  // Atualiza as informações do usuário no Firestore
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {

      if (userRef !== "") {
        // Atualiza as informações do usuário no Firestore
        await updateDoc(userRef, {
          name: name,
          role: role,
          teams: teams
        });

        // Se o usuário selecionou uma nova foto, faz o upload dela para o Storage e atualiza a foto de perfil do usuário no Firestore
        if (photo) {
          const convertToFile = async (dataUrl: string) => {
            try {
              const blob = await fetch(dataUrl).then((r) => r.blob());
              const newFile = new File([blob], `${userId}.png`, { type: "image/png" });

              // Upload file to Firebase Storage
              const storageRef = ref(
                storage,
                `companies/${businessId}/users/${userId}/`
              );
              const snapshot = await uploadBytesResumable(storageRef, newFile);
              const photoURL = await getDownloadURL(snapshot.ref);
              await updateDoc(userRef, { photoURL });
            } catch (error) {
              console.error(error);
            }
          };
          convertToFile(photo);
        }

        setSuccess(true);
        setError(null);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };


  return (
    <section className={styleAdd.section}>

      <div className={styleAdd.view}>
        {userData?.photoURL ?
          <img src={userData?.photoURL} alt="usuário" className={style.userPhoto} /> :
          <img src={baseURL + "/assets/avatar.png"} alt="usuário" className={style.userPhoto} />}

        <div className={style.userName}>{userData.name}</div>
        <div className={style.userRole}>{userData.role}</div>
      </div>

      <div className={styleAdd.content}>
        <div className={style.component}>
          <h1 className={style.title}><FaUserEdit className={style.iconTitle} />Meu Perfil</h1>
          <form onSubmit={handleSubmit} className={style.form}>
            <div className={style.label}>
              Foto de perfil:
              <div className={style.contentPhotos}>
                <PhotoUser onSave={handlePhotoChange} userId={userId ? userId : "noUserId"} />
              </div>

            </div>

            <div className={style.label}>

              <TextField
                id="nome"
                label="Nome"
                value={name}
                onChange={handleNameChange}
                sx={{
                  width: '100%', '& label': {
                    minWidth: 200,
                  },
                }}
              />
            </div>
            <div className={style.label}>


              <TextField
                id="cargo"
                label="Cargo"
                value={role}
                onChange={handleRoleChange}
                sx={{
                  width: '100%', '& label': {
                    minWidth: 200,
                  },
                }}
              /></div>

            <div className={style.label}>

              {update ? <SelectArea label="Times" value={teams} handleArea={handleTeamChange} />
                : <SelectArea label="Times" value={teams} handleArea={handleTeamChange} />}


            </div>

            <div className={style.label}>
              <button className={style.button} type="submit">
                <FaSave className={style.icon} />Salvar
              </button>
            </div>
          </form>
          {error && <p>{error}</p>}
          {success && <p>As informações do usuário foram atualizadas com sucesso!</p>}
        </div>
      </div>
    </section>
  );
}