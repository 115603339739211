import style from "pages/meetings/singleMeeting/singleMeeting.module.scss";
import { TbTarget } from "react-icons/tb";
import MeetSingleKr from "../singleKr";
import { FaBatteryThreeQuarters, FaCodeBranch, FaCrosshairs, FaUsers } from "react-icons/fa";
import classNames from "classnames";
import GaugeChart from "components/charts/gaugeChart";
import BarProgress from "components/charts/barProgress";
import BarProgressWhite from "components/charts/barProgressWhite";
import { Teams } from "components/teams/showTeams";
import UpDown from "../upDown";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { today } from "components/date";
import BarProgressRoxo from "components/charts/barProgressRoxo";

interface SingleMeetingOkr {
  okr: any,
  compareMeeting: any,
  businessId: any,
  meetId: any,
  meetingNotes: any,
  status: any,
}


export default function MeetSingleOkr(props: SingleMeetingOkr) {

  const [chartKey, setChartKey] = useState(0)
  const [doneValue, setDoneValue] = useState(0);
  const [doneValueCompare, setDoneValueCompare] = useState(0);
  const [expectValue, setExpectValue] = useState(0);
  const [compare, setCompare] = useState();

  // // Getting all teams from okr
  const allTeams = props.okr.krs.flatMap((krs: any) => krs.teams);
  const uniqueTeamsSet = new Set(allTeams);
  const teamsFromKrs = Array.from(uniqueTeamsSet);


  // HANDLE DATA FROM OKRS

  const handleData = (data: any) => {

    if (data.length === 0) {
      return { totalFinal: 0, expectValueFinal: 0 };
    }

    let sumTotal = 0;
    let expectValue0 = 0;

    data.forEach((e: any) => {

      const checkpoints = e.checkpoints;
      const totalValue = e.finalValue;
      const initialValue = e.initialValue;
      const resultType = e.resultType;
      const initialDate = props.okr.initialDate;
      const finalDate = props.okr.finalDate;


      if (checkpoints.length > 0) {

        let latestCheckpoint = checkpoints.reduce((previousCheckpoint: any, currentCheckpoint: any) => {
          return previousCheckpoint.date > currentCheckpoint.date ? previousCheckpoint : currentCheckpoint;
        });

        const checkpointValue = parseFloat(`${latestCheckpoint.value}`);

        if (resultType === "Conquista") {

          const preDone = checkpointValue - initialValue;
          const preTotal = totalValue - initialValue;

          const percentageValue = ((100 / preTotal) * preDone);
          const percent = parseInt(`${percentageValue}`);
          sumTotal += percent;

        } else if (resultType === "Redução") {

          const preDone = initialValue - checkpointValue;
          const preTotal = initialValue - totalValue;

          const percentageValue = ((100 / preTotal) * preDone);
          const percent = parseInt(`${percentageValue}`);
          sumTotal += percent;

        } else if (resultType === "Manter") {

          if (checkpointValue < totalValue) {

            const preDone = checkpointValue;
            const preTotal = totalValue;

            const percentageValue = ((100 / preTotal) * preDone);
            const percent = parseInt(`${percentageValue}`);
            sumTotal += percent;

          } else if (checkpointValue > totalValue) {

            const preDone = checkpointValue - totalValue; // 110 - 100 = 10
            const preTotal = totalValue - preDone; // 100 - 10 = 90

            const percentageValue = ((100 / totalValue) * preTotal); // 100 / 100 * 90 = 90
            const percent = parseInt(`${percentageValue}`);
            sumTotal += percent;

          } else if (checkpointValue === totalValue) {

            const percentageValue = (100);
            const percent = parseInt(`${percentageValue}`);
            sumTotal += percent;

          }
        }

      }

      const initialDateDayjs = dayjs(initialDate);
      const finalDateDayjs = dayjs(finalDate);
      const hoje = new Date();

      const prazo = dayjs(finalDate).diff(dayjs(initialDate), 'day');
      const prazoRestante = dayjs(finalDate).diff(dayjs(hoje), 'day');
      const tempoDecorrido = prazo - prazoRestante;

      const valorPorDia = 100 / prazo;

      const valorAtual = valorPorDia * tempoDecorrido;
      const expectValue = parseInt(`${valorAtual}`);
      const expectValue2 = expectValue > 100 ? 100 : expectValue;

      expectValue0 += expectValue2;

    });

    const total = sumTotal / data.length;
    const totalPercent = total.toFixed(0);
    const totalFinal = parseInt(`${totalPercent}`);

    const expectValueFinalPercent = expectValue0 / data.length;
    const expectValueFinalPercent2 = expectValueFinalPercent.toFixed(0);
    const expectValueFinal = parseInt(`${expectValueFinalPercent2}`);


    return { totalFinal, expectValueFinal };

  }



  useEffect(() => {
    const krs = props.okr.krs;
    const data = handleData(krs);
    setExpectValue(data.expectValueFinal);
    setDoneValue(data.totalFinal);
    setChartKey(chartKey + 1)
  }, [props.okr.krs])

  useEffect(() => {
    if (props.compareMeeting === undefined) return;

    const compareMeeting = props.compareMeeting;
    const compareMeetingData = compareMeeting.okrs.find((okr: any) => okr.id === props.okr.id);

    if (compareMeetingData === undefined) return;
    const krs2 = compareMeetingData.krs && compareMeetingData.krs !== undefined ? compareMeetingData.krs : [];

    setCompare(krs2);

    const data3 = handleData(krs2);
    setDoneValueCompare(data3.totalFinal);
    setChartKey(chartKey + 1)
  }, [props.compareMeeting])

  return (
    <>
      <div className={style.container}>


        <div className={classNames({
          [style.container__left]: true,
          [style.background__roxo]: true,
        })}>

          <div className={classNames({
            [style.component__title]: true,
            [style.color__cinzaclaro]: true,
          })}>
            <FaCrosshairs className={style.title__subicon} />
            RESULTADO CHAVE
          </div>

          <div className={style.component__container}>

            <div className={classNames({
              [style.title__left]: true,
              [style.color__branco]: true,
            })}>
              {props.okr.okrName}
            </div>

          </div>

          <span className={style.hr} />

          <div className={classNames({
            [style.component__title]: true,
            [style.color__cinzaclaro]: true,
          })}>
            <FaUsers className={style.title__subicon} />
            RESPONSÁVEIS
          </div>

          <div className={style.component__container}>

            <div className={style.teams}>
              {teamsFromKrs.map((e: any, index: any) =>
                <Teams team={e} key={index} />
              )}
            </div>
          </div>


        </div>

        <div className={classNames({
          [style.container__center]: true,
          [style.background__roxo]: true,
        })}>
          <div className={classNames({
            [style.component__title]: true,
            [style.color__cinzaclaro]: true,
          })}>
            <FaBatteryThreeQuarters className={style.title__subicon} />
            DADOS
          </div>

          <div className={style.component__container}>
            <div className={style.chart}>
              <BarProgressWhite key={chartKey} finalValue={100} doneValue={doneValue} initialValue={0} resultType="Conquista" dataType="%" />
            </div>

            <span className={style.space} />


            <div className={style.comparative}>
              <div className={style.comparative__row}>
                <div className={classNames({
                  [style.comparative__row__col]: true,
                  [style.comparative__row__col__branco]: true,
                })}>
                  <div className={classNames({
                    [style.comparative__row__col__title]: true,
                    [style.color__branco]: true,
                  })}>PRODUZIDO</div>
                  <div className={classNames({
                    [style.comparative__row__col__value]: true,
                    [style.color__branco]: true,
                  })}>
                    {doneValue}%
                  </div>
                </div>
                <div className={classNames({
                  [style.comparative__row__col]: true,
                  [style.comparative__row__col__branco]: true,
                })}>
                  <div className={classNames({
                    [style.comparative__row__col__title]: true,
                    [style.color__branco]: true,
                  })}>EXPECTATIVA</div>
                  <div className={classNames({
                    [style.comparative__row__col__value]: true,
                    [style.color__branco]: true,
                  })}>
                    {expectValue}%
                  </div>
                </div>
                <div className={classNames({
                  [style.comparative__row__col]: true,
                  [style.comparative__row__col__branco]: true,
                })}>
                  <div className={classNames({
                    [style.comparative__row__col__title]: true,
                    [style.color__branco]: true,
                  })}>VARIAÇÃO</div>
                  <div className={classNames({
                    [style.comparative__row__col__value]: true,
                    [style.color__branco]: true,
                  })}>
                    <UpDown resultType="Conquista" dataType="number" initialValue={0} previousValue={expectValue} nowValue={doneValue} finalValue={100} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className={classNames({
          [style.container__right]: true,
          [style.background__cinza]: true,
        })}>
          {props.compareMeeting !== undefined ? <>

            <div className={classNames({
              [style.component__title]: true,
              [style.color__cinzaclaro]: true,
            })}>
              <FaBatteryThreeQuarters className={style.title__subicon} />
              DADOS - COMPARAÇÃO
            </div>

            <div className={style.component__container}>
              <div className={style.chart}>
                <BarProgressRoxo key={chartKey} finalValue={100} doneValue={doneValueCompare} initialValue={0} resultType="Conquista" dataType="%" />
              </div>

              <span className={style.space} />

              <div className={style.comparative}>
                <div className={style.comparative__row}>
                  <div className={classNames({
                    [style.comparative__row__col]: true,
                    [style.comparative__row__col__roxo]: true,
                  })}>
                    <div className={classNames({
                      [style.comparative__row__col__title]: true,
                      [style.color__roxo]: true,
                    })}>PRODUZIDO</div>
                    <div className={classNames({
                      [style.comparative__row__col__value]: true,
                      [style.color__roxo]: true,
                    })}>
                      {doneValueCompare}%
                    </div>
                  </div>
                  <div className={classNames({
                    [style.comparative__row__col]: true,
                    [style.comparative__row__col__roxo]: true,
                  })}>
                    <div className={classNames({
                      [style.comparative__row__col__title]: true,
                      [style.color__roxo]: true,
                    })}>VARIAÇÃO</div>
                    <div className={classNames({
                      [style.comparative__row__col__value]: true,
                      [style.color__roxo]: true,
                    })}>
                      <UpDown resultType="Conquista" dataType="number" initialValue={0} previousValue={doneValueCompare} nowValue={doneValue} finalValue={100} />
                    </div>
                  </div>
                </div>
              </div>
            </div></>
            : <>
              <div className={classNames({
                [style.component__title]: true,
                [style.color__cinzaclaro]: true,
              })}>
                <FaBatteryThreeQuarters className={style.title__subicon} />
                DADOS - COMPARAÇÃO
              </div>

              <div className={style.component__container}>
                <div className={style.semDados}>
                  SEM DADOS
                </div>
              </div>
            </>}
        </div>

      </div >

      {
        props.okr.krs.map((kr: any, index: any) =>
          <MeetSingleKr kr={kr} compareMeeting={compare} okr={props.okr.id} businessId={props.businessId} meetDate={props.meetId} meetingNotes={props.meetingNotes} key={index} status={props.status} compareNotes={props.compareMeeting} />
        )
      }
    </>
  )
}