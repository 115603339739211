import style from "styles/components.module.scss";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { dateToStart } from "components/date";
import { ValidateDate } from "components/date/validate";
import ButtonSystem from "components/forms/button";
import dayjs from "dayjs";
import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { businessIdState, meetingsState, updateMeetingState, updateState } from "state/atom";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { useNavigate } from "react-router-dom";

const today = dateToStart;


export default function AddMeet() {

  const listOfMeetings = useRecoilValue(meetingsState);
  const businessId = useRecoilValue(businessIdState);
  const updateMeeting = useRecoilValue(updateMeetingState);
  const setUpdateMeetingState = useSetRecoilState(updateMeetingState);

  const [meetingDate, setMeetingDate] = useState(today);
  const [messageDate, setMessageDate] = useState(false);
  const [messageExists, setMessageExists] = useState(false);

  const navigate = useNavigate();

  // CHANGE FINAL DATE

  const changeFinalDate = (e: any) => {
    const date = dayjs(e);
    const formattedDate = date.format("YYYY-MM-DD");
    setMeetingDate(formattedDate);
  }

  // VALIDATE FINAL DATE
  const validateFinalDate = (e: any) => {
    const date = dayjs(e, 'DD/MM/YYYY');
    const formattedDate = date.format("YYYY-MM-DD");
    const validate = ValidateDate(formattedDate);
    if (validate === true) {
      setMessageDate(false);
    } else {
      setMessageDate(true);
    }
  }


  // CREATE MEETing

  const submit = async () => {
    try {
      if (!businessId || !meetingDate) {
        // Verifique se businessId e meetingDate estão definidos
        console.error("businessId e meetingDate são obrigatórios.");
        return;
      }

      const meetingList = listOfMeetings;
      const meetingExists = meetingList.find((meeting) => meeting.date === meetingDate);

      if (!meetingExists) {
        // Se a reunião não existe, continue com a operação

        const meetingRef = doc(db, "companies", businessId, "meetings", "docmeetings");

        const meetingDocSnapshot = await getDoc(meetingRef);

        let meetingData: { meetings?: any[] } = {};

        if (meetingDocSnapshot.exists()) {
          // Se o documento de reunião existir, obtenha seus dados
          meetingData = meetingDocSnapshot.data();
        }
        if (!meetingData.meetings) {
          // Se a lista de reuniões não existir, crie-a com a nova reunião
          meetingData.meetings = [{ date: meetingDate, status: "open" }];
        } else {
          // Verifique se a reunião já existe para essa data
          const existingMeetingIndex = meetingData.meetings.findIndex(
            (meeting: any) => meeting.date === meetingDate
          );

          if (existingMeetingIndex !== -1) {
            // Se a reunião já existir para esta data, exiba uma mensagem de erro
            setMessageExists(true);
            return;
          }

          // Adicione a nova reunião à lista de reuniões
          meetingData.meetings.push({ date: meetingDate, status: "open" });

        }

        // Atualize o documento de reunião com as reuniões atualizadas ou adicionadas
        await setDoc(meetingRef, meetingData);

        // Atualize o estado, se necessário
        setUpdateMeetingState(!updateMeeting);

        navigate(`/reunioes/detalhes/${meetingDate}`)
        
      } else {
        // A reunião já existe para esta data, exiba uma mensagem de erro
        setMessageExists(true);
      }
    } catch (error) {
      console.error("Erro ao processar a operação:", error);
    }
  };

  return (
    <section className={style.section}>


      <div className={style.div__input}>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Data"
            format="DD/MM/YYYY"
            value={dayjs(meetingDate)}
            onChange={(e: any) => {
              changeFinalDate(e);
              validateFinalDate(e)
            }
            }
            sx={{
              width: '100%', '& label': {
                minWidth: 200,
              },
            }}
          />
        </LocalizationProvider>

      </div>


      <div className={style.space} />
      <div className={style.div__input}>

        {messageExists ? <div className={style.message}>Já existe uma reunião nesta data.</div> : ""}
      </div>


      <ButtonSystem action={() => submit()} status={!messageDate} text="Criar Reunião" to="finish" icon="finish" />

    </section>
  )
}