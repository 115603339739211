import { IOKRData } from "interface/allOkrs";
import { IUser } from "interface/user";
import { IBusinessInfo } from "interface/businessInfo";
import { atom } from "recoil";
import { ITeams } from "interface/teams";
import { IMeeting } from "interface/meeting";

export const userIdState = atom<string | null>({
  key: "userIdState",
  default: null,
});

export const businessIdState = atom<string | null>({
  key: 'businessIdState',
  default: null,
});

export const okrsState = atom<IOKRData>({
  key: "okrsState",
  default: [],
});

export const meetingsState = atom<IMeeting[]>({
  key: "meetingsState",
  default: [],
});

export const businessUsersState = atom<IUser[]>({
  key: "businessUsersState",
  default: [],
});

export const userDataState = atom<IUser>({
  key: "userDataState",
  default: {
    id: "",
    name: "",
    email: "",
    role: "",
    admin: false,
    teams: [],
    userActive: false,
    photoURL: ""
  }
});

export const updateState = atom({
  key: "updateState",
  default: false,
});

export const updateMeetingState = atom({
  key: "updateMeetingState",
  default: false,
});

export const updateNewUserState = atom({
  key: "updateNewUserState",
  default: false,
});

export const businessInfoState = atom<IBusinessInfo>({
  key: 'businessInfoState',
  default: {
    name: "",
    plan:  "",
    active:  false,
    cnpj:  "",
    photoURL: "",
    teams: []
  }
});