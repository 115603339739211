import { useRecoilValue, useSetRecoilState } from "recoil";
import { businessIdState, meetingsState, okrsState, updateState } from "state/atom";
import { db } from "config/firebase";
import { collection, doc, getDoc, getDocs, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { ITeam } from "interface/teams";


export default function UpdateFirebase(props: any) {

  const businessId = useRecoilValue(businessIdState);
  const setOkrs = useSetRecoilState(okrsState);
  const update = useRecoilValue(updateState);
  const setMeetings = useSetRecoilState(meetingsState);

  useEffect(() => {
    if (businessId) {
      const okrsRef = collection(db, "companies", businessId, "okrs");

      // subscribe to changes in the "okrs" collection
      const unsubscribe = onSnapshot(okrsRef, async () => {
        try {
          // fetch updated okrs data
          const okrsSnapshot = await getDocs(okrsRef);

          const okrsData = await Promise.all(
            okrsSnapshot.docs.map(async (okrDoc) => {

              const krs = okrDoc.data().krs === undefined || !okrDoc.data().krs ? [] : okrDoc.data().krs;

              return {
                id: okrDoc.id,
                okrName: okrDoc.data().okrName,
                initialDate: okrDoc.data().initialDate,
                finalDate: okrDoc.data().finalDate,
                krs: krs.map((kr: any) => ({
                  id: kr.id,
                  kr: kr.kr,
                  dataType: kr.dataType,
                  resultType: kr.resultType,
                  responsible: kr.responsible,
                  initialValue: kr.initialValue,
                  finalValue: kr.finalValue,
                  teams: kr.teams || [],
                  checkpoints: kr.checkpoints === undefined || !kr.checkpoints ? [] : kr.checkpoints,
                  tasks: kr.tasks === undefined || !kr.tasks ? [] : kr.tasks,
                  initialDate: kr.initialDate,
                  finalDate: kr.finalDate,
                })),
              };
            })
          );

          // update the recoil state with the new data
          setOkrs(okrsData);

        } catch (error) {
          console.error("Error fetching okrs: ", error);
        }
      });

      // unsubscribe when the component unmounts
      return () => unsubscribe();
    }
  }, [update]);


  // useEffect(() => {

  //   const fetchMeetings = async () => {
  //     try {
  //       if (businessId) {
  //         // Define a referência para a coleção de "meetings" da empresa
  //         const meetingsRef = collection(db, "companies", businessId, "meetings");
  
  //         // Busca o documento "meetings" dentro da coleção "meetings"
  //         const meetingsDocRef = doc(meetingsRef, "docmeetings");
  
  //         // Busca o campo "meetings" dentro do documento "meetings"
  //         const meetingsDocSnapshot = await getDoc(meetingsDocRef);
  
  //         if (meetingsDocSnapshot.exists()) {
  //           // Se o documento "meetings" existir, obtenha o campo "meetings" com as informações
  //           const meetingsArray = meetingsDocSnapshot.data().meetings || [];
  
  //           // Realize qualquer tratamento adicional que você deseja dentro do map
  //           const meetingsData = meetingsArray.map((meetingDoc: any) => {
  //             return {
  //               date: meetingDoc.date,
  //               status: meetingDoc.status,
  //               team: meetingDoc.team || [],
  //               okrs: meetingDoc.okrs || [],
  //               notes: meetingDoc.notes || [],
  //               result: meetingDoc.result || 0,
  //               closed: meetingDoc.closed || "",
  //             };
  //           });
    
  
  //           // Agora, meetingsData contém as informações tratadas da array "meetings" dentro do documento "meetings"
  //           setMeetings(meetingsData);
  //         } else {
  //           // Se o documento "meetings" não existir ou não tiver o campo "meetings", defina meetingsData como um array vazio
  //           setMeetings([]);
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Erro ao buscar as reuniões: ", error);
  //     }
  //   };
  
  //   // Chame a função de busca ao atualizar ou sempre que "businessId" mudar
  //   fetchMeetings();

  // }, [update])

  return (
    <></>
  );
}