import { Box, Modal } from "@mui/material";
import ButtonSystem from "components/forms/button";
import { useEffect, useState } from "react";
import style from "./filters.module.scss";
import styleComponents from "styles/components.module.scss";
import { FaCheck, FaFilter, FaTimes } from "react-icons/fa";
import SelectArea from "components/teams/selectArea";
import { useRecoilValue } from "recoil";
import { businessInfoState } from "state/atom";
import classNames from "classnames";
import { IKR, IOKR } from "interface/allOkrs";

interface IFilter {
  listOfOkrs: any,
  filteredOkrs: any,
}

export default function Filters(props: IFilter) {

  const [open, setOpen] = useState(false);
  const [teams, setTeams] = useState<any>([]);
  const [okrsFiltered, setOkrsFiltered] = useState([]);

  const businessInfo = useRecoilValue(businessInfoState);
  const listTeamsState = businessInfo.teams;

  useEffect(() => {
    const stateTeamsFiltered = listTeamsState.map((e: any) => e.id)
    setTeams(stateTeamsFiltered);
  }, []);

  useEffect(() => {

    if (teams.length === listTeamsState.length) {
      setOkrsFiltered(props.listOfOkrs);
    } else {

      const filteringOkrs = props.listOfOkrs.map((okr: IOKR) => {
        if (okr.krs !== undefined) {
          const filteredKRs = okr.krs.filter((kr: IKR) => {
            return teams.some((team: string) => kr.teams.includes(team));
          });

          if (filteredKRs.length > 0) {
            return { ...okr, krs: filteredKRs };
          }

          return null; // Retorna null quando nenhum time está selecionado
        }
      }).filter((okr: any) => okr !== null); // Filtra OKRs não nulos


      setOkrsFiltered(filteringOkrs);

    }

  }, [teams, props.listOfOkrs]);

  useEffect(() => {
    props.filteredOkrs(okrsFiltered);
  }, [okrsFiltered]);

  const handleClose = (e: any) => {
    setOpen(false);
  }

  const setSelectedTeams = (e: any) => {
    setTeams(e);
  }

  const removeAllTeams = () => {
    setTeams([]);
  }


  const addAllTeams = () => {
    const stateTeamsFiltered = listTeamsState.map((e: any) => e.id)
    setTeams(stateTeamsFiltered);
  }


  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 3,
    px: 3,
    pb: 3,
  };





  return (
    <>
      <ButtonSystem action={(e: any) => setOpen(!open)} status={true} text="Filtros" to="finish" icon="filters" />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...modalStyle }}>
          <div className={style.container}>
            <FaTimes className={style.close} onClick={handleClose} />
            <div className={style.title}>
              <FaFilter className={style.icon} />  Filtrar Times
            </div>
            <div className={style.clear}>

              <button type="button" className={classNames({
                [styleComponents.singleArea]: true,
                [styleComponents["singleArea--notSelected"]]: true,
              })} onClick={addAllTeams}>
                <FaCheck className={style.icon} />
                <div >Selecionar todos</div>
              </button>

              <button type="button" className={classNames({
                [styleComponents.singleArea]: true,
                [styleComponents["singleArea--notSelected"]]: true,
              })} onClick={removeAllTeams}>
                <FaTimes className={style.icon} />
                <div >Limpar Seleção</div>
              </button>



            </div>
            <div className={style.teams}>
              {teams ? <SelectArea label="Selecione os times para ver os resultados esperados" value={teams} handleArea={setSelectedTeams} /> : ""}
            </div>
          </div>


        </Box>
      </Modal>

    </>
  )
}