import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { formatNumber } from 'components/date/formatNumber';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import style from "./ProgressBar.module.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type IProgressBar = {
  finalValue: number,
  doneValue: number,
  dataType: string,
  resultType: string,
  initialValue: number,
}

export default function BarProgress(props: IProgressBar) {

  const [showTitle, setShowTitle] = useState("");
  const [doneValue, setDoneValue] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [missingValue, setMissingValue] = useState(0);

  useEffect(() => {

    const beforeDone = formatNumber(Number(props.doneValue));
    const beforeTotal = formatNumber(Number(props.finalValue));

    if (props.resultType === "Conquista") {

      if (props.dataType === "R$") {
        const preShow = `R$ ${beforeDone} de R$ ${beforeTotal}`;
        setShowTitle(preShow);
      } else if (props.dataType === "%") {
        const preShow = `${beforeDone} de ${beforeTotal}%`;
        setShowTitle(preShow);
      } else {
        const preShow = `${beforeDone} de ${beforeTotal}`;
        setShowTitle(preShow);
      }

      const preDone = props.doneValue - props.initialValue;
      const preTotal = props.finalValue - props.initialValue;

      setDoneValue(preDone);
      setTotalValue(preTotal);

      if (preDone < preTotal) {
        setMissingValue(preTotal - preDone);
      } else {
        setMissingValue(0);
      }

    } else if (props.resultType === "Redução") {

      if (props.dataType === "R$") {
        const preShow = `R$ ${beforeDone} - Meta: R$ ${beforeTotal}`;
        setShowTitle(preShow);
      } else if (props.dataType === "%") {
        const preShow = `${beforeDone}% - Meta: ${beforeTotal}%`;
        setShowTitle(preShow);
      } else {
        const preShow = `${beforeDone} - Meta: ${beforeTotal}`;
        setShowTitle(preShow);
      }

      const preDone = props.initialValue - props.doneValue; // 30 - 29 = 1
      const preTotal = props.initialValue - props.finalValue; // 30 - 2 = 28

      setDoneValue(preDone);
      setTotalValue(preTotal);

      if (preDone < preTotal) {
        setMissingValue(preTotal - preDone);
      } else {
        setMissingValue(0);
      }

    } else if (props.resultType === "Manter") {

      if (props.dataType === "R$") {
        const preShow = `R$ ${beforeDone} - Meta: R$ ${beforeTotal}`;
        setShowTitle(preShow);
      } else if (props.dataType === "%") {
        const preShow = `${beforeDone}% - Meta: ${beforeTotal}%`;
        setShowTitle(preShow);
      } else {
        const preShow = `${beforeDone} - Meta: ${beforeTotal}`;
        setShowTitle(preShow);
      }

      if (props.doneValue < props.finalValue) {

        setDoneValue(props.doneValue); // 98
        setTotalValue(props.finalValue); // 100
        setMissingValue(props.finalValue - props.doneValue); // 2

        // 98 + 2 = 100

      } else if (props.doneValue > props.finalValue) {

        const preDone = props.doneValue - props.finalValue; // 130 - 110 = 20
        const done = props.finalValue - preDone; // 110 - 20 = 90

        setDoneValue(done);
        setTotalValue(props.finalValue);
        setMissingValue(props.finalValue - done); // 110 - 90 = 20

      } else if (props.doneValue === props.finalValue) {
        setDoneValue(props.doneValue);
        setTotalValue(props.finalValue);
        setMissingValue(0);
      }
    }

  }, [props])


  const data = {
    labels: ["Concluído", "Em aberto"],
    datasets: [{
      label: 'Concluído',
      data: [doneValue],
      backgroundColor: (context: any) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          return null
        }
        if (context.dataIndex === 0) {
          return getGradient(chart);
        } else {
          return "rgba(241,241,241,1)"
        }
      },
    },
    {
      label: 'Em Aberto',
      data: [missingValue],
      backgroundColor: "rgba(241,241,241,1)",
    }
    ]
  }

  function getGradient(chart: any) {
    const { ctx, chartArea: { top, bottom, left, right } } = chart;
    const gradientSegment = ctx.createLinearGradient(left, 0, right, 0);
    gradientSegment.addColorStop(0.1, "rgba(117,81,217,1)");
    gradientSegment.addColorStop(0.5, "rgba(98,176,211,1)");
    gradientSegment.addColorStop(0.9, "rgba(36,255,143,1)");
    return gradientSegment;
  }

  const options = {
    indexAxis: 'y' as const,
    layout: {
      padding: 0,
    },
    elements: {
      bar: {
        borderWidth: 1,
        borderSkipped: false,
        borderRadius: 5,
        // barPercentage: 1
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        display: false,
        stacked: true,
        offset: false,
        grid: {
          display: false,
          drawBorder: false,
          drawTicks: false,
          tickLength: 0,
        },
        ticks: {
          display: false,
          padding: 0,
          backdropPadding: 0,
          beginAtZero: true,
          max: 1,
        }
      },
      x: {
        beginAtZero: true,
        display: false,
        stacked: true,
        offset: false,
        grid: {
          display: false,
          drawBorder: false,
          drawTicks: false,
          tickLength: 0,
        },
        ticks: {
          display: false,
          padding: 0,
          backdropPadding: 0,
          beginAtZero: true,
          max: 1,
        }
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: [showTitle],
        font: {
          size: 15,
          family: "'Blinker', sans-serif",
          weight: "normal",
        }
      },
      tooltip: {
        enabled: false,
      },
    },
    maintainAspectRatio: false,
  };




  return (<div className={style.container}>
    {/* <div className={style.title}>{showTitle}</div> */}
    <Bar options={options} data={data} className={style.progressBar} />
  </div>
  );
}