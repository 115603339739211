import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { businessUsersState } from "state/atom";
import style from "./show.module.scss";
import { baseURL } from "baseURL";

type IUserValid = {
  users: string[],
}

export default function ShowUsers(props: IUserValid) {

  // DATA TO USE
  const [selectedUsers, setSelectedUsers] = useState<string[]>(props.users);
  const businessUsers = useRecoilValue(businessUsersState);

  // GET FROM PROPS
  useEffect(() => {
    setSelectedUsers(props.users);
  }, [props.users]);

  // FILTER BUSINESS USERS
  const filteredUsers = businessUsers.filter(user => selectedUsers.includes(user.id));


  return (
    <div className={style.content}>
      {filteredUsers.map((user: any) => (
        <div key={user.id} className={style.user}>
          <div className={style.userPhotoWrapper}>
            {!user.photoURL || user.photoURL === ""
              ? (<img src={baseURL + "/assets/avatar.png"} alt={user.name} className={style.userPhoto} title={user.name} />)
              : (<img src={user.photoURL} alt={user.name} className={style.userPhoto} title={user.name} />)}
          </div>
        </div>
      ))}
    </div>
  );
}