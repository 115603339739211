import { db } from "config/firebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";

export async function gettingMeetings(businessId: any, setMeetings: any) {
  try {
    if (businessId) {
      // Define a referência para a coleção de "meetings" da empresa
      const meetingsRef = collection(db, "companies", businessId, "meetings");

      // Busca o documento "meetings" dentro da coleção "meetings"
      const meetingsDocRef = doc(meetingsRef, "docmeetings");

      // Busca o campo "meetings" dentro do documento "meetings"
      const meetingsDocSnapshot = await getDoc(meetingsDocRef);

      if (meetingsDocSnapshot.exists()) {
        // Se o documento "meetings" existir, obtenha o campo "meetings" com as informações
        const meetingsArray = meetingsDocSnapshot.data().meetings || [];

        // Realize qualquer tratamento adicional que você deseja dentro do map
        const meetingsData = meetingsArray.map((meetingDoc: any) => {
          return {
            date: meetingDoc.date,
            status: meetingDoc.status,
            team: meetingDoc.team || [],
            okrs: meetingDoc.okrs || [],
            notes: meetingDoc.notes || [],
            result: meetingDoc.result || 0,
            closed: meetingDoc.closed || "",
          };
        });

        // Agora, meetingsData contém as informações tratadas da array "meetings" dentro do documento "meetings"
        setMeetings(meetingsData);
      } else {
        // Se o documento "meetings" não existir ou não tiver o campo "meetings", defina meetingsData como um array vazio
        setMeetings([]);
      }
    }
  } catch (error) {
    console.error("Erro ao buscar as reuniões: ", error);
  }
}