import React, { useState, useRef, useEffect } from 'react';
import AvatarEditor from 'react-avatar-editor';

interface Props {
  onSave: any;
  userId: string;
}

const PhotoUser: React.FC<Props> = ({ onSave, userId }) => {
  const [image, setImage] = useState<File | null>(null);
  const editorRef = useRef<AvatarEditor | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");
  const [change, setChange] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      if (editorRef.current) {

        const imageUrl = editorRef.current.getImageScaledToCanvas().toDataURL();
        onSave(imageUrl);
      }
    }, 2000); // espera 2 segundos antes de enviar

  }, [change]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage("A imagem selecionada é maior do que 5MB. Por favor, escolha uma imagem menor.");
      } else {
        setErrorMessage("");
        const imageUrl = URL.createObjectURL(file);
        setImageUrl(imageUrl);
        setImage(file);
      }
      setChange(!change);
    }
  };

  return (
    <div>
      {!image && (<input type="file" accept="image/*" onChange={handleImageChange} />)}
      {errorMessage && <p>{errorMessage}</p>}
      {image && imageUrl && (
        <AvatarEditor
          ref={editorRef}
          image={imageUrl}
          width={200}
          height={200}
          border={0}
          borderRadius={200}
          color={[255, 255, 255, 0.8]} // cor de fundo transparente
          scale={1}
          onPositionChange={() => setChange(!change)}
        />
      )}
    </div>
  );
};

export default PhotoUser;