import classNames from "classnames";
import BarProgress from "components/charts/barProgress";
import { formatNumber } from "components/date/formatNumber";
import { Teams } from "components/teams/showTeams";
import ShowUsers from "components/users/show";
import { CheckpointData } from "interface/checkpoints";
import style from "pages/meetings/singleMeeting/singleMeeting.module.scss";
import { useEffect, useState } from "react";
import { TbTarget } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import UpDown from "../upDown";
import { TextField } from "@mui/material";
import ButtonSystem from "components/forms/button";
import ButtonSystemNoText from "components/forms/button/buttonNoText";
import { FaBatteryThreeQuarters, FaCalendar, FaCrosshairs, FaExternalLinkAlt, FaFileSignature, FaFlagCheckered, FaHourglassHalf, FaKey, FaSpinner, FaUsers } from "react-icons/fa";
import BarProgress2 from "components/charts/barProgress2";
import { db } from "config/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import dayjs from "dayjs";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { updateMeetingState } from "state/atom";
import BarProgressRoxo from "components/charts/barProgressRoxo";

interface SingleMeetingKr {
  kr: any,
  compareMeeting: any,
  okr: any,
  meetDate: any,
  businessId: any,
  meetingNotes: any,
  status: any,
  compareNotes: any,
}

export default function MeetSingleKr(props: SingleMeetingKr) {

  const setMeetingUpdate = useSetRecoilState(updateMeetingState);
  const updateMeeting = useRecoilValue(updateMeetingState);

  const [doneValue, setDoneValue] = useState(0);
  const [doneValueCompare, setDoneValueCompare] = useState(0);
  const [expectValue, setExpectValue] = useState(0);
  const [chartKey, setChartKey] = useState(0);
  const [lastCheckpoint, setLastCheckpoint] = useState<CheckpointData | undefined>(undefined);
  const [lastCheckpointCompare, setLastCheckpointCompare] = useState<CheckpointData | undefined>(undefined);
  const [review, setReview] = useState(false);
  const [meetingNote, setMeetingNote] = useState<any>(undefined);
  const [compareNote, setCompareNote] = useState<any>(undefined);

  const [anotacao, setAnotacao] = useState("");

  const checkpoints = props.kr.checkpoints;
  const finalValue = props.kr.finalValue;
  const initialValue = props.kr.initialValue;
  const resultType = props.kr.resultType;
  const dataType = props.kr.dataType;
  const initialDate = props.kr.initialDate;
  const finalDate = props.kr.finalDate;


  const navigate = useNavigate();

  const goTo = () => {
    navigate(`/kr/${props.okr}/${props.kr.id}`)
  };

  useEffect(() => {

    const hoje = new Date();
    const prazo = dayjs(finalDate).diff(dayjs(initialDate), 'day');
    const prazoRestante = dayjs(finalDate).diff(dayjs(hoje), 'day');
    const tempoDecorrido = prazo - (prazoRestante < 0 ? 0 : prazoRestante);

    if (resultType === "Conquista") {

      const valorPorDia = (finalValue - initialValue) / prazo; //  (100 - 10) / 3 = 30
      const valorAtual = (valorPorDia * tempoDecorrido) + initialValue; // (30 * 2) + 10 = 70
      const valorAtualSemVirgula = parseInt(`${valorAtual}`);
      setExpectValue(valorAtualSemVirgula);

    } else if (resultType === "Redução") {

      const valorPorDia = (initialValue - finalValue) / prazo; // (100 - 50) / 4 = 12.5
      const valorAtual = initialValue - (valorPorDia * tempoDecorrido); // 100 - (12.5 * 2) = 75
      const valorAtualSemVirgula = parseInt(`${valorAtual}`);
      setExpectValue(valorAtualSemVirgula);

    }


    if (checkpoints.length > 0) {


      let latestCheckpoint = checkpoints.reduce((previousCheckpoint: any, currentCheckpoint: any) => {
        return previousCheckpoint.date > currentCheckpoint.date ? previousCheckpoint : currentCheckpoint;
      });

      const checkpointValue = parseFloat(`${latestCheckpoint.value}`);

      setDoneValue(checkpointValue);
      setLastCheckpoint(latestCheckpoint);
      setChartKey(chartKey + 1);


    } else {
      setDoneValue(initialValue);
      setChartKey(chartKey + 1);
    }

    if (props.meetingNotes !== undefined) {

      const meetingNote2 = props.meetingNotes.find((e: any) => e.kr === props.kr.id && e.okr === props.okr);
      setMeetingNote(meetingNote2);
    }
  }, [props]);



  // COMPARATIVE

  useEffect(() => {

    if (props.compareMeeting === undefined) return;

    const compareMeeting = props.compareMeeting;
    const compareMeetingData = compareMeeting.find((kr: any) => kr.id === props.kr.id);
    const checkp = compareMeetingData.checkpoints;

    console.log("aqui", checkp);


    if (checkp.length > 0) {


      let latestCheckpoint2 = checkp.reduce((previousCheckpoint: any, currentCheckpoint: any) => {
        return previousCheckpoint.date > currentCheckpoint.date ? previousCheckpoint : currentCheckpoint;
      });

      const checkpointValue2 = parseFloat(`${latestCheckpoint2.value}`);

      setDoneValueCompare(checkpointValue2);
      setLastCheckpointCompare(latestCheckpoint2);
      setChartKey(chartKey + 1);


    } else {
      setDoneValueCompare(initialValue);
      setChartKey(chartKey + 1);
    }

    if (props.compareNotes !== undefined && props.compareNotes.notes !== undefined) {
      const compareNote2 = props.compareNotes.notes.find((e: any) => e.kr === props.kr.id && e.okr === props.okr);
      setCompareNote(compareNote2);
    }
  }, [props]);

  const teamsSort = [...props.kr.teams].sort((a: any, b: any) => a.localeCompare(b));


  // CREATE NOTE

  const submit = async () => {

    try {
      if (!props.businessId || !props.meetDate || !props.okr || !props.kr.id) {
        // Verifique se businessId, meetDate, okr e kr.id estão definidos
        console.error("businessId, meetDate, okr e kr.id são obrigatórios.");
        return;
      }

      const meetingRef = doc(db, "companies", props.businessId, "meetings", "docmeetings");

      // Recupere o documento da reunião atual
      const meetingDocSnapshot = await getDoc(meetingRef);
      const meetingData = meetingDocSnapshot.data();

      if (meetingData) {
        const targetMeeting = meetingData.meetings.find((meeting: any) => meeting.date === props.meetDate);

        if (targetMeeting) {
          // Crie a nota com as informações de OKR e KR
          const note = {
            okr: props.okr,
            kr: props.kr.id,
            note: anotacao,
          };

          // Verifique e crie o array de 'notes' se ainda não existir
          if (!targetMeeting.notes) {
            targetMeeting.notes = [];
          }

          // Verifique se já existe uma nota com as mesmas IDs de OKR e KR
          const existingNoteIndex = targetMeeting.notes.findIndex(
            (existingNote: any) => existingNote.okr === props.okr && existingNote.kr === props.kr.id
          );

          if (existingNoteIndex !== -1) {
            // Se já existe uma nota com as mesmas IDs, substitua-a
            targetMeeting.notes[existingNoteIndex] = note;
          } else {
            // Caso contrário, adicione a nova nota ao array de 'notes'
            targetMeeting.notes.push(note);
          }

          // Atualize o documento da reunião com os dados atualizados
          await updateDoc(meetingRef, meetingData);

          console.log("Nota adicionada com sucesso!");

          setMeetingUpdate(!updateMeeting);

          setAnotacao("");
        } else {
          console.error("O documento da reunião não existe 2.");
        }
      } else {
        console.error("O documento da reunião não existe.");
      }
    } catch (error) {
      console.error("Erro ao processar a operação:", error);
    }
  };

  // console.log(lastCheckpointCompare);




  return (
    <>
      <div className={classNames({
        [style.container]: true,
      })}>


        <div className={classNames({
          [style.container__left]: true,
          [style.background__degrade]: true,
        })}>

          <div className={classNames({
            [style.component__title]: true,
            [style.color__cinzaclaro]: true,
          })}>
            <FaKey className={style.title__subicon} />
            RESULTADO CHAVE
          </div>

          <div className={style.component__container}>



            <div className={classNames({
              [style.title__left]: true,
              [style.color__verde]: true,
            })}>
              {props.kr.kr} <FaExternalLinkAlt onClick={goTo} className={style.icon__link} />
            </div>

          </div>

          <span className={style.hr} />

          <div className={classNames({
            [style.component__title]: true,
            [style.color__cinzaclaro]: true,
          })}>
            <FaUsers className={style.title__subicon} />
            RESPONSÁVEIS:
          </div>

          <div className={style.component__container}>

            <div className={style.teams}>
              <div className={style.users}>
                <ShowUsers users={props.kr.responsible} />
              </div></div>
            <div className={style.teams}>
              {teamsSort.map((e: any, index: any) =>
                <Teams team={e} key={index} />
              )}
            </div>

          </div>


          <span className={style.hr} />

          <div className={classNames({
            [style.component__title]: true,
            [style.color__cinzaclaro]: true,
          })}>
            <FaHourglassHalf className={style.title__subicon} />
            PRAZO
          </div>

          <div className={style.component__container}>

            <div className={style.prazo}>

              <div className={style.prazo__row}>
                <div className={style.prazo__row__col}>
                  <div className={style.prazo__row__col__value}>
                    {initialDate.split("-").reverse().join("/")}
                  </div>
                </div>
                <div className={style.prazo__row__col}>
                  <div className={style.prazo__row__col__value}>
                    <FaCalendar className={style.icon__calendar} />
                    {finalDate.split("-").reverse().join("/")}
                  </div>
                </div>
                <div className={style.prazo__row__col}>
                  <div className={style.prazo__row__col__value}>
                    {dayjs(finalDate).diff(dayjs(), 'day') < 0 ? 0 : dayjs(finalDate).diff(dayjs(), 'day')} dias
                  </div>
                </div>
              </div>

            </div>

          </div>


        </div>

        {/* KR - RESULTADO ATUAL */}

        <div className={classNames({
          [style.container__center]: true,
          [style.background__branco]: true,
        })}>



          {/* <div className={classNames({
            [style.containerKR__center]: true,
          })}> */}

          <div className={classNames({
            [style.component__title]: true,
            [style.color__roxo]: true,
          })}>
            <FaBatteryThreeQuarters className={style.title__subicon} />
            DADOS
          </div>

          <div className={style.component__container}>


            <div className={style.chart}>
              <BarProgress key={chartKey} finalValue={finalValue} doneValue={doneValue} dataType={dataType} resultType={resultType} initialValue={initialValue} />
            </div>

            <span className={style.space} />


            <div className={style.comparative}>

              <div className={style.comparative}>
                <div className={style.comparative__row}>
                  <div className={classNames({
                    [style.comparative__row__col]: true,
                    [style.comparative__row__col__roxo]: true,
                  })}>
                    <div className={classNames({
                      [style.comparative__row__col__title]: true,
                      [style.color__roxo]: true,
                    })}>PRODUZIDO</div>
                    <div className={classNames({
                      [style.comparative__row__col__value]: true,
                      [style.color__roxo]: true,
                    })}>
                      {dataType === "R$" ? "R$ " : ""}{doneValue}{dataType === "%" ? "%" : ""}
                    </div>
                  </div>
                  <div className={classNames({
                    [style.comparative__row__col]: true,
                    [style.comparative__row__col__roxo]: true,
                  })}>
                    <div className={classNames({
                      [style.comparative__row__col__title]: true,
                      [style.color__roxo]: true,
                    })}>EXPECTATIVA</div>
                    <div className={classNames({
                      [style.comparative__row__col__value]: true,
                      [style.color__roxo]: true,
                    })}>
                      {dataType === "R$" ? "R$ " : ""}{expectValue}{dataType === "%" ? "%" : ""}
                    </div>
                  </div>
                  <div className={classNames({
                    [style.comparative__row__col]: true,
                    [style.comparative__row__col__roxo]: true,
                  })}>
                    <div className={classNames({
                      [style.comparative__row__col__title]: true,
                      [style.color__roxo]: true,
                    })}>VARIAÇÃO</div>
                    <div className={classNames({
                      [style.comparative__row__col__value]: true,
                      [style.color__roxo]: true,
                    })}>
                      <UpDown resultType={resultType} dataType={dataType} initialValue={initialValue} previousValue={expectValue} nowValue={doneValue} finalValue={finalValue} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <span className={style.hr} />

          <div className={classNames({
            [style.component__title]: true,
            [style.color__cinzaclaro]: true,
          })}>
            <FaFlagCheckered className={style.title__subicon} />
            ÚLTIMO CHECKPOINT
          </div>

          <div className={style.component__container}>

            {lastCheckpoint !== undefined ?

              <div className={style.comparative}>
                <div className={style.comparative__row}>
                  <div className={classNames({
                    [style.comparative__row__col]: true,
                    [style.comparative__row__col__cinza]: true,
                  })}>
                    <div className={classNames({
                      [style.comparative__row__col__title]: true,
                      [style.color__cinza]: true,
                    })}>
                      VALOR
                    </div>
                    <div className={classNames({
                      [style.comparative__row__col__value]: true,
                      [style.color__cinza]: true,
                    })}>
                      {formatNumber(Number(lastCheckpoint.value))}
                    </div>
                  </div>
                  <div className={classNames({
                    [style.comparative__row__col]: true,
                    [style.comparative__row__col__cinza]: true,
                  })}>
                    <div className={classNames({
                      [style.comparative__row__col__title]: true,
                      [style.color__cinza]: true,
                    })}>
                      RESPONSÁVEL
                    </div>
                    <div className={classNames({
                      [style.comparative__row__col__value]: true,
                      [style.color__cinza]: true,
                    })}>
                      <ShowUsers users={lastCheckpoint.responsible} />
                    </div>
                  </div>
                  <div className={classNames({
                    [style.comparative__row__col]: true,
                    [style.comparative__row__col__cinza]: true,
                  })}>
                    <div className={classNames({
                      [style.comparative__row__col__title]: true,
                      [style.color__cinza]: true,
                    })}>
                      DATA
                    </div>
                    <div className={classNames({
                      [style.comparative__row__col__value]: true,
                      [style.color__cinza]: true,
                    })}>
                      {lastCheckpoint.date.split("-").reverse().join("/")}
                    </div>
                  </div>
                </div>

                

                <div className={style.checkpoints}>
                <span className={style.space} />
                  <div className={classNames({
                    [style.comparative__row__col__title]: true,
                    [style.color__cinza]: true,
                  })}>
                    OBSERVAÇÃO
                  </div>
                  <div className={classNames({
                    [style.updateObs]: true,
                    [style.color__cinza]: true,
                  })}>
                    {lastCheckpoint.obs ? lastCheckpoint.obs : "-"}
                  </div>
                </div>
              </div>

              : <div className={style.checkpoints}>
                <div className={style.semDados}>
                  NENHUMA ATUALIZAÇÃO REALIZADA
                </div>
              </div>}

          </div>

          <span className={style.hr} />


          <div className={classNames({
            [style.component__title]: true,
            [style.color__cinzaclaro]: true,
          })}>
            <FaFileSignature className={style.title__subicon} />
            ANOTAÇÕES DA REUNIÂO
          </div>

          <div className={style.component__container}>

            {meetingNote !== undefined && meetingNote.note ?

              <div className={style.checkpoints}>
                <div className={style.updateObs}>
                  {meetingNote.note}
                </div>
              </div>

              : <div className={style.checkpoints}>
                <div className={style.semDados}>
                  SEM ANOTAÇÕES
                </div>
              </div>}



            {props.status === "closed" ? "" : <>


              <div className={style.notes}>

                <TextField
                  label="Anotação"
                  value={anotacao}
                  onChange={(e: any) => setAnotacao(e.target.value)}
                  sx={{
                    width: '100%', '& label': {
                      minWidth: 200,
                    },
                    mb: 2,
                  }}
                  fullWidth
                />

                <ButtonSystem text={meetingNote !== undefined && meetingNote.note ? "Substituir Anotação" : "Adicionar Anotação"} action={() => submit()} status={anotacao !== ""} to="next" icon={meetingNote !== undefined && meetingNote.note ? "update" : "add"} />
              </div>
            </>}
          </div>

        </div>

        <div className={classNames({
          [style.container__right]: true,
          [style.background__cinza]: true,
        })}>


          {props.compareMeeting !== undefined ? <>

            <div className={classNames({
              [style.component__title]: true,
              [style.color__cinzaclaro]: true,
            })}>
              <FaBatteryThreeQuarters className={style.title__subicon} />
              DADOS - COMPARAÇÃO
            </div>

            <div className={style.component__container}>

              <div className={style.chart}>
                <BarProgress key={chartKey} finalValue={finalValue} doneValue={doneValueCompare} dataType={dataType} resultType={resultType} initialValue={initialValue} />
              </div>

              <span className={style.space} />

              <div className={style.comparative}>

                <div className={style.comparative}>
                  <div className={style.comparative__row}>
                    <div className={classNames({
                      [style.comparative__row__col]: true,
                      [style.comparative__row__col__roxo]: true,
                    })}>
                      <div className={classNames({
                        [style.comparative__row__col__title]: true,
                        [style.color__roxo]: true,
                      })}>PRODUZIDO</div>
                      <div className={classNames({
                        [style.comparative__row__col__value]: true,
                        [style.color__roxo]: true,
                      })}>
                        {doneValueCompare}%
                      </div>
                    </div>
                    <div className={classNames({
                      [style.comparative__row__col]: true,
                      [style.comparative__row__col__roxo]: true,
                    })}>
                      <div className={classNames({
                        [style.comparative__row__col__title]: true,
                        [style.color__roxo]: true,
                      })}>VARIAÇÃO</div>
                      <div className={classNames({
                        [style.comparative__row__col__value]: true,
                        [style.color__roxo]: true,
                      })}>
                        <UpDown resultType={resultType} dataType={dataType} initialValue={initialValue} previousValue={doneValueCompare} nowValue={doneValue} finalValue={finalValue} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <span className={style.hr} />

            <div className={classNames({
              [style.component__title]: true,
              [style.color__cinzaclaro]: true,
            })}>
              <FaFlagCheckered className={style.title__subicon} />
              ÚLTIMO CHECKPOINT - COMPARAÇÃO
            </div>

            <div className={style.component__container}>

              {lastCheckpointCompare !== undefined ?

                <div className={style.comparative}>
                  <div className={style.comparative__row}>
                    <div className={classNames({
                      [style.comparative__row__col]: true,
                      [style.comparative__row__col__cinza]: true,
                    })}>
                      <div className={classNames({
                        [style.comparative__row__col__title]: true,
                        [style.color__cinza]: true,
                      })}>
                        VALOR
                      </div>
                      <div className={classNames({
                        [style.comparative__row__col__value]: true,
                        [style.color__cinza]: true,
                      })}>
                        {formatNumber(Number(lastCheckpointCompare.value))}
                      </div>
                    </div>
                    <div className={classNames({
                      [style.comparative__row__col]: true,
                      [style.comparative__row__col__cinza]: true,
                    })}>
                      <div className={classNames({
                        [style.comparative__row__col__title]: true,
                        [style.color__cinza]: true,
                      })}>
                        RESPONSÁVEL
                      </div>
                      <div className={classNames({
                        [style.comparative__row__col__value]: true,
                        [style.color__cinza]: true,
                      })}>
                        <ShowUsers users={lastCheckpointCompare.responsible} />
                      </div>
                    </div>
                  </div>

                  <div className={style.checkpoints}>
                <span className={style.space} />
                    <div className={classNames({
                      [style.comparative__row__col__title]: true,
                      [style.color__cinza]: true,
                    })}>
                      OBSERVAÇÃO
                    </div>
                    <div className={classNames({
                      [style.updateObs]: true,
                      [style.color__cinza]: true,
                    })}>
                      {lastCheckpointCompare.obs ? lastCheckpointCompare.obs : "-"}
                    </div>
                  </div>
                </div>

                : <div className={style.checkpoints}>
                  <div className={style.semDados}>
                    SEM ATUALIZAÇÕES
                  </div>
                </div>}

            </div>

            <span className={style.hr} />



            <div className={classNames({
              [style.component__title]: true,
              [style.color__cinzaclaro]: true,
            })}>
              <FaFileSignature className={style.title__subicon} />
              ANOTAÇÕES - COMPARAÇÃO
            </div>

            <div className={style.component__container}>



              {compareNote !== undefined && compareNote.note ?

                <div className={style.checkpoints}>
                  <div className={style.updateObs}>
                    {compareNote.note}
                  </div>
                </div>

                : <div className={style.checkpoints}>
                  <div className={style.semDados}>
                    SEM ANOTAÇÕES
                  </div>
                </div>}

            </div>




          </>
            : <>

              <div className={classNames({
                [style.component__title]: true,
                [style.color__cinzaclaro]: true,
              })}>
                <FaBatteryThreeQuarters className={style.title__subicon} />
                DADOS - COMPARAÇÃO
              </div>

              <div className={style.component__container}>

                <div className={style.semDados}>SEM DADOS</div></div></>}
        </div>


      </div >

    </>

  )
}