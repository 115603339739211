import { useState } from "react";
import style from "../../../styles/addSometing.module.scss";
import NewTeam from "components/teams/newTeam";
import { baseURL } from "baseURL";

export default function EditTeams() {

  const [data, setData] = useState() as any;

  const teamName = data ? data.team : "Novo Time";
  const icon = data ? data.icon : "assets/areas/globe.svg";

  return (
    <section className={style.section}>

      <div className={style.view}>
        <img src={baseURL + icon} className={style.item} alt={teamName} />
        <div className={style.printObjective}>
          {teamName}
        </div>
      </div>

      <div className={style.content}>
        <div className={style.container}>


          <NewTeam data={(e: any) => setData(e)} />

        </div>

      </div>

    </section>
  );
}