import Kanban from "components/tasks/kanban";
import { useRecoilValue } from "recoil";
import { userIdState } from "state/atom";
import stylePages from "styles/pages.module.scss";


export default function Tasks(){

  const userId = useRecoilValue(userIdState);


  return(
    <section className={stylePages.container__section}>
    <div className={stylePages.header}>

      <div className={stylePages.header__container}>
        <div className={stylePages.header__title}>
          Nossas Tarefas
        </div>
      </div>

      <div className={stylePages.header__actions}>
        {/* <Filters listOfOkrs={okrs} filteredOkrs={(e:any) => filters(e)} />
        <ButtonSystem action={(e: any) => addKR(e)} status={true} text="Adicionar Objetivo" to="next" icon="add" /> */}
      </div>
    </div>

    <Kanban okr="all" kr="all" user={userId} />
    
  </section>
  )
}