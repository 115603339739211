
import { useState } from "react";

interface IMathOkrs {
  okrs: any,
}



export function MathOkrs(props: IMathOkrs) {

  let sumTotal = 0;

  props.okrs.forEach((okr: any) => {
    let okrDoneValue = 0;

    okr.krs.forEach((kr: any) => {
      const checkpoints = kr.checkpoints;
      const totalValue = kr.finalValue;
      const initialValue = kr.initialValue;
      const resultType = kr.resultType;

      if (checkpoints.length > 0) {
        let latestCheckpoint = checkpoints.reduce((previousCheckpoint: any, currentCheckpoint: any) => {
          return previousCheckpoint.date > currentCheckpoint.date ? previousCheckpoint : currentCheckpoint;
        });

        const checkpointValue = parseFloat(`${latestCheckpoint.value}`);

        if (resultType === "Conquista") {
          const preDone = checkpointValue - initialValue;
          const preTotal = totalValue - initialValue;

          const percentageValue = ((100 / preTotal) * preDone);
          const percent = parseInt(`${percentageValue}`);
          okrDoneValue += percent;
        } else if (resultType === "Redução") {
          const preDone = initialValue - checkpointValue;
          const preTotal = initialValue - totalValue;

          const percentageValue = ((100 / preTotal) * preDone);
          const percent = parseInt(`${percentageValue}`);
          okrDoneValue += percent;
        } else if (resultType === "Manter") {
          if (checkpointValue < totalValue) {
            const preDone = checkpointValue;
            const preTotal = totalValue;

            const percentageValue = ((100 / preTotal) * preDone);
            const percent = parseInt(`${percentageValue}`);
            okrDoneValue += percent;
          } else if (checkpointValue > totalValue) {
            const preDone = checkpointValue - totalValue;
            const preTotal = totalValue - preDone;

            const percentageValue = ((100 / totalValue) * preTotal);
            const percent = parseInt(`${percentageValue}`);
            okrDoneValue += percent;
          } else if (checkpointValue === totalValue) {
            const percentageValue = 100;
            const percent = parseInt(`${percentageValue}`);
            okrDoneValue += percent;
          }
        }
      }
    });

    // Calculate the percentage of completed KR for this OKR
    const krDonePercentage = okr.krs.length > 0 ? okrDoneValue / okr.krs.length : 0;

    sumTotal += krDonePercentage;
  });

  // Calculate the percentage of completed OKRs
  const total = sumTotal / props.okrs.length;
  const totalPercent = total.toFixed(0);
  const totalFinal = parseInt(`${totalPercent}`);

  const percent = parseInt(`${totalFinal}`);

  let doneValue = totalFinal;
  let missingValue = totalFinal < 100 ? 100 - totalFinal : 0;
  let percentage = percent < 100 ? percent : 100;

  return {doneValue, missingValue, percentage}
}