import style from "./Teams.module.scss";
import { baseURL } from "baseURL";
import { ITeam } from "interface/team";
import { useRecoilValue } from "recoil";
import { businessInfoState } from "state/atom";

export function Teams(props:ITeam){

  const businessInfo = useRecoilValue(businessInfoState);

  const teams = businessInfo.teams;

  const teamsSort = [...teams].sort((a, b) => {
    const teamA = a.team.toLowerCase();
    const teamB = b.team.toLowerCase();
  
    if (teamA < teamB) {
      return -1;
    }
    if (teamA > teamB) {
      return 1;
    }
    return 0;
  });

  
  return(
    <>    
    {teamsSort.filter((team: any) => team.id === props.team).map((e: any, index: any) =>
    <div className={style.singleTeam} key={index}>
      <img src={
        baseURL + e.icon} className={style.icon} alt={e.team} />{e.team}
    </div>)}
    </>
  );
}