import { db } from "config/firebase";
import { doc, collection, onSnapshot } from "firebase/firestore";
import { IUser } from "interface/user";

export async function getUsersByBusinessId(businessId: any, setBusinessUsers: any) {

  if (businessId) {
    const companyRef = doc(db, 'companies', businessId);
    const usersRef = collection(companyRef, 'users');

    const unsubscribe = onSnapshot(usersRef, (snapshot) => {
      const fetchedUsers: IUser[] = snapshot.docs.map((doc) => {
        const userData = doc.data() as IUser;
        const { name, email, role, admin, teams, userActive, photoURL } = userData;
        return {
          id: doc.id,
          name: name,
          email: email,
          role: role,
          admin: admin,
          teams: teams,
          userActive: userActive,
          photoURL: photoURL,
        };
      });
      setBusinessUsers(fetchedUsers);
    });

    return () => {
      unsubscribe();
    };
  }
}