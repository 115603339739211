import style from "./config.module.scss";
import styleAdd from "../../styles/addSometing.module.scss";
import { useRecoilValue } from "recoil";
import { businessInfoState } from "state/atom";
import { baseURL } from "baseURL";
import { FaBuilding, FaChevronDown, FaChevronRight, FaChevronUp, FaFlag, FaUsers } from "react-icons/fa";
import { useEffect, useState } from "react";
import TeamsConfig from "../../components/teams/configTeams";
import BusinessConfig from "../../components/business/configBusiness";
import ListUsers from "components/users/listUsers";
import { useNavigate } from "react-router-dom";


export default function Configuracoes() {

  const businessInfo = useRecoilValue(businessInfoState);

  const [key, setKey] = useState(0);

  const navigate = useNavigate();

  useEffect(() =>{
    setKey(key + 1);
  },[businessInfo])



  // Open and Close Sections

    const goToBusiness = () => {
      navigate("/configuracoes/business");
    }

    const goToTeams = () => {
      navigate("/configuracoes/teams");
    }

    const goToUsers = () => {
      navigate("/configuracoes/users");
    }


  return (

    <section className={styleAdd.section}>


      <div className={styleAdd.view}>
        {businessInfo?.photoURL ?
          <img key={key} src={businessInfo.photoURL} alt="usuário" className={style.userPhoto} /> :
          <img key={key} src={baseURL + "/assets/avatar.png"} alt="usuário" className={style.userPhoto} />}

        <div className={style.userName}>{businessInfo.name}</div>
      </div>

      <div className={styleAdd.content}>
        <div className={style.component}>

          {/* BUSINESS */}

          <div onClick={goToBusiness} className={style.menuConfig} >

            <div className={style.title}>
              <FaBuilding className={style.iconTitle} /> Empresa
            </div>
            <FaChevronRight className={style.iconOpen} />
          </div>



          {/* TEAMS */}

          <div onClick={goToTeams} className={style.menuConfig} >

            <div className={style.title}>
              <FaFlag className={style.iconTitle} /> Times
            </div>
            <FaChevronRight className={style.iconOpen} />
          </div>


          {/* USERS */}


          <div onClick={goToUsers} className={style.menuConfig} >

            <div className={style.title}>
              <FaUsers className={style.iconTitle} /> Usuários
            </div>
            <FaChevronRight className={style.iconOpen} />
          </div>


        </div>
      </div>
    </section >

  );
}