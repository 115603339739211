import { useEffect, useState } from "react";
import {
  FaArrowUp,
  FaArrowDown,
  FaEquals,
} from "react-icons/fa";
import style from "pages/meetings/singleMeeting/singleMeeting.module.scss";

interface IUpDown {
  resultType: string;
  dataType: string;
  initialValue: number;
  finalValue: number;
  previousValue?: number | undefined;
  nowValue: number;
}

export default function UpDown(props: IUpDown) {
  const [icon, setIcon] = useState<JSX.Element | null>(null);
  const [result, setResult] = useState<number>(0);


  useEffect(() => {

    // Calcula a variação com base nos valores fornecidos
    const variacao =
      props.previousValue !== undefined
        ? props.nowValue - props.previousValue
        : props.nowValue - props.initialValue;

    // Define o ícone com base no tipo de resultado
    if (props.resultType === "Conquista") {
      if (variacao > 0) {
        setIcon(<FaArrowUp className={style.comparative__icon} />);
      } else if (variacao < 0) {
        setIcon(<FaArrowDown className={style.comparative__iconRed} />);
      } else {
        setIcon(<FaEquals className={style.comparative__iconAzul} />);
      }

      setResult(variacao);


    } else if (props.resultType === "Redução") {
      if (variacao < 0) {
        setIcon(<FaArrowDown className={style.comparative__icon} />);
      } else if (variacao > 0) {
        setIcon(<FaArrowUp className={style.comparative__iconRed} />);
      } else {
        setIcon(<FaEquals className={style.comparative__iconAzul} />);
      }

      setResult(variacao);

    } else if (props.resultType === "Manter") {
      const distanciaAtual = Math.abs(props.nowValue - props.finalValue);
      const distanciaAnterior = Math.abs(props.previousValue? props.previousValue - props.finalValue : props.initialValue - props.finalValue);
    
      if (distanciaAtual < distanciaAnterior) {
        setIcon(<FaArrowUp className={style.comparative__icon} />);
      } else if (distanciaAtual > distanciaAnterior) {
        setIcon(<FaArrowDown className={style.comparative__iconRed} />);
      } else {
        setIcon(<FaEquals className={style.comparative__iconAzul} />);
      }

      setResult(distanciaAtual - distanciaAnterior);
    }
  }, [props])





  return (
    <>
      {result !== 0 ? <>
      {icon && <span>{icon}</span>}
      {props.dataType === "R$" ? `R$ ${result}` : ""}
      {props.dataType === "%" ? `${result.toFixed(0)}%` : ""}
      {props.dataType === "number" ? `${result}` : ""}
      </> : <>
      {icon && <span>{icon}</span>}
      </>}

    </>
  )
}