import { useEffect, useState } from "react";
import style from "./configBusiness.module.scss";
import { businessIdState, businessInfoState } from "state/atom";
import { useRecoilValue } from "recoil";
import { TextField } from "@mui/material";
import LogoBusiness from "components/business/logoBusiness";
import { FaBuilding, FaSave, FaUser } from "react-icons/fa";
import { doc, updateDoc } from "firebase/firestore";
import { db, storage } from "config/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { baseURL } from "baseURL";
import styleAdd from "styles/addSometing.module.scss";
import ButtonSystem from "components/forms/button";
import { useNavigate } from "react-router-dom";


export default function BusinessConfig() {

  const businessId = useRecoilValue(businessIdState);
  const businessInfo = useRecoilValue(businessInfoState);

  const [name, setName] = useState("");
  const [nameMessage, setNameMessage] = useState(false);
  const [photo, setPhoto] = useState();
  const [photoURL, setPhotoURL] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(key + 1);
  }, [businessInfo])



  useEffect(() => {
    setPhotoURL(businessInfo.photoURL);
    setName(businessInfo.name);
  }, [businessInfo]);

  const companyRef = businessId ? doc(db, "companies", businessId) : "";

  // Atualiza a logo da empresa no estado local
  const handlePhotoChange = (event: any) => {
    setPhoto(event);
  };

  // Atualiza o nome da empresa no estado local
  const handleNameChange = (event: any) => {
    setName(event.target.value);

    if (event.target.value === "" || event.target.value === undefined || event.target.value === null) {
      setNameMessage(true);
    } else if (nameMessage === true) {
      setNameMessage(false);
    }
  };

  // Atualiza as informações da empresa no Firestore
  const handleSubmit = async (event: any) => {

    event.preventDefault();

    try {
      if (companyRef !== "" && name !== undefined && name !== null && name !== "") {
        // Atualiza as informações da empresa no Firestore
        await updateDoc(companyRef, {
          name: name,
        });

        // Se a empresa selecionou uma nova foto/logo, faz o upload dela para o Storage e atualiza a foto/logo no Firestore
        if (photo) {

          const convertToFile = async (dataUrl: string) => {
            try {
              const blob = await fetch(dataUrl).then((r) => r.blob());
              const newFile = new File([blob], `${businessId}.png`, { type: "image/png" });

              // Upload file to Firebase Storage
              const storageRef = ref(
                storage,
                `companies/${businessId}/logo/${businessId}/`
              );

              const snapshot = await uploadBytesResumable(storageRef, newFile);
              const photoURL = await getDownloadURL(snapshot.ref);
              await updateDoc(companyRef, { photoURL });
            } catch (error) {
              console.error(error);
            }
          };

          convertToFile(photo);
        }

        setSuccess(true);
        setError(null);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  const cancel = (e: any) => {
    navigate(-1);
  }


  return (
    <section className={styleAdd.section}>

      <div className={styleAdd.view}>
        {businessInfo?.photoURL ?
          <img key={key} src={businessInfo.photoURL} alt="usuário" className={style.userPhoto} /> :
          <img key={key} src={baseURL + "/assets/avatar.png"} alt="usuário" className={style.userPhoto} />}

        <div className={style.userName}>
          {name}
        </div>
      </div>

      <div className={styleAdd.content}>
        <div className={styleAdd.container}>

          <div className={style.contentConfig} data-testid="business-config">

            <form onSubmit={handleSubmit} className={style.form}>

              {/* NAME */}

              <div className={style.field}>

                <TextField
                  id="business"
                  label="Empresa"
                  value={name}
                  error={nameMessage}
                  helperText={nameMessage === true ? 'O nome da Empresa não pode ficar vazio' : ''}
                  onChange={handleNameChange}
                  sx={{
                    width: '100%', '& label': {
                      minWidth: 200,
                    },
                  }}
                />
              </div>

              {/* LOGO */}

              <div className={style.label}>
                Logo Atual:
                <br />
                <img width="200px" height="200px" src={photoURL ? photoURL : baseURL + "assets/avatar.png"} alt="Logo da Empresa" />
              </div>

              <div className={style.label}>
                Logo da Empresa:
                <div className={style.contentPhotos}>
                  <LogoBusiness onSave={handlePhotoChange} businessId={businessId ? businessId : "noBusinessId"} />
                </div>
              </div>

              {/* SAVE BUTTON */}

              <div className={styleAdd.controls}>

                {/* <div className={style.label}> */}

                <ButtonSystem action={(e: any) => cancel(e)} status={true} text="Voltar" to="previous" icon="back" />


                <button className={style.button} type="submit">
                  <FaSave className={style.icon} />Salvar
                </button>
              </div>

              {/* </div> */}
            </form>
            {error && <p>{error}</p>}
            {success && <p>As informações do usuário foram atualizadas com sucesso!</p>}
          </div>
        </div>

      </div>

    </section >
  )
}