import style from "../../../styles/components.module.scss";
import { useEffect, useState } from "react";
import styleComponent from "../../../styles/components.module.scss";
import ButtonSystem from "components/forms/button";
import iconsList from "../../../data/icons.json";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { businessIdState, businessInfoState, updateState } from "state/atom";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import TextField from '@mui/material/TextField';
import SelectIcon from "../selectIcon";
import { v4 as importedUuidv4 } from 'uuid';

const newId = importedUuidv4();

const initialFilters = {
  team: "Novo Time",
  icon: "assets/areas/globe.svg"
};

export default function NewTeam(props: any) {

  // DATA TO SAVE

  const [team, setTeam] = useState(initialFilters.team);
  const [icon, setIcon] = useState(initialFilters.icon);
  const [newTeam, setNewTeam] = useState(false);
  
  // MESSAGES

  const [messageTeam, setMessageTeam] = useState(false);
  const [messageIcon, setMessageIcon] = useState(false);
  const [buttonNext, setButtonNext] = useState(false);

  const icons = iconsList;
  const businessId = useRecoilValue(businessIdState);


  const businessInfo = useRecoilValue(businessInfoState);
  const teamsData = businessInfo.teams;
  const { teamid } = useParams();

  const update = useRecoilValue(updateState);
  const setUpdateState = useSetRecoilState(updateState);

  useEffect(() => {
    if (teamid === "newTeam") {
      setNewTeam(true);
    } else {
      setNewTeam(false);

      const teamData = teamsData.find((teamData: any) => teamData.id === teamid);

      if (teamData) {
        setTeam(teamData.team);
        setIcon(teamData.icon);
      }

    }
  }, []);


  const navigate = useNavigate();

  // RETURN DATA TO PARENT

  useEffect(() => {
    props.data({
      team: team,
      icon: icon
    });
  }, [team, icon]);

  // SUBMIT OKR

  const submit = () => {
    if (businessId) {
      // Define a referência para o documento de configuração da empresa
      const companyConfigRef = doc(db, "companies", businessId);
  
      // Recupera os dados atuais do documento
      getDoc(companyConfigRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const currentData = docSnapshot.data();
  
            // Verifica se já existe um campo "teams" no documento
            if (currentData.teams) {
              // Encontra o índice do time com base no ID, se existir
              const existingTeamIndex = currentData.teams.findIndex(
                (existingTeam:any) => existingTeam.id === teamid
              );
  
              if (existingTeamIndex !== -1) {
                // Se o time já existir, atualiza-o
                currentData.teams[existingTeamIndex] = {
                  team: team,
                  icon: icon,
                  id: teamid,
                };
              } else {
                // Se o time não existir, adiciona-o à lista de times
                currentData.teams.push({
                  team: team,
                  icon: icon,
                  id: newId,
                });
              }
            } else {
              // Se não existir um campo "teams", cria-o com o novo time
              currentData.teams = [
                {
                  team: team,
                  icon: icon,
                  id: newId,
                },
              ];
            }
  
            // Atualiza o documento com os novos dados
            updateDoc(companyConfigRef, currentData)
              .then(() => {
                setUpdateState(!update);
                navigate(`/configuracoes`);
              })
              .catch((error) => {
                console.error("Erro ao atualizar os times:", error);
              });
          }
        })
        .catch((error) => {
          console.error("Erro ao recuperar os dados do documento:", error);
        });
    }
  };

  // VALIDATE TEAM

  const changeTeam = (e: any) => {
    setTeam(e);
    if (e !== "" && e !== undefined && messageTeam === true) {
      setMessageTeam(false);
    } else if ((e === "" || e === undefined) && messageTeam === false) {
      setMessageTeam(true);
    }
  }

  // VALIDATE BUTTON 

  useEffect(() => {

    if (messageTeam === false && messageIcon === false) {
      setButtonNext(true);
    } else if (buttonNext === true) {
      setButtonNext(false);
    }
  }, [messageTeam, messageIcon]);


  return (
    <section className={style.section}>

      <div className={style.div__input}>

        <TextField
          label="Time"
          error={Boolean(messageTeam)}
          helperText={messageTeam ? "Adicione um nome para o Time" : ""}
          value={team}
          onChange={(e) => changeTeam(e.target.value)}
          placeholder="Escolha um nome para o time..."
          sx={{
            width: '100%', '& label': {
              minWidth: 200,
            },
          }}
          fullWidth
        />

      </div>
      <div className={style.div__input}>

        {/* ICONES */}

        <SelectIcon label="Selecione o ícone" value={icon} handleIcon={(e: any) => setIcon(e)} />


      </div>

      <div className={styleComponent.controls}>

        <ButtonSystem action={(e: any) => submit()} status={buttonNext} text="Salvar" to="finish" icon="finish" />

      </div>

    </section>
  );
}
