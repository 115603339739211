import { db } from "config/firebase";
import { doc, onSnapshot } from "firebase/firestore";

export async function getBusinessInfo(businessId:any, setBusinessInfo:any) {
  if (businessId) {
    const companyRef = doc(db, "companies", businessId);
    const unsubscribe = onSnapshot(companyRef, async (snapshot:any) => {
      const companyData = snapshot.data();
      const { name, plan, active, cnpj, photoURL,teams } = companyData;
      const businessInfoUpdate = {
        name: name,
        plan: plan,
        active: active,
        cnpj: cnpj,
        photoURL: photoURL,
        teams: teams
      };
      setBusinessInfo(businessInfoUpdate);
    });

    return () => {
      unsubscribe();
    };
  }
}