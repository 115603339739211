import style from "./admin.module.scss";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Business from "components/business/listBusiness";
import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { db } from "config/firebase";
import { collection, collectionGroup, getDocs } from 'firebase/firestore';
import ExportButton from "services/export/exportBusiness";
import { addTeamsToBusiness } from "services/export/addTeams";
import ExportOkrs from "services/export/exportOkrs";
import { addKrstoField } from "services/export/addKrsField";
interface CompanyData {
  id: string;
  name: string;
  plan: string;
  active: boolean;
  users: any;
  photoURL: string;
}

export const companiesListState = atom<any>({
  key: "companiesListState",
  default: [],
});

export default function Admin() {
  const [companiesList, setCompaniesList] = useRecoilState(companiesListState);
  const navigate = useNavigate();

  useEffect(() => {
    const getCompanies = async () => {
      const companiesRef = collectionGroup(db, 'companies');
      const companiesSnapshot = await getDocs(companiesRef);
      const companiesPromises = companiesSnapshot.docs.map(async (doc) => {
        const usersRef = collection(db, 'companies', doc.id, 'users');
        const usersSnapshot = await getDocs(usersRef);
        const usersData = usersSnapshot.docs.map(userDoc => ({ id: userDoc.id, ...userDoc.data() })) as any;
        return { id: doc.id, ...doc.data(), users: usersData };
      });

      const companiesData = await Promise.all(companiesPromises);
      setCompaniesList(companiesData);
    };

    getCompanies();
  }, [setCompaniesList]);

  const addEmpresa = () => {
    navigate("/business/new");
  };


  const handleAddTeamsClick = () => {
    addTeamsToBusiness(); // Chame a função para adicionar os campos "teams"
  };

  return (
    <section className={style.section}>
      <div className={style.actionsContainer}>
        <button type="button" onClick={addEmpresa} className={style.button}>
          <FaPlusCircle className={style.icon} /> Adicionar Empresa
        </button>
      </div>

      <div className={style.container}>
        {companiesList.map((r: CompanyData, index: number) => (
          <Business
            key={index}
            id={r.id}
            name={r.name}
            plan={r.plan}
            active={r.active}
            users={r.users}
            photoURL={r.photoURL}
          />
        ))}
      </div>
    </section>
  );
}
