import ButtonSystem from "components/forms/button";
import GaugeChart from "components/charts/gaugeChart";
import Timeline from "components/charts/timeline";
import { useEffect, useState } from "react";
import { FaCodeBranch, FaEdit, FaRegTrashAlt, FaCalendarAlt, FaArchive, FaUndoAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import KrSingle from "../../kr/krSingle";
import style from "./OkrSingle.module.scss";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { db } from "config/firebase";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { businessIdState, updateState } from "state/atom";
import { collection, deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

interface IOKRSingle {
  data: any;
  key: any;
  archive: any;
  select: any;
}

export default function OkrSingle(props: IOKRSingle) {

  const [doneValue, setDoneValue] = useState(0);
  const [missingValue, setMissingValue] = useState(0);
  const [percent, setPercent] = useState(0);
  const [chartKey, setChartKey] = useState(0);
  const [open, setOpen] = useState(false);
  const [openArchive, setOpenArchive] = useState(false);

  const businessId = useRecoilValue(businessIdState);
  const update = useRecoilValue(updateState);
  const setUpdateState = useSetRecoilState(updateState);

  const navigate = useNavigate();

  const krs = props.data.krs;

  useEffect(() => {

    if (krs.length === 0) {
      // Caso não haja KRs, definir valores padrão
      setDoneValue(0);
      setMissingValue(1);
      setPercent(0);
      setChartKey(chartKey + 1);
      return;
    }

    let sumTotal = 0;

    krs.forEach((e: any) => {

      const checkpoints = e.checkpoints;
      const totalValue = e.finalValue;
      const initialValue = e.initialValue;
      const resultType = e.resultType;

      if (checkpoints.length > 0) {

        let latestCheckpoint = checkpoints.reduce((previousCheckpoint: any, currentCheckpoint: any) => {
          return previousCheckpoint.date > currentCheckpoint.date ? previousCheckpoint : currentCheckpoint;
        });

        const checkpointValue = parseFloat(`${latestCheckpoint.value}`);

        if (resultType === "Conquista") {

          const preDone = checkpointValue - initialValue;
          const preTotal = totalValue - initialValue;

          const percentageValue = ((100 / preTotal) * preDone);
          const percent = parseInt(`${percentageValue}`);
          sumTotal += percent;

        } else if (resultType === "Redução") {

          const preDone = initialValue - checkpointValue;
          const preTotal = initialValue - totalValue;

          const percentageValue = ((100 / preTotal) * preDone);
          const percent = parseInt(`${percentageValue}`);
          sumTotal += percent;

        } else if (resultType === "Manter") {

          if (checkpointValue < totalValue) {

            const preDone = checkpointValue;
            const preTotal = totalValue;

            const percentageValue = ((100 / preTotal) * preDone);
            const percent = parseInt(`${percentageValue}`);
            sumTotal += percent;

          } else if (checkpointValue > totalValue) {

            const preDone = checkpointValue - totalValue; // 110 - 100 = 10
            const preTotal = totalValue - preDone; // 100 - 10 = 90

            const percentageValue = ((100 / totalValue) * preTotal); // 100 / 100 * 90 = 90
            const percent = parseInt(`${percentageValue}`);
            sumTotal += percent;

          } else if (checkpointValue === totalValue) {

            const percentageValue = (100);
            const percent = parseInt(`${percentageValue}`);
            sumTotal += percent;

          }
        }

      }
    });

    const total = sumTotal / krs.length;
    const totalPercent = total.toFixed(0);
    const totalFinal = parseInt(`${totalPercent}`);
    setDoneValue(totalFinal);

    if (totalFinal < 100) {
      const missingValue = 100 - totalFinal;
      setMissingValue(missingValue);
    } else {
      setMissingValue(0);
    }

    const percent = parseInt(`${totalFinal}`);

    if (percent < 100) {
      setPercent(percent);
    } else {
      setPercent(100);
    }

    setChartKey(chartKey + 1);

  }, [krs]);

  const addKR = (e: any) => {
    navigate(`/edit/${props.data.id}/newkr`);
  }

  // Dialog

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleArchiveOpen = () => {
    setOpenArchive(true);
  }

  const handleArchiveClose = () => {
    setOpenArchive(false);
  }
 
  // ARCHIVE OKR

  const archiveObjective = () => {
    setOpenArchive(false);

    if (businessId) {
      // Define a referência para a coleção de "okrs" da empresa
      const okrDocRef = doc(db, "companies", businessId, "okrs", props.data.id);

      // Verifica se o documento já existe
      getDoc(okrDocRef).then((docSnapshot) => {
        if (docSnapshot.exists()) {

          // Define a referência para a coleção de "okrs" da empresa
          const archiveRef = collection(db, "companies", businessId, "archived");
          const archiveDocRef = doc(archiveRef, docSnapshot.id);

          setDoc(archiveDocRef, {
            ...docSnapshot.data(),
          }).then(() => {

            deleteDoc(okrDocRef).then(() => {

              setUpdateState(!update);
              navigate(`/objetivos`);
            });
          });
        } else {
          console.log("Doc Não existe");
        }
      });
    }

  }

  // REOPEN OKR

  const handleReopen = () => {
    if (businessId) {
      // Define a referência para a coleção de "okrs" da empresa
      const okrArchiveRef = doc(db, "companies", businessId, "archived", props.data.id);

      // Verifica se o documento já existe
      getDoc(okrArchiveRef).then((docSnapshot) => {
        if (docSnapshot.exists()) {

          // Define a referência para a coleção de "okrs" da empresa
          const okrRef = collection(db, "companies", businessId, "okrs");
          const okrDocRef = doc(okrRef, docSnapshot.id);

          setDoc(okrDocRef, {
            ...docSnapshot.data(),
          }).then(() => {

            deleteDoc(okrArchiveRef).then(() => {

              setUpdateState(!update);
              navigate(`/objetivos`);
            });
          });
        } else {
          console.log("Doc Não existe");
        }
      });
    }
  }

  // DELETE OPEN OKR

  const deleteOpenOkr = () => {
    setOpen(false);

    if (businessId) {
      // Define a referência para o documento a ser excluído
      const okrDocRef = props.archive === false ? doc(db, "companies", businessId, "okrs", props.data.id) : doc(db, "companies", businessId, "archived", props.data.id);

      // Exclui o documento existente
      deleteDoc(okrDocRef).then(() => {
        // Atualiza o estado do componente para refletir a exclusão
        setUpdateState(!update);
        navigate("/objetivos");
      });
    }
  }


  return (
    <div className={style.container}>
      <div className={style.objective}>
        <div className={style.okr}>
          <FaCodeBranch className={style.item} />
          {props.data.okrName}
          <div className={style.actions}>

            {/* Crie uma tabela de 1 linha e 3 colunas com os ícones abaixo mais um título */}


            {props.archive === false ?
              <div className={style.actions__table}>
                <div className={style.actions__row} onClick={() => navigate(`/edit/${props.data.id}`)}>
                  <FaEdit className={style.actions__icon} />
                  <div className={style.actions__text}>
                    Editar
                  </div>
                </div>
                <div className={style.actions__row} onClick={() => handleArchiveOpen()}>
                  <FaArchive className={style.actions__icon} />
                  <div className={style.actions__text}>
                    Arquivar
                  </div>
                </div>
                <div className={style.actions__row} onClick={() => handleClickOpen()}>
                  <FaRegTrashAlt className={style.actions__icon} />
                  <div className={style.actions__text}>
                    Deletar
                  </div>
                </div>
              </div> : <div className={style.actions__table}>
                <div className={style.actions__row} onClick={() => handleReopen()}>
                  <FaUndoAlt className={style.actions__icon} />
                  <div className={style.actions__text}>
                    Desarquivar
                  </div>
                </div>

                <div className={style.actions__row} onClick={() => handleClickOpen()}>
                  <FaRegTrashAlt className={style.actions__icon} />
                  <div className={style.actions__text}>
                    Deletar
                  </div>
                </div>
              </div>}

            {/* ARQUIVAR OKR */}

            <Dialog
              open={openArchive}
              onClose={handleArchiveClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Você tem certeza que deseja arquivar este Objetivo?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Ao arquivar este objetivo, ele não será mais exibido na lista de objetivos ativos. Ele também não será mais contato nos dashboards e gráficos.<br /><br /> Você pode acessar objetivos arquivados na página de Arquivo.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleArchiveClose}>Cancelar</Button>
                <Button onClick={archiveObjective} autoFocus>
                  Arquivar
                </Button>
              </DialogActions>
            </Dialog>

            {/* DELETE OPEN OKR */}

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Você tem certeza que deseja excluir este OKR?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Ao excluir este OKR, todos os seus resultados-chave e checkpoints serão excluídos também. Esta ação não pode ser desfeita.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={deleteOpenOkr} autoFocus>
                  Excluir
                </Button>
              </DialogActions>
            </Dialog>

          </div>
        </div>
        {/* <div className={style.column}> */}
          <div className={style.status}>
            <GaugeChart key={chartKey} doneValue={doneValue} missingValue={missingValue} percentValue={percent} />
          </div>
          <div className={style.timeline}>
            <FaCalendarAlt className={style.itemB} />
            <Timeline initialDate={props.data.initialDate} finalDate={props.data.finalDate} />
          </div>
        {/* </div> */}
      </div>
      {krs.map((e: any, index: any) =>
        <KrSingle key={index} data={e} okr={props.data.id} />
      )}
      <div className={style.newkrs}>
        <div className={style.hr} />
        <ButtonSystem action={(e: any) => addKR(e)} status={true} text="Adicionar Resultado-chave" to="finish" icon="add" />
      </div>
      <hr className={style.separator}></hr>

    </div>
  );
}
