import { NavLink, useNavigate } from "react-router-dom";
import style from "./Menu.module.scss";
import { FaCalendar, FaCalendarAlt, FaChartBar, FaCog, FaFlag, FaList, FaMugHot, FaPlusCircle, FaRegCalendarCheck, FaUserEdit, FaUserFriends, } from "react-icons/fa";
import { SiTarget } from "react-icons/si";
import { useState } from "react";
import classNames from "classnames";
import { baseURL } from "baseURL";
import { useRecoilValue } from "recoil";
import { userDataState, userIdState } from "state/atom";
import Logout from "components/general/logout";

export default function Menu() {

  const userData = useRecoilValue(userDataState);

  const [menu, setMenu] = useState(false);
  const userId = useRecoilValue(userIdState);

  const navigate = useNavigate();

  const goToUser = () => {
    navigate("/usuario")
  }
  

  function openMenu() {
    setMenu(!menu);
  }

  function closeMenu() {
    setMenu(false);
  }

  if (menu === true) {
    document.body.style.overflowY = 'hidden';
  } else {
    document.body.style.overflowY = 'unset';
  }

  return (
    <>
      <div className={style.mobile__header}>

        <div className={style.logo}>
          <NavLink to="/"><img src={
            baseURL + "/assets/yscale.svg"} alt="Yscale" className={style.logo__img} /></NavLink>
        </div>
        <div className={style.toggle}>
          <label htmlFor="check">
            <input type="checkbox" id="check" checked={menu} onClick={openMenu} />
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

      </div>




      <div className={classNames({
        [style.menu]: true,
        [menu === true ? style["menu--active"] : style["menu--hidden"]]: true,
      })}>
        <nav onClick={closeMenu}>
          <ul>


            <li>
              <NavLink to="/" className={({ isActive }) => (isActive ? style.active : "")}>
                <FaChartBar className={style.menu__icon} />
                <div className={style.title}>
                  Dashboard
                </div>
              </NavLink>
            </li>

            <li>
              <NavLink to="/objetivos" className={({ isActive }) => (isActive ? style.active : "")}>
                <SiTarget className={style.menu__icon} />
                <div className={style.title}>
                  Objetivos
                </div>
              </NavLink>
            </li>

            <li>
              <NavLink to="/reunioes" className={({ isActive }) => (isActive ? style.active : "")}>
                <FaRegCalendarCheck className={style.menu__icon} />
                <div className={style.title}>
                  Reuniões
                </div>
              </NavLink>
            </li>

            {/* <li>
              <NavLink to="/edit/newokr" className={({ isActive }) => (isActive ? style.active : "")}>
                <FaPlusCircle className={style.menu__icon} />
                <div className={style.title}>
                  Adicionar OKR
                </div>
              </NavLink>
            </li> */}

            <li>
              <NavLink to="/meusresultados" className={({ isActive }) => (isActive ? style.active : "")}>
                <FaFlag className={style.menu__icon} />
                <div className={style.title}>
                  Meus Resultados
                </div>
              </NavLink>
            </li>

            <li>
              <NavLink to="/tarefas" className={({ isActive }) => (isActive ? style.active : "")}>
                <FaList className={style.menu__icon} />
                <div className={style.title}>
                  Minhas Tarefas
                </div>
              </NavLink>
            </li>

            <li>
              <NavLink to="/time" className={({ isActive }) => (isActive ? style.active : "")}>
                <FaUserFriends className={style.menu__icon} />
                <div className={style.title}>
                  Meu Time
                </div>
              </NavLink>
            </li>

            <li>
              <NavLink to="/usuario" className={({ isActive }) => (isActive ? style.active : "")}>
                <FaUserEdit className={style.menu__icon} />
                <div className={style.title}>
                  Meu Perfil
                </div>
              </NavLink>
            </li>

            {userData.admin === true ?

            <li>
              <NavLink to="/configuracoes" className={({ isActive }) => (isActive ? style.active : "")}>
                <FaCog className={style.menu__icon} />
                <div className={style.title}>
                  Configurações
                </div>
              </NavLink>
            </li> : ""}

            {userId === "pCmYv6tCmlP1T274cI0l4XKpog93" || userId === "vanGKNSp5haPMVRhJqvVxXzkr4k1" || userId === "bp7wdYCOr2g6TSpZxCQtd862XN72" ?
              <li>
                <NavLink to="/admin" className={({ isActive }) => (isActive ? style.active : "")}>
                  <FaMugHot className={style.menu__icon} />
                  <div className={style.title}>
                    Admin
                  </div>
                </NavLink>
              </li> : ""}


          </ul>
        </nav>

        <div className={style.userContainer}>
          <div className={style.user} onClick={goToUser}>
            <div className={style.user__infoA}>
              {userData?.photoURL ?
                <img src={userData?.photoURL} alt="usuário" className={style.user__img} /> :
                <img src={
                  baseURL + "/assets/avatar.png"} alt="usuário" className={style.user__img} />}
            </div>
            <div className={style.user__infoB}>
              <div className={style.user__nome}>
                {userData?.name}
              </div>
              <div className={style.user__cargo}>
                {userData?.role}
              </div>
            </div>
          </div>
          <Logout />
        </div>

      </div>
    </>
  );
}