import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import style from "./kanban.module.scss";
import { FaCheckCircle, FaList, FaSpinner } from 'react-icons/fa';
import AddTask from '../addTask';
import ButtonSystem from 'components/forms/button';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { businessIdState, okrsState } from 'state/atom';
import { ITask } from 'interface/tasks';
import SingleTask from '../singleTask';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from 'config/firebase';


interface IKanban {
  okr: any;
  kr: any;
  user: any;
}


export default function Kanban(props: IKanban) {

  const [tasks, setTasks] = useState<ITask[]>([]);
  const businessId = useRecoilValue(businessIdState);

  const okrData = useRecoilValue(okrsState);

  const navigate = useNavigate();

  useEffect(() => {

    if (props.okr !== "all" && props.kr !== "all") {

      // Filtrar o OKR com o mesmo ID de props.okr
      const selectedOKR = okrData.find((okr) => okr.id === props.okr);

      if (selectedOKR) {
        // Encontrar o KR com o mesmo ID de props.kr dentro do OKR
        const selectedKR = selectedOKR.krs.find((kr) => kr.id === props.kr);

        if (selectedKR) {
          setTasks(selectedKR.tasks || []);
        }
      }
    } else {
      // Se props.okr ou props.kr for "all", simplesmente retorne todas as tarefas
      const allTasks = okrData.flatMap((okrItem) =>
        okrItem.krs.flatMap((kr) => kr.tasks)
      );

      // Filtrar as tarefas com base no props.userId
      const filteredTasks = allTasks.filter((task) => task.responsible.includes(props.user));

      setTasks(filteredTasks);

    }
  }, [props.okr, props.kr, okrData]);


 // Função para atualizar a tarefa no Firebase
const updateTaskInFirebase = (task:any, newStatus:any) => {

  console.log("newStatus", newStatus);

  if (businessId && task.okrId && task.krId) {
    // Define a referência para o documento de OKR
    const okrRef = doc(db, "companies", businessId, "okrs", task.okrId);

    // Verifica se o documento OKR existe
    getDoc(okrRef)
      .then((okrDocSnapshot) => {
        if (okrDocSnapshot.exists()) {
          // O documento OKR existe

          // Obtém os dados do documento OKR
          const okrData = okrDocSnapshot.data();

          // Verifica se a lista de KRs já existe no documento OKR
          if (okrData.krs) {
            // Procura o KR pelo ID
            const existingKrIndex = okrData.krs.findIndex((itemkr:any) => itemkr.id === task.krId);

            if (existingKrIndex !== -1) {
              // Se o KR existir, obtenha a lista de Tasks
              const krTasks = okrData.krs[existingKrIndex].tasks || [];

              // Encontre a tarefa atualizada pelo ID
              const updatedTaskIndex = krTasks.findIndex((t:any) => t.id === task.id);

              if (updatedTaskIndex !== -1) {
                // Atualize o status da tarefa
                krTasks[updatedTaskIndex].status = newStatus;
              }

              // Atualize a lista de Tasks do KR
              okrData.krs[existingKrIndex].tasks = krTasks;

              // Atualize o documento OKR com os KRs atualizados ou adicionados
              updateDoc(okrRef, okrData)
                .then(() => {
                  console.log("Tarefa atualizada com sucesso!");
                })
                .catch((error) => {
                  console.error("Erro ao atualizar o documento OKR:", error);
                });
            }
          }
        } else {
          // O documento OKR não existe, não é possível adicionar um KR separadamente
          console.error("O documento OKR não existe. Não é possível atualizar a tarefa.");
        }
      })
      .catch((error) => {
        console.error("Erro ao verificar a existência do documento OKR:", error);
      });
  }
};

// Função para lidar com o término do arrasto
const handleDragEnd = (result:any) => {
  if (!result.destination) return;

  const { source, destination } = result;

  if (source.droppableId === destination.droppableId) {
    // Mesma coluna, reordene as tarefas
    const updatedTasks = [...tasks];
    const [movedTask] = updatedTasks.splice(source.index, 1);
    updatedTasks.splice(destination.index, 0, movedTask);

    setTasks(updatedTasks);
  } else { 
    // Movido para outra coluna, atualize o status da tarefa

    const updatedTasks = tasks.map((task) => {
      if (task.id === result.draggableId) {

        const updatedTask = {
          ...task,
          status: destination.droppableId,
        };

        // Atualize a tarefa no Firebase
        updateTaskInFirebase(updatedTask, destination.droppableId);
      }
      return task;
    });

    setTasks(updatedTasks);
  }
};


  return (
    <section className={style.section}>




      <DragDropContext onDragEnd={handleDragEnd}>
        <div className={style.kanbanBoard}>
          <Droppable droppableId="open" key="open">
            {(provided) => (
              <div className={style.collumn}>
                <div className={style.title}>
                  <FaList className={style.title__icon} /> A Fazer
                </div>
                <div ref={provided.innerRef} {...provided.droppableProps} className={style.container__task}>
                  {tasks.map((task, index) =>
                    task.status === 'open' && (
                      <Draggable key={task.id} draggableId={task.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={style.task}
                            onClick={() => navigate(`/tarefas/${task.okrId}/${task.krId}/${task.id}`)}
                          >
                            <SingleTask id={task.id} task={task.task} initialDate={task.initialDate} finalDate={task.finalDate} description={task.description} position={task.position} status={task.status} responsible={task.responsible} categories={task.categories} comments={task.comments} okrId={task.okrId} krId={task.krId} />
                          </div>
                        )}


                      </Draggable>

                    )
                  )}
                  {/* <ButtonSystem action={() => { navigate(`/tarefas/${props.okr}/${props.kr}/newtask`) }} status={true} text="Adicionar Tarefa" to="finish" icon="add" /> */}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
          <Droppable droppableId="progress" key="progress">
            {(provided) => (
              <div className={style.collumn}>
                <div className={style.title}>
                  <FaSpinner className={style.title__icon} /> Em Progresso</div>
                <div ref={provided.innerRef} {...provided.droppableProps} className={style.container__task}>
                  {tasks.map((task, index) =>
                    task.status === 'progress' && (
                      <Draggable key={task.id} draggableId={task.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={style.task}
                            onClick={() => navigate(`/tarefas/${props.okr}/${props.kr}/${task.id}`)}
                          >
                            <SingleTask id={task.id} task={task.task} initialDate={task.initialDate} finalDate={task.finalDate} description={task.description} position={task.position} status={task.status} responsible={task.responsible} categories={task.categories} comments={task.comments} okrId={task.okrId} krId={task.krId} />
                          </div>
                        )}
                      </Draggable>
                    )
                  )}

                </div>
              </div>
            )}
          </Droppable>
          <Droppable droppableId="done" key="done">
            {(provided) => (
              <div className={style.collumn}>
                <div className={style.title}><FaCheckCircle className={style.title__icon} /> Concluído</div>
                <div ref={provided.innerRef} {...provided.droppableProps} className={style.container__task}>
                  {tasks.map((task, index) =>
                    task.status === 'done' && (
                      <Draggable key={task.id} draggableId={task.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={style.task}
                            onClick={() => navigate(`/tarefas/${props.okr}/${props.kr}/${task.id}`)}
                          >
                            <SingleTask id={task.id} task={task.task} initialDate={task.initialDate} finalDate={task.finalDate} description={task.description} position={task.position} status={task.status} responsible={task.responsible} categories={task.categories} comments={task.comments} okrId={task.okrId} krId={task.krId} />
                          </div>
                        )}
                      </Draggable>
                    )
                  )}
                  {/* <ButtonSystem action={() => { navigate(`/tarefas/${props.okr}/${props.kr}/newtask`) }} status={true} text="Adicionar Tarefa" to="finish" icon="add" /> */}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>

      <div className={style.controls}>

        <ButtonSystem action={() => { navigate(`/tarefas/${props.okr}/${props.kr}/newtask`) }} status={true} text="Adicionar Tarefa" to="finish" icon="add" />

      </div>

    </section>
  );
};