import { Teams } from "components/teams/showTeams";
import { FaAngleRight, FaCheckCircle, FaEnvelope, FaFlag, FaTimesCircle, FaUserCog, FaUserTag } from "react-icons/fa";
import style from "./users.module.scss";
import { useNavigate } from "react-router-dom";
import { IUser } from "interface/user";
import { baseURL } from "baseURL";

interface IUserWithBusinessId extends IUser {
  businessId: any;
}

export default function Users(props: IUserWithBusinessId) {

  const navigate = useNavigate();

  return (
    <section className={style.section} onClick={(e: any) => navigate(`/business/${props.businessId}/users/${props.id}`)}>
      {props?.photoURL ?
        <img src={props?.photoURL} alt={props.name} className={style.photo} /> :
        <img src={
          baseURL + "/assets/avatar.png"} alt="usuário" className={style.photo} />}

      <div className={style.container}>
        <div className={style.line}>
          <div className={style.name}>
            {props.name}
          </div>


        </div>

        <div className={style.line}>
          <div className={style.status}>
            {props.teams.map((r: string) => <Teams team={r} />)}
          </div>
        </div>

        <div className={style.line}>
          <div className={style.status}>
            <FaFlag className={style.icon} />  {props.role}
          </div>

          <div className={style.status}>
            <FaEnvelope className={style.icon} />  {props.email}
          </div>
        </div>
        <div className={style.line}>
          <div className={style.status}>
            <FaUserCog className={style.icon} /> {props.admin === true ? "ADMIN" : "USUÁRIO"}
          </div>
          <div className={style.status}>
            {props.userActive === true ? <><FaCheckCircle className={style["active--true"]} /> ATIVO</> : <><FaTimesCircle className={style["active--false"]} /> INATIVO</>}
          </div>
        </div>
      </div>

      <FaAngleRight className={style.section__icon} />

    </section >
  )
}