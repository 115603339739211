import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ICheckpoint } from 'interface/allOkrs';
import dayjs from 'dayjs';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
);

type ILineChart = {
  initialDate: any;
  finalDate: any;
  initialValue: any;
  totalValue: any;
  checkpoints: ICheckpoint[];
};



export default function LineChart({ initialDate, finalDate, totalValue, checkpoints, initialValue }: ILineChart) {

  // const initial = dayjs(initialDate);
  // const end = dayjs(finalDate);

  // Encontre a data mais antiga entre os checkpoints
  const earliestCheckpoint = checkpoints.reduce((earliest, checkpoint) => {
    const checkpointDate = dayjs(checkpoint.date);
    return checkpointDate.isBefore(earliest) ? checkpointDate : earliest;
  }, dayjs(finalDate));

  // Encontre a data mais no futuro entre os checkpoints
  const latestCheckpoint = checkpoints.reduce((latest, checkpoint) => {
    const checkpointDate = dayjs(checkpoint.date);
    return checkpointDate.isAfter(latest) ? checkpointDate : latest;
  }, dayjs(initialDate));

  // Atualize a initialDate e a finalDate com base nos checkpoints
  const adjustedInitialDate = earliestCheckpoint.isBefore(initialDate)
    ? earliestCheckpoint
    : initialDate;

  const adjustedFinalDate = latestCheckpoint.isAfter(finalDate)
    ? latestCheckpoint
    : finalDate;

  const initial = dayjs(adjustedInitialDate);
  const end = dayjs(adjustedFinalDate);

  const labels = [];
  let currentDate = initial;
  while (currentDate.isSame(end) || currentDate.isBefore(end, 'day')) {
    labels.push(currentDate.format('DD/MM/YYYY'));
    currentDate = currentDate.add(1, 'day');
  }

  // const labels = [];
  // for (let d = initial; d <= end; d = d.add(1, 'day')) {
  //   labels.push(d.format('DD/MM/YYYY'));
  // }

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Checkpoints',
        data: checkpoints
          .map((checkpoint) => {
            const date = dayjs(checkpoint.date);

            if (date.isAfter(end)) {
              return { x: date.format('DD/MM/YYYY'), y: checkpoint.value };
            }

            return { x: date.format('DD/MM/YYYY'), y: checkpoint.value };
          }),
        fill: false,
        borderColor: '#7551d9',
        backgroundColor: '#7551d9',
        tension: 0.1,
      },
      {
        label: 'Objetivo',
        data: [
          { x: initial.format('DD/MM/YYYY'), y: initialValue },
          { x: end.format('DD/MM/YYYY'), y: totalValue },
        ],
        borderColor: '#3cdfaa',
        backgroundColor: '#3cdfaa',
        borderDash: [5, 5],
      },
    ],
  };

  // const data = {
  //   labels: labels,
  //   datasets: [
  //     {
  //       label: 'Checkpoints',
  //       data: checkpoints
  //         .filter((checkpoint) => dayjs(checkpoint.date) >= initial && dayjs(checkpoint.date) <= end)
  //         .map((checkpoint) => ({
  //           x: dayjs(checkpoint.date).format('DD/MM/YYYY'),
  //           y: checkpoint.value,
  //         })),
  //       fill: false,
  //       borderColor: '#7551d9',
  //       backgroundColor: '#7551d9',
  //       tension: 0.1,
  //     },
  //     {
  //       label: 'Objetivo',
  //       data: [
  //         { x: initial.format('DD/MM/YYYY'), y: initialValue },
  //         { x: end.format('DD/MM/YYYY'), y: totalValue },
  //       ],
  //       borderColor: '#3cdfaa',
  //       backgroundColor: '#3cdfaa',
  //       borderDash: [5, 5],
  //     },
  //   ],
  // };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Dias",
        },
        time: {
          unit: "day",
          displayFormats: {
            day: "DD/MM/YYYY"
          },
          unitStepSize: 7,
        },
        ticks: {
          maxTicksLimit: 5,
        },
      },
      y: {
        title: {
          display: false,
          text: "Valor",
        },
      },
    },
  };

  return <Line data={data} options={options} />;
}