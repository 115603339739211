export function ValidateDate(e: any) {
  
  if (e.length < 10) {
    return false;

  } else {
    const date = new Date(e);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    if (year > 0 && year < 9999 && month > 0 && month < 13 && day > 0 && day < 32) {
      return true;
    } else {
      return false;
    }
  }
}