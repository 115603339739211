import Users from "../singleUser";
import { useNavigate, useParams } from "react-router-dom";
import style from "./listUsers.module.scss";
import styleAdd from "styles/addSometing.module.scss";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";
import { IUser } from "interface/user";
import { useRecoilValue } from "recoil";
import { businessIdState, businessInfoState } from "state/atom";
import SingleInvite from "../singleInvite";
import { IInvite } from "interface/invite";
import ButtonSystem from "components/forms/button";
import { FaUser } from "react-icons/fa";

export default function ListUsers() {

  const [users, setUsers] = useState<IUser[]>([])
  const [invites, setInvites] = useState<IInvite[]>([])

  const { businessId } = useParams();
  const businessIdSt = useRecoilValue(businessIdState);
  const businessInfo = useRecoilValue(businessInfoState);

  const [link, setLink] = useState("");
  const [copy, setCopy] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getUsers = async () => {
      const business = businessId !== undefined ? businessId : businessIdSt;
      

      if (business) {
        const usersRef = collection(db, 'companies', business, 'users');
        const usersSnapshot = await getDocs(usersRef);
        const usersData = usersSnapshot.docs.map(userDoc => ({ id: userDoc.id, ...userDoc.data() })) as IUser[];
        setUsers(usersData);
      }

      if (business) {
        const inviteRef = collection(db, 'companies', business, 'invites');
        const inviteSnapshot = await getDocs(inviteRef);
        const inviteData = inviteSnapshot.docs.map(inviteDoc => ({ id: inviteDoc.id, ...inviteDoc.data() })) as IInvite[];
        setInvites(inviteData);
      }
    };

    getUsers();
  }, [businessId, businessIdSt]);


  const usersTotal = businessInfo.plan === "basic" ? 10 : 10;

  const usedUsers = users.length;

  const availableInvites = usersTotal - users.length;

  const usersText = users.length === 0
    ? "Nenhum usuário ativo"
    : users.length === 1
      ? "1 usuário escalando resultados"
      : `${users.length} usuários escalando resultados`;

  const invitesText = invites.length === 0
    ? ""
    : invites.length === 1
      ? "1 convite enviado"
      : `${invites.length} convites enviados`;




  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setCopy(true);
  };

  const cancel = (e: any) => {
    navigate(-1);
  }




  return (

    <section className={styleAdd.section}>

      <div className={styleAdd.view}>
        <FaUser className={styleAdd.item} />
        <div className={styleAdd.printObjective}>
          Novo Usuário
        </div>
      </div>

      <div className={styleAdd.content}>
        


          <div className={style.title}>
            {usersText}
          </div>

          <div className={style.users}>
            {users.length > 0 ? users.map((r: any, index: any) => <Users id={r.id} key={r.id} name={r.name} email={r.email} role={r.role} admin={r.admin} teams={r.teams} userActive={r.userActive} photoURL={r.photoURL} businessId={businessId !== undefined ? businessId : businessIdSt} />): ""}
          </div>

          <div className={style.title}>
            {invitesText}
          </div>

          <div className={style.users}>
            {invites.length > 0 ? invites.map((r: any, index: any) => <SingleInvite email={r.email} key={r.id} invite={r.invite} id={r.id} />) : ""}
          </div>

          <div className={style.title}>
            Plano atual: {usedUsers} de {usersTotal} usuários.
          </div>

          <div className={styleAdd.controls}>
            
          <ButtonSystem action={(e: any) => cancel(e)} status={true} text="Voltar" to="previous" icon="back" />

            <ButtonSystem action={(e: any) => { navigate(`/invite/business/${businessIdSt}`) }} status={availableInvites > 0} text="Adicionar Usuário" to="finish" icon="add" />
          </div>
      </div>



    </section >
  )
}