import style from "styles/styles.module.scss";
import { useEffect, useReducer, useState } from "react";
import styleComponent from "styles/components.module.scss";
import ButtonSystem from "components/forms/button";
import { FaCheckCircle, FaTimesCircle, FaUser } from "react-icons/fa";
import SelectArea from "components/teams/selectArea";
import styleAdd from "styles/addSometing.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../../config/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { db } from "../../../config/firebase";
import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { TextField } from "@mui/material";
import { baseURL } from "baseURL";
import UpdateFirebase from "config/update";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { updateNewUserState, updateState, userIdState } from "state/atom";

const filtersStatesUser = (state: any, action: any) => {
  switch (action.type) {

    case "email":
      return { ...state, email: action.payload };

    case "reset":
      return action.payload;

    default:
      throw new Error();

  }
}


export default function NewUser(props: any) {

  const initialData = {
    email: "",
  }

  const { businessId } = useParams();
  const { idCad } = useParams();
  const navigate = useNavigate();

  const [stateUser, dispatchUser] = useReducer(filtersStatesUser, initialData);
  const [password, setPassword] = useState("");
  const [messagePassword, setMessagePassword] = useState(true);

  const update = useRecoilValue(updateState);
  const setUpdateState = useSetRecoilState(updateState);

  useEffect(() => {
    if (businessId && idCad) {
      const fetchUserData = async () => {
        try {
          // BUSCAR DADOS DO BANCO DE DADOS
          const docRef = doc(db, "companies", businessId, "invites", idCad);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();

            // EXIBIR DADOS NA TELA PARA EDIÇÃO
            dispatchUser({ type: "email", payload: data.email });
          } else {
            navigate("/login");
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
          navigate("/login");
        }
      };

      fetchUserData();
    } else {

      navigate("/login");

    }
  }, []);




  const passwordVerify = (e: any) => {

    setPassword(e);

    if ((e === "" || e.length < 8) && !messagePassword) {
      setMessagePassword(true);
    } else if ((e !== "" && e.length > 7) && messagePassword) {
      setMessagePassword(false);
    }
  }


  const saveUser = (e: any) => {

    let user: any;

    createUserWithEmailAndPassword(auth, stateUser.email, password)
      .then((userCredential) => {
        user = userCredential.user;
        const userDocRef = doc(db, "users", user.uid);
        return setDoc(userDocRef, {
          businessId: businessId,
        });
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("Erro na primeira etapa:", errorCode, errorMessage);
        throw error; // Rejeita a promessa para que o próximo bloco catch possa capturar o erro
      }).then(() => {
        if (businessId) {
          const oldUserDocRef = doc(db, "companies", businessId, "users", stateUser.email);
          return getDoc(oldUserDocRef);
        }
      }).catch((error) => {
        console.log("Erro na segunda etapa:", error);
        throw error;
      }).then((oldUserDocSnapshot) => {
        if (businessId && oldUserDocSnapshot && oldUserDocSnapshot.exists()) {
          const oldUserData = oldUserDocSnapshot.data();
          const newUserDocRef = doc(db, "companies", businessId, "users", user.uid);
          return setDoc(newUserDocRef, oldUserData);
        }
      }).catch((error) => {
        console.log("Erro na terceira etapa:", error);
        throw error;
      }).then(() => {
        if (businessId) {
          const oldUserDocRef = doc(db, "companies", businessId, "users", stateUser.email);
          return deleteDoc(oldUserDocRef);
        }
      }).catch((error) => {
        console.log("Erro na quarta etapa:", error);
        throw error;
      }).then(() => {
        if (businessId && idCad) {
          const oldInviteRef = doc(db, "companies", businessId, "invites", idCad);
          return deleteDoc(oldInviteRef);
        }
      }).catch((error) => {
        console.log("Erro na quinta etapa:", error);
        throw error;
      }).then(() => {
        setUpdateState(!update);
        console.log("atualizou");
        navigate("/");
      })
      .catch((error) => {
        console.log("Erro na sexta etapa:", error);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  return (
    <section className={styleAdd.section}>
      <div className={styleAdd.view}>
        <img src={"/assets/yscale_white.svg"} alt="Yscale" className={style.logo} />
      </div>
      <div className={styleAdd.content}>
        <div className={styleAdd.container}>
          <section className={styleComponent.section}>
            <div className={styleComponent.title}>
              <FaUser className={styleComponent.item} /> Complete o seu Cadastro
            </div>
            <div className={styleComponent.div__input}>
              <TextField
                disabled
                label="E-mail"
                value={stateUser.email}
                // InputProps={{ readOnly: true }}
                sx={{
                  width: '100%', '& label': {
                    minWidth: 200,
                  },
                }}
                fullWidth
              />
            </div>
            <div className={styleComponent.div__input}>
              <TextField
                label="Senha"
                error={Boolean(messagePassword)}
                helperText={messagePassword ? "Adicione uma senha com no mínimo 8 dígitos" : ""}
                value={password}
                onChange={(e: any) => passwordVerify(e.target.value)}
                sx={{
                  width: '100%', '& label': {
                    minWidth: 200,
                  },
                }}
                fullWidth
              />
            </div>

            <div className={styleComponent.controls}>
              <ButtonSystem action={(e: any) => { saveUser(e) }} status={!messagePassword} text="Salvar" to="finish" icon="finish" />
            </div>
          </section>
        </div>

      </div >
    </section >
  );
}