import classNames from "classnames";
import { useEffect, useState } from "react";
import styleComponent from "../../../styles/components.module.scss";
import style from "./selectIcon.module.scss";
import iconsList from "../../../data/icons.json";
import { baseURL } from "baseURL";

type IIconValid = {
  label: string,
  value: string,
  handleIcon: any,
}

export default function SelectIcon(props: IIconValid) {

  const [selectedIcon, setSelectedIcon] = useState<string>("assets/areas/globe.svg");

  useEffect(() => {
    setSelectedIcon(props.value);
  }, [props.value]);

  const onSelect = (e: string) => {

    if (selectedIcon !== e) {
      setSelectedIcon(e);
      props.handleIcon(e);
    }
  }

  return (
    <div className={styleComponent.label}><>{props.label}:

      <div className={styleComponent.areas}>

        {iconsList.map((area: any, index: any) => <button type="button" className={classNames({
          [style.singleArea]: true,
          [selectedIcon.includes(area.url) === true ? style["singleArea--selected"] : style["singleArea--notSelected"]]: true,
        })} onClick={(e: any) => onSelect(e.target.id)} key={index} id={area.url} placeholder="">

          <img src={baseURL + area.url} onClick={(e: any) => onSelect(e.target.id)} id={area.url} alt={area.url} className={classNames({
            [style.singleArea__icon]: true,
            [selectedIcon.includes(area.url) === true ? style["singleArea__icon--active"] : ""]: true
          })} />
        </button>
        )}

      </div>
    </>
    </div>
  );
}