import ButtonSystem from "components/forms/button";
import SingleTeam from "components/teams/singleTeam";
import style from "./configTeams.module.scss";
import { useRecoilValue } from "recoil";
import { businessInfoState } from "state/atom";
import { useNavigate } from "react-router-dom";
import styleAdd from "styles/addSometing.module.scss";
import { FaUsers } from "react-icons/fa";

export default function TeamsConfig() {

  const businessInfo = useRecoilValue(businessInfoState);
  const teams = businessInfo.teams;

  const navigate = useNavigate();

  const teamsSort = [...teams].sort((a, b) => {
    const teamA = a.team.toLowerCase();
    const teamB = b.team.toLowerCase();

    if (teamA < teamB) {
      return -1;
    }
    if (teamA > teamB) {
      return 1;
    }
    return 0;
  });


  const cancel = (e: any) => {
    navigate(-1);
  }


  return (
    <section className={styleAdd.section}>

      <div className={styleAdd.view}>
        <FaUsers className={styleAdd.item} />
        <div className={styleAdd.printObjective}>
          Times
        </div>
      </div>

      <div className={styleAdd.content}>
        <div className={styleAdd.container}>

          <div className={style.contentConfig}>

            {teamsSort.map((team: any) =>
              <SingleTeam key={team.id} team={team.team} id={team.id} icon={team.icon} />
            )}

            <div className={styleAdd.controls}>
              <ButtonSystem action={(e: any) => cancel(e)} status={true} text="Voltar" to="previous" icon="back" />

              <ButtonSystem action={(e: any) => navigate("/team/newTeam")} status={true} text="Novo Time" to="finish" icon="add" />

            </div>

          </div>
        </div>
      </div>

    </section>
  )
}