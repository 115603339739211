export function formatNumber(value: number) {
  const parts = value.toString().split(".");
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const formattedValue = parts.length > 1 ? integerPart + "," + parts[1] : integerPart;
  return formattedValue;
}

export function removeFormat(value: string) {

  const valueA = value.replace(/[^\d,]/g, ""); // Remove todos os caracteres que não são dígitos nem vírgula
  const replacedValue = valueA.replace(/\./g, ""); // Remove todos os pontos (.)
  const replace2 = replacedValue.replace(",", "."); // Substitui a primeira vírgula por ponto (se houver)
  let replace3 = replace2.toString(); // Transforma em string
  // Verifica se o número possui decimais e ajusta para no máximo 2
  const decimalIndex = replace3.indexOf(".");
  if (decimalIndex !== -1) {
    const decimals = replace3.substring(decimalIndex + 1);
    if (decimals.length > 2) {
      replace3 = replace3.substring(0, decimalIndex + 3);
    }
  }

  return replace3;
}
