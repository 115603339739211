import style from "./myObjectives.module.scss";
import stylePages from "styles/pages.module.scss";
import { useRecoilValue } from "recoil";
import { okrsState, userIdState } from "state/atom";
import { TbTarget } from "react-icons/tb";
import KrSingle from "components/kr/krSingle";
import OkrName from "components/okr/okrName";
import React from "react";

export default function MyObjectives() {

  const okrs = useRecoilValue(okrsState);
  const userId = useRecoilValue(userIdState);

  const krsByOkr = okrs.reduce((acc: any, okr: any) => {
    const krs = okr.krs?.filter((kr: any) => kr.responsible.includes(userId))
      .map((kr: any) => ({ ...kr, okrName: okr.okrName || "" })) || [];
    return {
      ...acc,
      [okr.id]: krs
    };
  }, {});

  return (
    <section className={stylePages.container__section}>
      <div className={stylePages.header}>

        <div className={stylePages.header__container}>
          <div className={stylePages.header__title}>
            Meus Resultados
          </div>
        </div>

        <div className={stylePages.header__actions}>

        </div>
      </div>

      {Object.keys(krsByOkr).length === 0 ? <div className={style.noData}>Você ainda não está vinculado a nenhum objetivo.</div> : ""}
      {Object.keys(krsByOkr).map((okrId: any, index: number) => {
        const krs = krsByOkr[okrId];
        return (
          <React.Fragment key={index}>
            {krs.length > 0 && <OkrName okrName={krs[0].okrName} />}
            {krs.map((kr: any, index: number) => (
              <KrSingle key={index} data={kr} okr={okrId} />
            ))}
            {krs.length > 0 && <hr className={stylePages.separator} />}
          </React.Fragment>
        );
      })}
    </section>
  );
}