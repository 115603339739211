import style from "./PageKr.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { FaBatteryThreeQuarters, FaCalendarCheck, FaChartLine, FaChartPie, FaCheckCircle, FaChevronDown, FaChevronUp } from "react-icons/fa";
import DoughnutPercentage from "components/charts/doughnutPercentage";
import AddCheckpoint from "components/Checkpoints/addCheckpoint";
import { businessIdState, okrsState, updateState } from "state/atom";
import { db } from "../../../config/firebase";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { deleteDoc, doc } from "firebase/firestore";
import { ICheckpoint, IKR } from "interface/allOkrs";
import UpdateFirestore from "components/general/update";
import { TbTarget } from "react-icons/tb";
import { Teams } from "components/teams/showTeams";
import ShowUsers from "components/users/show";
import LineChart from "components/charts/lineGraph";
import { formatNumber } from "components/date/formatNumber";
import RemoveCheckpoint from "components/Checkpoints/removeCheckpoint";
import BarProgressNoInfo from "components/charts/barProgress__noInfo";
import ButtonSystemNoText from "components/forms/button/buttonNoText";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import DeleteKr from "components/kr/deleteKr";
import Kanban from "components/tasks/kanban";

export default function PageKr() {

  // Pré-informações do KR

  const { kr, okr } = useParams();
  const businessId = useRecoilValue(businessIdState);

  // Armazendo os dados do KR

  const [krData, setKrData] = useState<IKR>();
  const [checkpoints, setCheckpoints] = useState<ICheckpoint[]>([]);
  const okrsStateData = useRecoilValue(okrsState);

  // Informações para o gráfico

  const [doneValue, setDoneValue] = useState(0);
  const [chartKey, setChartKey] = useState(0);

  // update

  const [update, setUpdate] = useState(false);
  const [updateChart, setUpdateChart] = useState(false);
  const [open, setOpen] = useState(false);
  const setUpdateState = useSetRecoilState(updateState);

  const [checkpointsOpen, setCheckpointsOpen] = useState(false);

  // Atualizando os dados do KR

  useEffect(() => {
    const okrData = okrsStateData.find((data) => data.id === okr);
    if (okrData) {
      const krInfo = okrData.krs.find((data) => data.id === kr);
      if (krInfo) {
        setKrData(krInfo);
        setCheckpoints(krInfo.checkpoints);
      }
    }
    setUpdateChart(!updateChart);
  }, [okrsStateData, okr, kr]);

  // Navegação

  const navigate = useNavigate();

  const previousPage = () => {
    navigate(-1);
  }

  const editKr = () => {
    navigate(`/edit/${okr}/${kr}`);
  }

  // info to graph

  useEffect(() => {
    if (krData) {
      if (checkpoints.length > 0) {
        let latestCheckpoint = checkpoints.reduce((previousCheckpoint: any, currentCheckpoint: any) => {
          return previousCheckpoint.date > currentCheckpoint.date ? previousCheckpoint : currentCheckpoint;
        });
        const checkpointValue = parseFloat(`${latestCheckpoint.value}`);
        setDoneValue(checkpointValue);
        setChartKey(chartKey + 1);
      } else {
        setDoneValue(krData.initialValue);
        setChartKey(chartKey + 1);
      }
    }
  }, [krData, checkpoints]);

  const [showValue, setShowValue] = useState("");
  const [showObjective, setShowObjective] = useState("");

  useEffect(() => {
    if (krData) {
      if (krData.dataType === "R$") {
        const inPreShow = formatNumber(Number(doneValue));
        const inPreObjective = formatNumber(Number(krData.finalValue));
        const preShow = `R$ ${inPreShow}`;
        const preObjective = `R$ ${inPreObjective}`;
        setShowValue(preShow);
        setShowObjective(preObjective);
      } else if (krData.dataType === "%") {
        const inPreShow = formatNumber(Number(doneValue));
        const inPreObjective = formatNumber(Number(krData.finalValue));
        const preShow = `${inPreShow}%`
        const preObjective = `${inPreObjective}%`;
        setShowValue(preShow);
        setShowObjective(preObjective);
      } else {
        const inPreShow = formatNumber(Number(doneValue));
        const inPreObjective = formatNumber(Number(krData.finalValue));

        const preShow = `${inPreShow}`;
        const preObjective = `${inPreObjective}`;
        setShowValue(preShow);
        setShowObjective(preObjective);
      }
    }
  }, [krData, doneValue])

  // Dialog

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const deleteKr = () => {
    setOpen(false);

    const deleteKrProps = {
      businessId: businessId,
      okr: okr,
      kr: kr,
    };

    DeleteKr(deleteKrProps).then(() => {
      // Atualiza o estado do componente para refletir a exclusão
      setUpdateState((prevUpdate) => !prevUpdate);
      navigate("/objetivos");
    })
      .catch((error) => {
        // Trate o erro, se necessário
        console.error(error);
      });
  }



  return (
    <section className={style.section}>

      <div className={style.kr}>

        {/* okr infos */}

        <div className={style.kr__header}>
          <div className={style.kr__title}>
            <TbTarget className={style.title__icon} />  {krData ? krData.kr : null}
          </div>
          <div className={style.kr__teams}>

            {krData ? <ShowUsers users={krData.responsible} /> : null}
            {krData ? krData.teams.map((e: any, index: any) =>
              <Teams team={e} key={index} />
            ) : null}
          </div>
        </div>

        {/* action buttons */}

        <div className={style.kr__actions}>
          <ButtonSystemNoText action={previousPage} status={true} text="" to="previous" icon="back" />
          <ButtonSystemNoText action={editKr} status={true} text="" to="finish" icon="edit" />
          <ButtonSystemNoText action={() => handleClickOpen()} status={true} text="" to="remove" icon="remove" />
          <UpdateFirestore />
        </div>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Você tem certeza que deseja excluir este KR?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ao excluir este KR, todos os seus checkpoints serão excluídos também. Esta ação não pode ser desfeita.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={deleteKr} autoFocus>
              Excluir
            </Button>
          </DialogActions>
        </Dialog>

      </div>

      {/* kr infos - 1 line */}

      <div className={style.widget__line}>

        {/* objective */}

        <div className={style.widget__container66}>
          <div className={style.widget__title}>
            <FaBatteryThreeQuarters className={style.title__icon} />
            Objetivo
          </div>
          <div className={style.widget__value}>
            {krData ? <BarProgressNoInfo key={chartKey + "a"} finalValue={krData.finalValue} doneValue={doneValue} dataType={krData.dataType} resultType={krData.resultType} initialValue={krData.initialValue} /> : null}

            <div className={style.legend}>
              <div className={style.legend__done}>{krData ? showValue : null}<br />Realizado</div>
              <div className={style.legend__objective}>{krData ? showObjective : null}<br />Objetivo</div>
            </div>
          </div>
        </div>


        {/* percentage */}

        <div className={style.widget__container33}>
          <div className={style.widget__title}>
            <FaChartPie className={style.title__icon} />
            % de conclusão
          </div>
          <div className={style.widget__value}>
            <DoughnutPercentage key={chartKey + "b"} doneValue={doneValue} finalValue={krData?.finalValue} initialValue={krData?.initialValue} resultType={krData?.resultType} height={200} />
          </div>
        </div>

      </div>

      {/* kr infos - 2 line */}

      <div className={style.widget__line}>

        {/* checkpoints */}

        <div className={style.widget__container66}>

          <div className={style.widget__title}>
            <FaChartLine className={style.title__icon} />
            Gráfico de Atualizações
          </div>
          <div className={style.chartLine}>
            {krData ? <LineChart key={chartKey + "c"} initialDate={krData.initialDate} finalDate={krData.finalDate} checkpoints={checkpoints} totalValue={krData.finalValue} initialValue={krData.initialValue} /> : null}
          </div>
        </div>

        {/* add checkpoint */}

        <div className={style.widget__container33B}>
          <div className={style.widget__titleB}>
            <FaCheckCircle className={style.title__icon} />
            Adicionar Atualização
          </div>
          <div className={style.chart}>
            <AddCheckpoint update={() => setUpdate(!update)} businessId={businessId} okr={okr} kr={kr} minDate={krData?.initialDate} maxDate={krData?.finalDate} />
          </div>
        </div>
      </div>


      

      <div className={style.widget__line}>
        <Kanban okr={okr} kr={kr} user="" />
      </div>

      <div className={style.widget__line}>

        <div className={style.checkpoints}>

          <div className={style.checkpoints__titleButton}  onClick={(e: any) => setCheckpointsOpen(!checkpointsOpen)}>

            <div className={style.checkpoints__title}>
              <FaCalendarCheck className={style.title__icon} />
              Atualizações
            </div>

            {checkpointsOpen === true ? <FaChevronUp className={style.title__icon} /> : <FaChevronDown className={style.title__icon} />}

          </div>
          {checkpointsOpen === true ?
            <div className={style.update__table}>
              <div className={style.update__rowHeader}>
                <div className={style.update__line1}>
                  <div className={style.update__date}>Data</div>
                  <div className={style.update__value}>Valor</div>
                  <div className={style.update__responsible}>Responsável</div>
                  <div className={style.update__remove}>Ação</div>
                </div>
                <div className={style.update__obs}>Observações</div>
              </div>

              {checkpoints.map((checkpoint: any) => (
                <div className={style.update__row}>
                  <div className={style.update__line1}>
                    <div className={style.update__date}>{checkpoint.date.split("-").reverse().join("/")}</div>
                    <div className={style.update__value}>{formatNumber(Number(checkpoint.value))}</div>
                    <div className={style.update__responsible}>{<ShowUsers users={checkpoint.responsible} />}</div>
                    <div className={style.update__remove}><RemoveCheckpoint businessId={businessId} okr={okr} kr={kr} dateId={checkpoint.id} /></div>
                  </div>
                  <div className={style.update__obs}>{checkpoint.obs}</div>
                </div>
              ))}
            </div> : ""}

        </div>

      </div>

    </section >
  );
}