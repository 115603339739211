import { useState } from 'react';
import style from './Login.module.scss';
import { setPersistence, signInWithEmailAndPassword, browserLocalPersistence } from 'firebase/auth';
import { auth } from '../../../config/firebase';
import ButtonSystem from 'components/forms/button';
import { userIdState } from 'state/atom';
import { useNavigate } from 'react-router-dom';
import InfoLogin from '../info';
import { useRecoilState } from 'recoil';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [userId, setUserId] = useRecoilState(userIdState);

  const navigate = useNavigate();

  function sendLogin(e: any) {
    e.preventDefault();
    if (!email || !password) {
      setErrorMessage('Por favor, preencha todos os campos.');
      return;
    }
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            const user = userCredential.user;
            setUserId(user.uid || null);
            navigate('/');
            
          })
          .catch((error) => {
            setErrorMessage('Credenciais inválidas. Por favor, tente novamente.');
          });
      })
      .catch((error) => {
        setErrorMessage('Ocorreu um erro. Por favor, tente novamente.');
      });
  }

  function handleEmailChange(e: any) {
    setEmail(e.target.value);
    setErrorMessage('');
  }

  function handlePasswordChange(e: any) {
    setPassword(e.target.value);
    setErrorMessage('');
  }

  function handleForgotPasswordClick() {
    navigate('/forgotpassword');
  }

  return (
    <section className={style.section}>
      <div className={style.info}>
        <InfoLogin />
      </div>
      <div className={style.firebase}>
        <div className={style.loginArea}>
          <div className={style.loginTitle}>Acessar Plataforma:</div>
          <form className={style.form}>
            <div className={style.label}>E-mail:</div>
            <input className={style.field} type="email" placeholder="Insira seu login" value={email} onChange={handleEmailChange} />
            <div className={style.label}>Senha:</div>
            <input className={style.field} type="password" value={password} onChange={handlePasswordChange} />
            {errorMessage && <span className={style.errorMessage}>{errorMessage}</span>}
            <div className={style.divButton}>
              <ButtonSystem action={sendLogin} status={email !== '' && password !== ''} text="Login" to="next" icon="finish" />
            </div>
          </form>
          <div className={style.forgotPassword} onClick={handleForgotPasswordClick}>
            Esqueci minha senha
          </div>
        </div>
      </div>
    </section>
  );
}
