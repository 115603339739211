import { auth, db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

export async function gettingBusinessIdAuth(setBusinessId: any) {
  return new Promise<void>((resolve) => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // User is signed in.
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const { businessId } = userDoc.data();
          setBusinessId(businessId);
        } else {
          // User document does not exist.
          setBusinessId(null);
        }
      } else {
        // User is signed out.
        setBusinessId(null);
      }
      // Resolve a promessa após a conclusão da lógica
      resolve();
    });

    // Retornar uma função para descadastrar o observador quando necessário
    return () => {
      unsubscribe();
    };
  });
}